import { PhoneIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IntegrationType, MemberType } from '../../../API';
import { ReactComponent as GoogleMeetIcon } from '../../../assets/icons/21-google-meet.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/icons/21-zoom.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/icons/21-teams.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/12-star.svg';
import { ReactComponent as CEPIcon } from '../../../assets/icons/20-cep-salesforce.svg';
import { UserAvatar } from '../userAvatar/UserAvatar';
import { isTeamMember, MemberDTO } from '../../../store/meeting';

type HostCardProps = {
  host: MemberDTO;
  isMainHost?: boolean;
  onMakeMainHost?: (value: string) => void;
  onRemove?: (value: string) => void;
};

export const HostCard = ({ host, isMainHost, onMakeMainHost, onRemove }: HostCardProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 border-1 border-heavy-20 border-radius-10px flex-between p-8px overflow-hidden">
        <div className="flex-left-center gap-8px overflow-hidden">
          {isTeamMember(host) ? (
            <div className="p-avatar p-avatar-lg flex-none">
              <UsersIcon className="icon-16px" />
            </div>
          ) : (
            <UserAvatar
              name={host.name || host.email || ''}
              avatarLink={host.avatar}
              className="flex-none"
              size="large"
            />
          )}
          <div className="flex flex-column gap-4px overflow-hidden">
            <div className="text-label-s-med text-heavy-80 ">{host.name ?? ''}</div>
            {host.email && (
              <div className="text-label-xs-reg text-heavy-60 overflow-hidden text-overflow-ellipsis">{host.email}</div>
            )}
          </div>
        </div>

        <div className="flex-left-center gap-2px">
          {host.hasPhoneNumber && (
            <div className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
              <PhoneIcon className="icon-12px text-heavy-60" />
            </div>
          )}
          {host.integrationTypes.map((type) => {
            switch (type) {
              case IntegrationType.GOOGLE_MEET:
                return (
                  <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                    <GoogleMeetIcon className="icon-12px" />
                  </div>
                );
              case IntegrationType.MICROSOFT_TEAMS:
                return (
                  <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                    <TeamsIcon className="icon-12px" />
                  </div>
                );
              case IntegrationType.ZOOM:
                return (
                  <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                    <ZoomIcon className="icon-12px" />
                  </div>
                );
              default:
                return null;
            }
          })}
          {host.type === MemberType.CEP && (
            <div className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
              <CEPIcon className="icon-12px" />
            </div>
          )}
          {/* <div className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
            <UserIcon className="icon-12px" />
          </div> */}
          {(isMainHost || onMakeMainHost) && <div className="border-left-1 border-heavy-20 h-20px mx-6px" />}
          {isMainHost ? (
            <StarIcon className="icon-12px text-blue-main" />
          ) : (
            onMakeMainHost && (
              <div className="action-button-xs -m-4px">
                <StarIcon className="icon-12px" onClick={() => onMakeMainHost(host.id)} />
              </div>
            )
          )}
        </div>
      </div>
      <div className="w-20px">
        {onRemove && (
          <div className="action-button-xs" onClick={() => onRemove(host.id)}>
            <XMarkIcon className="icon-16px" />
          </div>
        )}
      </div>
    </div>
  );
};