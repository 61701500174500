import { call, put, takeLatest } from 'redux-saga/effects';
import { IntegrationsActionTypes, integrationsActions } from './actions';
import { IntegrationType, WorkspaceIntegrationType } from '../../API';
import {
  CONNECT_INTEGRATION_ERROR_TOAST,
  CONNECT_USER_INTEGRATION_SUCCESS_TOAST,
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_TOAST,
  HUBSPOT_AUTH_URL,
  SALESFORCE_PROD_AUTH_URL,
  SALESFORCE_SANDBOX_AUTH_URL,
  ZOOM_AUTH_URL,
} from './constants';
import { handleServiceError } from '../utils/reduxUtils';
import { notificationsActions } from '../notifications';
import { connectIntegration, disconnectIntegration } from './service';
import { SFOrgType } from './types';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { workspacesActions } from '../workspaces';
import { DisconnectIntegrationRequest } from '../../generated-sources/internal-api/models/DisconnectIntegrationRequest';
import { authenticationSagas } from '../authentication';
import { userSettingsSagas } from '../userSettings';
import { CodeRedirectPayload } from './types';

const getZoomCodeRedirect = (payload: CodeRedirectPayload) => {
  const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
  const scope = process.env.REACT_APP_ZOOM_SCOPE;
  const redirectUri = payload.uri;
  const state = encodeURIComponent(
    JSON.stringify({
      type: IntegrationType.ZOOM,
    })
  );

  window.location.href = `${ZOOM_AUTH_URL}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
};

const getHubSpotCodeRedirect = (payload: CodeRedirectPayload) => {
  const clientId = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
  const scope = process.env.REACT_APP_HUBSPOT_SCOPE;
  const redirectUri = payload.uri;
  const state = encodeURIComponent(
    JSON.stringify({
      type: WorkspaceIntegrationType.HUBSPOT,
      workspaceId: payload.workspaceId,
      customParameters: payload.customParameters,
    })
  );

  window.location.href = `${HUBSPOT_AUTH_URL}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
};

const getSalesforceCodeRedirect = async (payload: CodeRedirectPayload) => {
  const clientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
  const scope = process.env.REACT_APP_SALESFORCE_SCOPE;
  const redirectUri = payload.uri;
  const verifier = (crypto.randomUUID() + crypto.randomUUID() + crypto.randomUUID() + crypto.randomUUID()).substring(
    0,
    128
  ); //128 random bytes
  const state = encodeURIComponent(
    JSON.stringify({
      type: WorkspaceIntegrationType.SALESFORCE,
      workspaceId: payload.workspaceId,
      customParameters: {
        ...payload.customParameters,
        verifier,
      },
    })
  );

  const utf8 = new TextEncoder().encode(verifier);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const codeChallenge = btoa(String.fromCharCode.apply(null, hashArray))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  if (
    payload.customParameters &&
    'orgType' in payload.customParameters &&
    payload.customParameters?.orgType === SFOrgType.PRODUCTION &&
    payload.customParameters.createEvents === 'true'
  ) {
    window.location.href = `${SALESFORCE_PROD_AUTH_URL}?response_type=code&prompt=login&code_challenge=${codeChallenge}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  } else if (
    payload.customParameters &&
    'orgType' in payload.customParameters &&
    payload.customParameters?.orgType === SFOrgType.SANDBOX &&
    payload.customParameters.createEvents === 'true'
  ) {
    window.location.href = `${SALESFORCE_SANDBOX_AUTH_URL}?response_type=code&prompt=login&code_challenge=${codeChallenge}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }
};

const getCodeRedirect = async (action: ReturnType<typeof integrationsActions.getCodeRedirect>) => {
  if (action.type === IntegrationsActionTypes.GET_CODE_REDIRECT) {
    switch (action.payload.integrationType) {
      case IntegrationType.ZOOM:
        getZoomCodeRedirect(action.payload);
        break;
      case WorkspaceIntegrationType.HUBSPOT:
        getHubSpotCodeRedirect(action.payload);
        break;
      case WorkspaceIntegrationType.SALESFORCE:
        getSalesforceCodeRedirect(action.payload);
        break;
      default:
        break;
    }
  }
};

function* generateCepAuthCode(action: ReturnType<typeof integrationsActions.generateCepAuthCode>) {
  try {
    if (action.type === IntegrationsActionTypes.GENERATE_CEP_CODE_AUTH) {
      const input: ConnectIntegrationRequest = {
        type: WorkspaceIntegrationType.SALESFORCE_CEP,
        workspaceId: action.workspaceId,
        customParameters: action.customParameters,
      };

      yield call(connectIntegration, input);
      yield put(workspacesActions.getWorkspacesRequest());
    }
  } catch (error: unknown) {
    yield put(integrationsActions.connectIntegrationFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_INTEGRATION_ERROR_TOAST);
  }
}

function* disconnectCEP(action: ReturnType<typeof integrationsActions.generateCepAuthCode>) {
  if (action.type === IntegrationsActionTypes.DISCONNECT_CEP) {
    const input: DisconnectIntegrationRequest = {
      type: WorkspaceIntegrationType.SALESFORCE_CEP,
      workspaceId: action.workspaceId,
      workspaceIntegration: action.workspaceIntegration,
    };

    yield call(disconnectIntegration, input);
    yield put(workspacesActions.getWorkspacesRequest());
  }
}

function* connectIntegrationSaga(action: ReturnType<typeof integrationsActions.connectIntegrationRequest>) {
  try {
    if (action.type === IntegrationsActionTypes.CONNECT_INTEGRATION_REQUEST) {
      const input = action.payload;
      const isWorkspaceIntegration = Object.values(WorkspaceIntegrationType).includes(
        input.type as WorkspaceIntegrationType
      );

      yield call(connectIntegration, input);

      yield call(isWorkspaceIntegration ? authenticationSagas.getTenant : userSettingsSagas.getUserSettings);

      yield put(integrationsActions.connectIntegrationSuccess());
      yield put(
        notificationsActions.showToast(
          isWorkspaceIntegration ? CONNECT_WORKSPACE_INTEGRATION_SUCCESS_TOAST : CONNECT_USER_INTEGRATION_SUCCESS_TOAST
        )
      );
    }
  } catch (error: unknown) {
    yield put(integrationsActions.connectIntegrationFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_INTEGRATION_ERROR_TOAST);
  }
}

export function* watchIntegrationsSaga() {
  yield takeLatest(IntegrationsActionTypes.GENERATE_CEP_CODE_AUTH, generateCepAuthCode);
  yield takeLatest(IntegrationsActionTypes.DISCONNECT_CEP, disconnectCEP);
  yield takeLatest(IntegrationsActionTypes.GET_CODE_REDIRECT, getCodeRedirect);
  yield takeLatest(IntegrationsActionTypes.CONNECT_INTEGRATION_REQUEST, connectIntegrationSaga);
}