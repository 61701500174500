import { useEffect, useRef } from 'react';
import { IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import { authenticationSelectors } from '../../../store/authentication';
import { VIDEO_CONFERENCES, integrationsActions } from '../../../store/integration';
import { generateVideoConference, getIntegrationURLParams } from '../../../store/integration/utils';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { ZoomAuthButton } from '../../private';
import { getUrlWithoutParams } from '../../../services/URLService';

interface IZoomIntegration {
  integrationType: IntegrationType | WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const ZoomIntegration = (props: IZoomIntegration) => {
  const { integrationType, handleDisconnect } = props;
  const dispatch = useDispatch();
  const isAuthenticationFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const userSettingsError = useSelector(userSettingsSelectors.selectError);
  const isZoomConnected = useSelector(userSettingsSelectors.selectIsZoomConnected);
  const integrations = useSelector(userSettingsSelectors.selectIntegrations);
  const initialLoad = useRef(true); // Use a ref to track the initial load

  useEffect(() => {
    if (isUserSettingsReceived && !isAuthenticationFetching && !userSettingsError) {
      const { code } = getIntegrationURLParams();

      if (code) {
        const redirectURI = getUrlWithoutParams();

        handleZoomAuthentication(code, redirectURI);
      }
    }
  }, [isUserSettingsReceived, isAuthenticationFetching, userSettingsError, integrationType, isZoomConnected]);

  const handleZoomAuthentication = (code: string, redirectURI: string) => {
    // if there's an authCode and Zoom is not connected, dispatch actions to connect to Zoom and navigate
    if (!isZoomConnected) {
      dispatchZoomConnection(code, redirectURI);
      navigationService.navigateTo(Path.ConnectIntegration); // clear the URL from code
      initialLoad.current = false; // after navigating, mark that the initial load is complete
    }
  };

  const dispatchZoomConnection = (code: string, redirectURI: string) => {
    dispatch(userSettingsActions.connectZoomRequest({ code, redirectURI }));
    dispatch(integrationsActions.setConnectIntegrationType(IntegrationType.ZOOM));

    // Check if Zoom is the first video conference in the user's integrations to make it the default
    const hasOtherVideoConferences =
      integrations &&
      integrations.some(
        (integration) =>
          integration && VIDEO_CONFERENCES.includes(integration.type) && integration.type !== IntegrationType.ZOOM
      );
    if (!hasOtherVideoConferences) {
      dispatch(
        userSettingsActions.updateUserSettings({
          defaultVideoIntegration: generateVideoConference(IntegrationType.ZOOM),
        })
      );
    }
  };

  const handleGetZoomCodeRedirect = () => {
    dispatch(integrationsActions.getCodeRedirect({ integrationType, uri: window.location.href }));
  };

  return (
    <ConnectIntegration
      integrationType={integrationType}
      handleDisconnect={handleDisconnect}
      ConnectButton={ZoomAuthButton}
      handleAuthRedirect={handleGetZoomCodeRedirect}
    />
  );
};
