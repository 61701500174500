import { AnyAction, Middleware, Dispatch } from 'redux';
import { Action, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { ThunkAction } from 'redux-thunk';
import authentication from './authentication/reducer';
import availability from './availability/reducer';
import billing from './billing/reducer';
import scheduledMeetings from './bookedMeetings/reducer';
import bookingPages from './bookingPages/reducer';
import bookingTemplates from './bookingTemplates/reducer';
import error from './error/reducer';
import global from './global/reducer';
import groupBookingPages from './groupBookingPages/reducer';
import integration from './integration/reducer';
import journeys from './journeyBuilder/reducer';
import locations from './locations/reducer';
import meeting from './meeting/reducer';
import modals from './modals/reducer';
import navigations from './navigations/reducer';
import notifications from './notifications/reducer';
import OPSConsoleAccounts from './opsConsole/accounts/reducer';
import OPSConsoleCommon from './opsConsole/common/reducer';
import OPSConsoleOrgs from './opsConsole/orgs/reducer';
import OPSConsoleStaff from './opsConsole/staff/reducer';
import OPSConsoleUsers from './opsConsole/users/reducer';
import promo from './promo/reducer';
import event from './publicBookingPage/reducer';
import quickSetup from './quickSetup/reducer';
import roles from './roles/reducer';
import rootSaga from './rootSaga';
import smartAlerts from './smartAlerts/reducer';
import teams from './teams/reducer';
import users from './users/reducer';
import userSettings from './userSettings/reducer';
import workspaces from './workspaces/reducer';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger as Middleware<unknown, any, Dispatch<AnyAction>>, sagaMiddleware),
  reducer: {
    authentication,
    billing,
    bookingPages,
    error,
    event,
    global,
    groupBookingPages,
    integration,
    journeys,
    locations,
    bookingTemplates,
    meeting,
    modals,
    navigations,
    notifications,
    OPSConsoleAccounts,
    OPSConsoleCommon,
    OPSConsoleOrgs,
    OPSConsoleStaff,
    OPSConsoleUsers,
    quickSetup,
    roles,
    scheduledMeetings,
    smartAlerts,
    teams,
    availability,
    userSettings,
    users,
    workspaces,
    promo,
  },
});

sagaMiddleware.run(rootSaga);

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Action<string>>;
