/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTeamUserRoundRobin = /* GraphQL */ `query GetTeamUserRoundRobin($teamId: String!, $userId: String!) {
  getTeamUserRoundRobin(teamId: $teamId, userId: $userId) {
    teamId
    tenantId
    userId
    lastCreatedEvent
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamUserRoundRobinQueryVariables,
  APITypes.GetTeamUserRoundRobinQuery
>;
export const listTeamUserRoundRobins = /* GraphQL */ `query ListTeamUserRoundRobins(
  $teamId: String
  $userId: ModelStringKeyConditionInput
  $filter: ModelTeamUserRoundRobinFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeamUserRoundRobins(
    teamId: $teamId
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      teamId
      tenantId
      userId
      lastCreatedEvent
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamUserRoundRobinsQueryVariables,
  APITypes.ListTeamUserRoundRobinsQuery
>;
export const getAvailabilityModel = /* GraphQL */ `query GetAvailabilityModel($id: ID!) {
  getAvailabilityModel(id: $id) {
    id
    tenant
    ownerId
    name
    weeklyHours {
      type
      __typename
    }
    overrides {
      isBlock
      __typename
    }
    bookingPages
    bookingTemplates
    locations
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAvailabilityModelQueryVariables,
  APITypes.GetAvailabilityModelQuery
>;
export const listAvailabilityModels = /* GraphQL */ `query ListAvailabilityModels(
  $id: ID
  $filter: ModelAvailabilityModelFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAvailabilityModels(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenant
      ownerId
      name
      bookingPages
      bookingTemplates
      locations
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAvailabilityModelsQueryVariables,
  APITypes.ListAvailabilityModelsQuery
>;
export const getFeatureRollout = /* GraphQL */ `query GetFeatureRollout($featureTag: String!) {
  getFeatureRollout(featureTag: $featureTag) {
    featureTag
    featureName
    firstWaveTenants
    firstWaveDate
    firstWaveState
    secondWaveTenants
    secondWaveDate
    secondWaveState
    thirdWaveDate
    thirdWaveState
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureRolloutQueryVariables,
  APITypes.GetFeatureRolloutQuery
>;
export const listFeatureRollouts = /* GraphQL */ `query ListFeatureRollouts(
  $featureTag: String
  $filter: ModelFeatureRolloutFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFeatureRollouts(
    featureTag: $featureTag
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      featureTag
      featureName
      firstWaveTenants
      firstWaveDate
      firstWaveState
      secondWaveTenants
      secondWaveDate
      secondWaveState
      thirdWaveDate
      thirdWaveState
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeatureRolloutsQueryVariables,
  APITypes.ListFeatureRolloutsQuery
>;
export const teamUserRoundRobinsByTenantId = /* GraphQL */ `query TeamUserRoundRobinsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamUserRoundRobinFilterInput
  $limit: Int
  $nextToken: String
) {
  teamUserRoundRobinsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamId
      tenantId
      userId
      lastCreatedEvent
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamUserRoundRobinsByTenantIdQueryVariables,
  APITypes.TeamUserRoundRobinsByTenantIdQuery
>;
export const teamUserRoundRobinsByUserId = /* GraphQL */ `query TeamUserRoundRobinsByUserId(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamUserRoundRobinFilterInput
  $limit: Int
  $nextToken: String
) {
  teamUserRoundRobinsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamId
      tenantId
      userId
      lastCreatedEvent
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamUserRoundRobinsByUserIdQueryVariables,
  APITypes.TeamUserRoundRobinsByUserIdQuery
>;
export const availabilityModelsByTenant = /* GraphQL */ `query AvailabilityModelsByTenant(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAvailabilityModelFilterInput
  $limit: Int
  $nextToken: String
) {
  availabilityModelsByTenant(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      ownerId
      name
      bookingPages
      bookingTemplates
      locations
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AvailabilityModelsByTenantQueryVariables,
  APITypes.AvailabilityModelsByTenantQuery
>;
export const availabilityModelsByOwnerId = /* GraphQL */ `query AvailabilityModelsByOwnerId(
  $ownerId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAvailabilityModelFilterInput
  $limit: Int
  $nextToken: String
) {
  availabilityModelsByOwnerId(
    ownerId: $ownerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      ownerId
      name
      bookingPages
      bookingTemplates
      locations
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AvailabilityModelsByOwnerIdQueryVariables,
  APITypes.AvailabilityModelsByOwnerIdQuery
>;
export const getUserData = /* GraphQL */ `query GetUserData($userId: String!, $link: String!, $tenant: String!) {
  getUserData(userId: $userId, link: $link, tenant: $tenant) {
    userId
    tenant
    owner
    link
    recordType
    userSettings {
      userDefaultAvailabilityId
      avatar
      fullName
      phoneDetails
      language
      dateFormat
      timeFormat
      country
      countryCode
      timeZone
      phoneNumber
      email
      isAdmin
      isQuickSetupFinished
      isInvitePeopleClosed
      isInstallInboxClosed
      defaultVideoIntegration
      calendarWarningDate
      isGuideTourFinished
      __typename
    }
    availabilityData {
      id
      name
      activeBookingPages
      isDefault
      __typename
    }
    lastActive
    version
    defaultAvailability
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserDataQueryVariables,
  APITypes.GetUserDataQuery
>;
export const listUserData = /* GraphQL */ `query ListUserData(
  $userId: String
  $linkTenant: ModelUserDataPrimaryCompositeKeyConditionInput
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserData(
    userId: $userId
    linkTenant: $linkTenant
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      version
      defaultAvailability
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserDataQueryVariables,
  APITypes.ListUserDataQuery
>;
export const userDataByTenant = /* GraphQL */ `query UserDataByTenant(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
) {
  userDataByTenant(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      version
      defaultAvailability
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserDataByTenantQueryVariables,
  APITypes.UserDataByTenantQuery
>;
export const userDataByLink = /* GraphQL */ `query UserDataByLink(
  $link: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
) {
  userDataByLink(
    link: $link
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      owner
      link
      recordType
      lastActive
      version
      defaultAvailability
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserDataByLinkQueryVariables,
  APITypes.UserDataByLinkQuery
>;
export const getUserEvent = /* GraphQL */ `query GetUserEvent(
  $userId: String!
  $eventDate: AWSDate!
  $eventId: String!
  $tenant: String!
) {
  getUserEvent(
    userId: $userId
    eventDate: $eventDate
    eventId: $eventId
    tenant: $tenant
  ) {
    userId
    tenant
    eventDate
    eventId
    workspaceId
    editKey
    shortLink
    shortEditLink
    bookingPageId
    bookingPageName
    owner
    startTime
    endTime
    beforeStartTime
    afterEndTime
    location {
      type
      settings
      __typename
    }
    integrations {
      type
      settings
      __typename
    }
    workspaceIntegrations {
      type
      settings
      status
      __typename
    }
    timeZone
    note
    noteFromHost
    phoneCallType
    inPersonType
    cohostsMembers {
      id
      type
      subType
      name
      email
      priority
      __typename
    }
    guestEmails
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    canceled {
      isCanceled
      type
      note
      __typename
    }
    executionIds {
      emailExecutionIds
      smsExecutionIds
      alertEmailExecutionIds
      alertSmsExecutionIds
      __typename
    }
    isNoShow
    host
    internalNotes
    isSMSAllowed
    hostName
    cancelationPolicy
    instructions
    journeyEventId
    journeyId
    groupBookingPageId
    labels {
      eventNameLabel
      detailsLabel
      guestsLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      confirmCancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    version
    noCustomerData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserEventQueryVariables,
  APITypes.GetUserEventQuery
>;
export const listUserEvents = /* GraphQL */ `query ListUserEvents(
  $userId: String
  $eventDateEventIdTenant: ModelUserEventPrimaryCompositeKeyConditionInput
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserEvents(
    userId: $userId
    eventDateEventIdTenant: $eventDateEventIdTenant
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      workspaceId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      noteFromHost
      phoneCallType
      inPersonType
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cancelationPolicy
      instructions
      journeyEventId
      journeyId
      groupBookingPageId
      version
      noCustomerData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserEventsQueryVariables,
  APITypes.ListUserEventsQuery
>;
export const userEventsByTenant = /* GraphQL */ `query UserEventsByTenant(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByTenant(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      workspaceId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      noteFromHost
      phoneCallType
      inPersonType
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cancelationPolicy
      instructions
      journeyEventId
      journeyId
      groupBookingPageId
      version
      noCustomerData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByTenantQueryVariables,
  APITypes.UserEventsByTenantQuery
>;
export const userEventsByEventDate = /* GraphQL */ `query UserEventsByEventDate(
  $eventDate: AWSDate!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByEventDate(
    eventDate: $eventDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      workspaceId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      noteFromHost
      phoneCallType
      inPersonType
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cancelationPolicy
      instructions
      journeyEventId
      journeyId
      groupBookingPageId
      version
      noCustomerData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByEventDateQueryVariables,
  APITypes.UserEventsByEventDateQuery
>;
export const userEventsByEventId = /* GraphQL */ `query UserEventsByEventId(
  $eventId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      workspaceId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      noteFromHost
      phoneCallType
      inPersonType
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cancelationPolicy
      instructions
      journeyEventId
      journeyId
      groupBookingPageId
      version
      noCustomerData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByEventIdQueryVariables,
  APITypes.UserEventsByEventIdQuery
>;
export const userEventsByWorkspaceId = /* GraphQL */ `query UserEventsByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserEventFilterInput
  $limit: Int
  $nextToken: String
) {
  userEventsByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      tenant
      eventDate
      eventId
      workspaceId
      editKey
      shortLink
      shortEditLink
      bookingPageId
      bookingPageName
      owner
      startTime
      endTime
      beforeStartTime
      afterEndTime
      timeZone
      note
      noteFromHost
      phoneCallType
      inPersonType
      guestEmails
      isNoShow
      host
      internalNotes
      isSMSAllowed
      hostName
      cancelationPolicy
      instructions
      journeyEventId
      journeyId
      groupBookingPageId
      version
      noCustomerData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserEventsByWorkspaceIdQueryVariables,
  APITypes.UserEventsByWorkspaceIdQuery
>;
export const getAdminData = /* GraphQL */ `query GetAdminData($email: String!, $tenantId: String!) {
  getAdminData(email: $email, tenantId: $tenantId) {
    email
    userId
    roleId
    status
    tenantId
    workspaceIds
    invitedById
    invitedByName
    team
    bookingTemplateIds
    expirationDate
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminDataQueryVariables,
  APITypes.GetAdminDataQuery
>;
export const listAdminData = /* GraphQL */ `query ListAdminData(
  $email: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelAdminDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAdminData(
    email: $email
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      userId
      roleId
      status
      tenantId
      workspaceIds
      invitedById
      invitedByName
      team
      bookingTemplateIds
      expirationDate
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminDataQueryVariables,
  APITypes.ListAdminDataQuery
>;
export const adminDataByTenantId = /* GraphQL */ `query AdminDataByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAdminDataFilterInput
  $limit: Int
  $nextToken: String
) {
  adminDataByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      userId
      roleId
      status
      tenantId
      workspaceIds
      invitedById
      invitedByName
      team
      bookingTemplateIds
      expirationDate
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminDataByTenantIdQueryVariables,
  APITypes.AdminDataByTenantIdQuery
>;
export const getTenant = /* GraphQL */ `query GetTenant($tenantId: String!) {
  getTenant(tenantId: $tenantId) {
    tenantId
    name
    status
    tenantStyle {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    workspace {
      id
      name
      isActive
      isPhoneRequired
      noCustomerData
      bookingPageIndex
      __typename
    }
    type
    note
    subscription
    version
    features {
      featureTag
      enabled
      enabledInWave
      __typename
    }
    defaultFeatureRolloutWave
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenantQueryVariables, APITypes.GetTenantQuery>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $tenantId: String
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTenants(
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      name
      status
      type
      note
      subscription
      version
      defaultFeatureRolloutWave
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const getBookingTemplate = /* GraphQL */ `query GetBookingTemplate($id: String!, $workspaceId: String!) {
  getBookingTemplate(id: $id, workspaceId: $workspaceId) {
    id
    tenantId
    workspaceId
    type
    inviteOthers
    enterNote
    labels {
      eventNameLabel
      detailsLabel
      guestsLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      confirmCancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    calendar {
      timeFormat
      calendarFormat
      timeInterval
      topOfInterval
      timeZoneType
      selectedTimeZone
      defaultTimeZone
      __typename
    }
    potentialHosts
    potentialTeams
    what {
      customName
      color
      instructions
      noteFromHost
      __typename
    }
    where {
      locationTypes
      videoConferenceType
      defaultLocationType
      inPersonType
      customAddress
      locations
      phoneCallType
      customPhone
      customCountryCode
      skipTheWhereStep
      __typename
    }
    when {
      afterEndTime
      beforeStartTime
      eventsPerDay
      rollingWindowDays
      expiresAt
      rollingExpiresAt
      __typename
    }
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    notifications {
      __typename
    }
    confirmationsPage {
      type
      externalLink
      anotherEventEnabled
      rescheduleEnabled
      cancelEnabled
      cancelationPolicy
      __typename
    }
    lastModify
    enabled
    smartAlertIds
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingTemplateQueryVariables,
  APITypes.GetBookingTemplateQuery
>;
export const listBookingTemplates = /* GraphQL */ `query ListBookingTemplates(
  $id: String
  $workspaceId: ModelStringKeyConditionInput
  $filter: ModelBookingTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingTemplates(
    id: $id
    workspaceId: $workspaceId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      potentialHosts
      potentialTeams
      lastModify
      enabled
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingTemplatesQueryVariables,
  APITypes.ListBookingTemplatesQuery
>;
export const bookingTemplatesByTenantId = /* GraphQL */ `query BookingTemplatesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingTemplatesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      potentialHosts
      potentialTeams
      lastModify
      enabled
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingTemplatesByTenantIdQueryVariables,
  APITypes.BookingTemplatesByTenantIdQuery
>;
export const bookingTemplatesByWorkspaceId = /* GraphQL */ `query BookingTemplatesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingTemplatesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      potentialHosts
      potentialTeams
      lastModify
      enabled
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingTemplatesByWorkspaceIdQueryVariables,
  APITypes.BookingTemplatesByWorkspaceIdQuery
>;
export const getBookingPage = /* GraphQL */ `query GetBookingPage($id: String!, $workspaceId: String!) {
  getBookingPage(id: $id, workspaceId: $workspaceId) {
    id
    tenantId
    workspaceId
    type
    inviteOthers
    enterNote
    labels {
      eventNameLabel
      detailsLabel
      guestsLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      confirmCancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    calendar {
      timeFormat
      calendarFormat
      timeInterval
      topOfInterval
      timeZoneType
      selectedTimeZone
      defaultTimeZone
      __typename
    }
    what {
      customName
      color
      bookingTemplateId
      isActive
      instructions
      noteFromHost
      __typename
    }
    who {
      hostAssignmentMode
      hostRescheduleWith
      __typename
    }
    where {
      locationTypes
      videoConferenceType
      defaultLocationType
      inPersonType
      customAddress
      locations
      phoneCallType
      customPhone
      customCountryCode
      skipTheWhereStep
      __typename
    }
    when {
      afterEndTime
      beforeStartTime
      eventsPerDay
      rollingWindowDays
      expiresAt
      rollingExpiresAt
      __typename
    }
    inputFields {
      id
      answers
      enabled
      label
      required
      type
      value
      fieldType
      __typename
    }
    notifications {
      __typename
    }
    confirmationsPage {
      type
      externalLink
      anotherEventEnabled
      rescheduleEnabled
      cancelEnabled
      cancelationPolicy
      __typename
    }
    lastModify
    displayId
    guestsLimit
    shortLink
    adminOnly
    smartAlertIds
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingPageQueryVariables,
  APITypes.GetBookingPageQuery
>;
export const listBookingPages = /* GraphQL */ `query ListBookingPages(
  $id: String
  $workspaceId: ModelStringKeyConditionInput
  $filter: ModelBookingPageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingPages(
    id: $id
    workspaceId: $workspaceId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      lastModify
      displayId
      guestsLimit
      shortLink
      adminOnly
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingPagesQueryVariables,
  APITypes.ListBookingPagesQuery
>;
export const bookingPagesByTenantId = /* GraphQL */ `query BookingPagesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingPagesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      lastModify
      displayId
      guestsLimit
      shortLink
      adminOnly
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingPagesByTenantIdQueryVariables,
  APITypes.BookingPagesByTenantIdQuery
>;
export const bookingPagesByWorkspaceId = /* GraphQL */ `query BookingPagesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingPagesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      type
      inviteOthers
      enterNote
      lastModify
      displayId
      guestsLimit
      shortLink
      adminOnly
      smartAlertIds
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingPagesByWorkspaceIdQueryVariables,
  APITypes.BookingPagesByWorkspaceIdQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: String!, $tenantId: String!) {
  getRole(id: $id, tenantId: $tenantId) {
    id
    tenantId
    name
    isActive
    isCustom
    hasChromeAccess
    users
    availability
    myBookingPages
    allBookingPages
    bookingTemplates
    resources
    teams
    smartAlerts
    smartAlertTemplates
    locations
    journey
    isAccessToAllWorkspaces
    workspaceAdminActions
    updatedBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $id: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRoles(
    id: $id
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      name
      isActive
      isCustom
      hasChromeAccess
      users
      availability
      myBookingPages
      allBookingPages
      bookingTemplates
      resources
      teams
      smartAlerts
      smartAlertTemplates
      locations
      journey
      isAccessToAllWorkspaces
      workspaceAdminActions
      updatedBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const rolesByTenantId = /* GraphQL */ `query RolesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  rolesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      name
      isActive
      isCustom
      hasChromeAccess
      users
      availability
      myBookingPages
      allBookingPages
      bookingTemplates
      resources
      teams
      smartAlerts
      smartAlertTemplates
      locations
      journey
      isAccessToAllWorkspaces
      workspaceAdminActions
      updatedBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RolesByTenantIdQueryVariables,
  APITypes.RolesByTenantIdQuery
>;
export const getLicense = /* GraphQL */ `query GetLicense($id: String!, $tenantId: String!) {
  getLicense(id: $id, tenantId: $tenantId) {
    id
    tenantId
    type
    startDate
    endDate
    owned
    assigned
    hasScheduledChanges
    scheduledChanges {
      id
      seats
      plan
      changeBy
      requestDate
      __typename
    }
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLicenseQueryVariables,
  APITypes.GetLicenseQuery
>;
export const listLicenses = /* GraphQL */ `query ListLicenses(
  $id: String
  $tenantId: ModelStringKeyConditionInput
  $filter: ModelLicenseFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLicenses(
    id: $id
    tenantId: $tenantId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      type
      startDate
      endDate
      owned
      assigned
      hasScheduledChanges
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLicensesQueryVariables,
  APITypes.ListLicensesQuery
>;
export const licensesByTenantId = /* GraphQL */ `query LicensesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLicenseFilterInput
  $limit: Int
  $nextToken: String
) {
  licensesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      type
      startDate
      endDate
      owned
      assigned
      hasScheduledChanges
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LicensesByTenantIdQueryVariables,
  APITypes.LicensesByTenantIdQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: String!) {
  getAccount(id: $id) {
    id
    tenantIds
    name
    createdBy
    note
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $id: String
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccounts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantIds
      name
      createdBy
      note
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getStaff = /* GraphQL */ `query GetStaff($email: String!) {
  getStaff(email: $email) {
    email
    userId
    userName
    roleName
    status
    lastLogin
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStaffQueryVariables, APITypes.GetStaffQuery>;
export const listStaff = /* GraphQL */ `query ListStaff(
  $email: String
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStaff(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      userId
      userName
      roleName
      status
      lastLogin
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListStaffQueryVariables, APITypes.ListStaffQuery>;
export const getTeam = /* GraphQL */ `query GetTeam($id: String!) {
  getTeam(id: $id) {
    id
    tenantId
    workspaceId
    name
    active
    workspacesForRead
    members {
      userId
      priority
      __typename
    }
    assignmentMode
    rescheduleWith
    createdBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $id: String
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeams(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      workspacesForRead
      assignmentMode
      rescheduleWith
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const teamsByTenantId = /* GraphQL */ `query TeamsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      workspacesForRead
      assignmentMode
      rescheduleWith
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamsByTenantIdQueryVariables,
  APITypes.TeamsByTenantIdQuery
>;
export const teamsByWorkspaceId = /* GraphQL */ `query TeamsByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      workspacesForRead
      assignmentMode
      rescheduleWith
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamsByWorkspaceIdQueryVariables,
  APITypes.TeamsByWorkspaceIdQuery
>;
export const getPhysicalLocation = /* GraphQL */ `query GetPhysicalLocation($id: String!) {
  getPhysicalLocation(id: $id) {
    id
    tenantId
    workspaceId
    name
    active
    address {
      name
      zip
      state
      city
      country
      lat
      lng
      __typename
    }
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPhysicalLocationQueryVariables,
  APITypes.GetPhysicalLocationQuery
>;
export const listPhysicalLocations = /* GraphQL */ `query ListPhysicalLocations(
  $id: String
  $filter: ModelPhysicalLocationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPhysicalLocations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhysicalLocationsQueryVariables,
  APITypes.ListPhysicalLocationsQuery
>;
export const physicalLocationsByTenantId = /* GraphQL */ `query PhysicalLocationsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPhysicalLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  physicalLocationsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhysicalLocationsByTenantIdQueryVariables,
  APITypes.PhysicalLocationsByTenantIdQuery
>;
export const physicalLocationsByWorkspaceId = /* GraphQL */ `query PhysicalLocationsByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPhysicalLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  physicalLocationsByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhysicalLocationsByWorkspaceIdQueryVariables,
  APITypes.PhysicalLocationsByWorkspaceIdQuery
>;
export const getSmartAlert = /* GraphQL */ `query GetSmartAlert($id: String!) {
  getSmartAlert(id: $id) {
    id
    tenantId
    workspaceId
    name
    icon
    isHide
    shareWith {
      workspaceId
      userIds
      __typename
    }
    whenSend {
      eventSendType
      delay
      immediately
      __typename
    }
    whomSend {
      __typename
    }
    createdBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSmartAlertQueryVariables,
  APITypes.GetSmartAlertQuery
>;
export const listSmartAlerts = /* GraphQL */ `query ListSmartAlerts(
  $id: String
  $filter: ModelSmartAlertFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSmartAlerts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSmartAlertsQueryVariables,
  APITypes.ListSmartAlertsQuery
>;
export const smartAlertsByTenantId = /* GraphQL */ `query SmartAlertsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSmartAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  smartAlertsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SmartAlertsByTenantIdQueryVariables,
  APITypes.SmartAlertsByTenantIdQuery
>;
export const smartAlertsByWorkspaceId = /* GraphQL */ `query SmartAlertsByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSmartAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  smartAlertsByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SmartAlertsByWorkspaceIdQueryVariables,
  APITypes.SmartAlertsByWorkspaceIdQuery
>;
export const getSmartAlertTemplate = /* GraphQL */ `query GetSmartAlertTemplate($id: String!) {
  getSmartAlertTemplate(id: $id) {
    id
    tenantId
    workspaceId
    name
    description
    icon
    isHide
    shareWith {
      workspaceId
      userIds
      __typename
    }
    whenSend {
      eventSendType
      delay
      immediately
      __typename
    }
    whomSend {
      __typename
    }
    createdBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSmartAlertTemplateQueryVariables,
  APITypes.GetSmartAlertTemplateQuery
>;
export const listSmartAlertTemplates = /* GraphQL */ `query ListSmartAlertTemplates(
  $id: String
  $filter: ModelSmartAlertTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSmartAlertTemplates(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      description
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSmartAlertTemplatesQueryVariables,
  APITypes.ListSmartAlertTemplatesQuery
>;
export const smartAlertTemplatesByTenantId = /* GraphQL */ `query SmartAlertTemplatesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSmartAlertTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  smartAlertTemplatesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      description
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SmartAlertTemplatesByTenantIdQueryVariables,
  APITypes.SmartAlertTemplatesByTenantIdQuery
>;
export const smartAlertTemplatesByWorkspaceId = /* GraphQL */ `query SmartAlertTemplatesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSmartAlertTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  smartAlertTemplatesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      description
      icon
      isHide
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SmartAlertTemplatesByWorkspaceIdQueryVariables,
  APITypes.SmartAlertTemplatesByWorkspaceIdQuery
>;
export const getJourney = /* GraphQL */ `query GetJourney($id: String!) {
  getJourney(id: $id) {
    id
    workspaceId
    tenantId
    name
    createdBy
    active
    shortLink
    lastEventDate
    eventsCount
    root {
      id
      type
      __typename
    }
    labels {
      eventNameLabel
      detailsLabel
      guestsLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      confirmCancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJourneyQueryVariables,
  APITypes.GetJourneyQuery
>;
export const listJourneys = /* GraphQL */ `query ListJourneys(
  $id: String
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listJourneys(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      workspaceId
      tenantId
      name
      createdBy
      active
      shortLink
      lastEventDate
      eventsCount
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneysQueryVariables,
  APITypes.ListJourneysQuery
>;
export const journeysByWorkspaceId = /* GraphQL */ `query JourneysByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  journeysByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workspaceId
      tenantId
      name
      createdBy
      active
      shortLink
      lastEventDate
      eventsCount
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JourneysByWorkspaceIdQueryVariables,
  APITypes.JourneysByWorkspaceIdQuery
>;
export const journeysByTenantId = /* GraphQL */ `query JourneysByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  journeysByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workspaceId
      tenantId
      name
      createdBy
      active
      shortLink
      lastEventDate
      eventsCount
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JourneysByTenantIdQueryVariables,
  APITypes.JourneysByTenantIdQuery
>;
export const getJourneyEvent = /* GraphQL */ `query GetJourneyEvent($id: String!) {
  getJourneyEvent(id: $id) {
    id
    tenantId
    journeyId
    eventId
    steps {
      __typename
    }
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJourneyEventQueryVariables,
  APITypes.GetJourneyEventQuery
>;
export const listJourneyEvents = /* GraphQL */ `query ListJourneyEvents(
  $id: String
  $filter: ModelJourneyEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listJourneyEvents(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      journeyId
      eventId
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyEventsQueryVariables,
  APITypes.ListJourneyEventsQuery
>;
export const journeyEventsByTenantId = /* GraphQL */ `query JourneyEventsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyEventFilterInput
  $limit: Int
  $nextToken: String
) {
  journeyEventsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      journeyId
      eventId
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JourneyEventsByTenantIdQueryVariables,
  APITypes.JourneyEventsByTenantIdQuery
>;
export const journeyEventsByJourneyId = /* GraphQL */ `query JourneyEventsByJourneyId(
  $journeyId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyEventFilterInput
  $limit: Int
  $nextToken: String
) {
  journeyEventsByJourneyId(
    journeyId: $journeyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      journeyId
      eventId
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JourneyEventsByJourneyIdQueryVariables,
  APITypes.JourneyEventsByJourneyIdQuery
>;
export const getGroupBookingPage = /* GraphQL */ `query GetGroupBookingPage($id: String!) {
  getGroupBookingPage(id: $id) {
    id
    tenantId
    workspaceId
    name
    active
    adminOnly
    labels {
      eventNameLabel
      detailsLabel
      guestsLabel
      cancelLabel
      bookButtonLabel
      updateButtonLabel
      bookAnotherButtonLabel
      cancelButtonLabel
      confirmCancelButtonLabel
      bookTitle
      bookDescription
      rescheduleTitle
      rescheduleDescription
      cancelTitle
      cancelDescription
      __typename
    }
    style {
      logoImage
      backgroundImage
      backgroundType
      css
      footerHtml
      primaryColor
      backgroundColor
      fontColor
      fontFamily
      __typename
    }
    shortLink
    bookingPages {
      bookingPageId
      description
      __typename
    }
    brandingBookingPageId
    createdBy
    version
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupBookingPageQueryVariables,
  APITypes.GetGroupBookingPageQuery
>;
export const listGroupBookingPages = /* GraphQL */ `query ListGroupBookingPages(
  $id: String
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGroupBookingPages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupBookingPagesQueryVariables,
  APITypes.ListGroupBookingPagesQuery
>;
export const groupBookingPagesByTenantId = /* GraphQL */ `query GroupBookingPagesByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  groupBookingPagesByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupBookingPagesByTenantIdQueryVariables,
  APITypes.GroupBookingPagesByTenantIdQuery
>;
export const groupBookingPagesByWorkspaceId = /* GraphQL */ `query GroupBookingPagesByWorkspaceId(
  $workspaceId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupBookingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  groupBookingPagesByWorkspaceId(
    workspaceId: $workspaceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      workspaceId
      name
      active
      adminOnly
      shortLink
      brandingBookingPageId
      createdBy
      version
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupBookingPagesByWorkspaceIdQueryVariables,
  APITypes.GroupBookingPagesByWorkspaceIdQuery
>;
export const getActionLog = /* GraphQL */ `query GetActionLog($id: String!) {
  getActionLog(id: $id) {
    id
    tenantId
    actionType
    userId
    executor
    action
    createdAt
    version
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionLogQueryVariables,
  APITypes.GetActionLogQuery
>;
export const listActionLogs = /* GraphQL */ `query ListActionLogs(
  $id: String
  $filter: ModelActionLogFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listActionLogs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tenantId
      actionType
      userId
      executor
      action
      createdAt
      version
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionLogsQueryVariables,
  APITypes.ListActionLogsQuery
>;
export const actionLogsByTenantId = /* GraphQL */ `query ActionLogsByTenantId(
  $tenantId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelActionLogFilterInput
  $limit: Int
  $nextToken: String
) {
  actionLogsByTenantId(
    tenantId: $tenantId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      actionType
      userId
      executor
      action
      createdAt
      version
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActionLogsByTenantIdQueryVariables,
  APITypes.ActionLogsByTenantIdQuery
>;
export const actionLogsByActionType = /* GraphQL */ `query ActionLogsByActionType(
  $actionType: ActionType!
  $sortDirection: ModelSortDirection
  $filter: ModelActionLogFilterInput
  $limit: Int
  $nextToken: String
) {
  actionLogsByActionType(
    actionType: $actionType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      actionType
      userId
      executor
      action
      createdAt
      version
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActionLogsByActionTypeQueryVariables,
  APITypes.ActionLogsByActionTypeQuery
>;
