import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { MeetingAction, MeetingActionTypes } from './actions';
import { MemberDTO } from './types';

const members = (state: MemberDTO[] = [], action: MeetingAction | GlobalAction) => {
  switch (action.type) {
    case MeetingActionTypes.GET_MEMBERS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const isFetching = (state = false, action: MeetingAction | GlobalAction) => {
  switch (action.type) {
    case MeetingActionTypes.GET_MEMBERS_REQUEST:
      return true;
    case MeetingActionTypes.GET_MEMBERS_SUCCESS:
    case MeetingActionTypes.GET_MEMBERS_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({ members, isFetching });