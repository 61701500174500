import { IntegrationType, MemberInput, TeamMember, VideoConferenceType } from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';

export enum AccordionIndexValues {
  what = 'what',
  who = 'who',
  where = 'where',
  when = 'when',
  how = 'how',
  alerts = 'alerts',
  invitee = 'invitee',
  after = 'after',
}

export type AccordionIndexes = {
  [AccordionIndexValues.what]: boolean;
  [AccordionIndexValues.who]: boolean;
  [AccordionIndexValues.where]: boolean;
  [AccordionIndexValues.when]: boolean;
  [AccordionIndexValues.how]: boolean;
  [AccordionIndexValues.alerts]: boolean;
  [AccordionIndexValues.invitee]: boolean;
  [AccordionIndexValues.after]: boolean;
};

export enum NotificationTypes {
  CONFIRMATION = 'CONFIRMATION',
  RESCHEDULE = 'RESCHEDULE',
  REMINDER = 'REMINDER',
  CANCELATION = 'CANCELATION',
  FOLLOW_UP = 'FOLLOW_UP',
  SMS = 'SMS',
}

export type SmartAlertModalRequest = { isEdit?: boolean; isReadOnly?: boolean };

export type TimeZoneOptionType = timeZoneItem & {
  value?: string;
};

export type MemberDTO = Omit<MemberInput, 'id'> & {
  id: string;
  hasPhoneNumber: boolean;
  integrationTypes: IntegrationType[];
  avatar?: string;
  defaultVideoIntegration?: VideoConferenceType;
  active?: boolean;
  teamMember?: TeamMember[];
  isDeleted?: boolean;
};

export type GetMembersResponse = {
  members: MemberDTO[];
};
