import { t } from '../../../i18n/i18n';

export default {
  events: t('ConnectIntegrationPage:SALESFORCE_EVENTS'),
  bookUsers: t('ConnectIntegrationPage:SALESFORCE_BOOK_USERS'),
  create: t('ConnectIntegrationPage:CREATE'),
  leads: t('ConnectIntegrationPage:LEADS'),
  contactsPersonAccounts: t('ConnectIntegrationPage:CONTACTS_PERSON_ACCOUNTS'),
  bookingWithSFSumoInbox: t('ConnectIntegrationPage:SALESFORCE_BOOKING_WITH_SF_SUMO_INBOX'),
  personAccount: t('ConnectIntegrationPage:PERSON_ACCOUNT'),
  orgType: t('ConnectIntegrationPage:SALESFORCE_ORG_TYPE'),
  production: t('ConnectIntegrationPage:PRODUCTION'),
  sandbox: t('ConnectIntegrationPage:SANDBOX'),
  allowBookingCEP: t('ConnectIntegrationPage:ALLOW_BOOKING_CEP'),
  usersAndResourcePools: t('ConnectIntegrationPage:USERS_AND_RESOURCE_POOLS'),
  salesforceOrgId: t('ConnectIntegrationPage:SALESFORCE_ORG_ID'),
  generateCode: t('ConnectIntegrationPage:GENERATE_CODE'),
  change: t('ConnectIntegrationPage:CHANGE'),
  authCode: t('ConnectIntegrationPage:AUTH_CODE'),
  authCodeDescription: t('ConnectIntegrationPage:AUTH_CODE_DESCRIPTION'),
  instruction: t('ConnectIntegrationPage:INSTRUCTION'),
  copyCode: t('ConnectIntegrationPage:COPY_CODE'),
  disconnect: t('ConnectIntegrationPage:DISCONNECT'),
  copiedLink: t('Common:COPIED_LINK'),
};