import { call, put, select, takeLatest } from "redux-saga/effects";
import { GetMembersResponse } from "./types";
import { getMembers } from "./service";
import { authenticationSelectors } from "../authentication";
import { meetingActions, MeetingActionTypes } from "./actions";
import { handleServiceError } from "../utils/reduxUtils";
import { GET_MEMBERS_ERROR_TOAST } from "./constants";

function* getMembersSaga() {
  try {
    const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: GetMembersResponse = yield call(getMembers, workspace);

    yield put(meetingActions.getMembersSuccess(response.members));
  } catch (error: unknown) {
    yield put(meetingActions.getMembersFail(error?.toString()));
    yield call(handleServiceError, error, GET_MEMBERS_ERROR_TOAST, true);
  }
}

export function* watchMeetingSaga() {
  yield takeLatest(MeetingActionTypes.GET_MEMBERS_REQUEST, getMembersSaga);
}

export const meetingSagas = {
  getMembers: getMembersSaga,
};