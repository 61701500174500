import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { WorkspaceIntegration } from '../../generated-sources/internal-api/models/WorkspaceIntegration';
import { CodeRedirectPayload, IntegrationFilter, SalesforceCustomParameters } from './types';

export enum IntegrationsActionTypes {
  SET_DEFAULT_INTEGRATIONS = 'integrations/SET_DEFAULT_INTEGRATIONS',
  SET_CONNECT_INTEGRATION_TYPE = 'integrations/SET_CONNECT_INTEGRATION_TYPE',
  SET_INTEGRATION_FILTER = 'integrations/SET_INTEGRATION_FILTER',
  GET_CODE_REDIRECT = 'integrations/GET_CODE_REDIRECT',
  GENERATE_CEP_CODE_AUTH = 'integrations/GENERATE_CEP_CODE_AUTH',
  DISCONNECT_CEP = 'integrations/DISCONNECT_CEP',
  CONNECT_INTEGRATION_REQUEST = 'integrations/CONNECT_INTEGRATION_REQUEST',
  CONNECT_INTEGRATION_SUCCESS = 'integrations/CONNECT_INTEGRATION_SUCCESS',
  CONNECT_INTEGRATION_FAIL = 'integrations/CONNECT_INTEGRATION_FAIL',
}

export type IntegrationsAction =
  | { type: IntegrationsActionTypes.SET_DEFAULT_INTEGRATIONS }
  | {
      type: IntegrationsActionTypes.SET_CONNECT_INTEGRATION_TYPE;
      payload: IntegrationType | WorkspaceIntegrationType | AppType;
    }
  | { type: IntegrationsActionTypes.SET_INTEGRATION_FILTER; payload: IntegrationFilter }
  | {
      type: IntegrationsActionTypes.GENERATE_CEP_CODE_AUTH;
      workspaceId: string;
      customParameters: SalesforceCustomParameters;
    }
  | {
      type: IntegrationsActionTypes.DISCONNECT_CEP;
      workspaceId: string;
      workspaceIntegration: WorkspaceIntegration;
    }
  | { type: IntegrationsActionTypes.GET_CODE_REDIRECT; payload: CodeRedirectPayload }
  | { type: IntegrationsActionTypes.CONNECT_INTEGRATION_REQUEST; payload: ConnectIntegrationRequest }
  | { type: IntegrationsActionTypes.CONNECT_INTEGRATION_SUCCESS }
  | { type: IntegrationsActionTypes.CONNECT_INTEGRATION_FAIL; error: unknown };

const setDefaultIntegration = (): IntegrationsAction => ({
  type: IntegrationsActionTypes.SET_DEFAULT_INTEGRATIONS,
});

const setConnectIntegrationType = (
  payload: IntegrationType | WorkspaceIntegrationType | AppType
): IntegrationsAction => ({
  type: IntegrationsActionTypes.SET_CONNECT_INTEGRATION_TYPE,
  payload,
});

const setIntegrationFilter = (payload: IntegrationFilter): IntegrationsAction => ({
  type: IntegrationsActionTypes.SET_INTEGRATION_FILTER,
  payload,
});

const getCodeRedirect = (payload: CodeRedirectPayload): IntegrationsAction => ({
  type: IntegrationsActionTypes.GET_CODE_REDIRECT,
  payload,
});

const generateCepAuthCode = (
  workspaceId: string,
  customParameters: SalesforceCustomParameters
): IntegrationsAction => ({
  type: IntegrationsActionTypes.GENERATE_CEP_CODE_AUTH,
  workspaceId,
  customParameters,
});

const disconnectCEP = (workspaceId: string, workspaceIntegration: WorkspaceIntegration): IntegrationsAction => ({
  type: IntegrationsActionTypes.DISCONNECT_CEP,
  workspaceId,
  workspaceIntegration,
});

const connectIntegrationRequest = (payload: ConnectIntegrationRequest): IntegrationsAction => ({
  type: IntegrationsActionTypes.CONNECT_INTEGRATION_REQUEST,
  payload,
});
const connectIntegrationSuccess = (): IntegrationsAction => ({
  type: IntegrationsActionTypes.CONNECT_INTEGRATION_SUCCESS,
});
const connectIntegrationFail = (error: unknown): IntegrationsAction => ({
  type: IntegrationsActionTypes.CONNECT_INTEGRATION_FAIL,
  error,
});

export const integrationsActions = {
  setDefaultIntegration,
  setConnectIntegrationType,
  setIntegrationFilter,
  generateCepAuthCode,
  disconnectCEP,
  getCodeRedirect,
  connectIntegrationRequest,
  connectIntegrationSuccess,
  connectIntegrationFail,
};