import { all, call, CallEffect, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { UpdateBookingTemplateInput, VideoConferenceType, WorkspaceInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CLONE_BOOKING_TEMPLATE_ERROR_TOAST,
  CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST,
  DEFAULT_BOOKING_TEMPLATE_DATA,
  DELETE_BOOKING_TEMPLATES_ERROR_TOAST,
  DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST,
  GET_BOOKING_TEMPLATES_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST,
} from './constants';
import { BookingTemplatesActionTypes, bookingTemplatesActions } from './actions';
import { bookingTemplatesSelectors } from './selectors';
import {
  deleteBookingTemplates,
  getBookingTemplate,
  getBookingTemplates,
  getBookingTemplatesByTenant,
  upsertBookingTemplate,
} from './service';
import { authenticationSelectors } from '../authentication';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { userSettingsSelectors } from '../userSettings';
import { handleServiceError } from '../utils/reduxUtils';
import { workspacesSelectors } from '../workspaces';
import { GetBookingTemplatesResponse } from './types';
import { smartAlertsSagas } from '../smartAlerts/sagas';
import { locationsSagas } from '../locations/sagas';
import { bookingPagesSagas } from '../bookingPages/sagas';
import { UpsertBookingTemplateResponse } from '../../generated-sources/internal-api/models/UpsertBookingTemplateResponse';
import { BookingTemplate } from '../../generated-sources/internal-api/models/BookingTemplate';
import { EMPTY_ACCORDION_INDEXES } from '../meeting/constants';
import { isLocationUnassigned } from '../meeting';
import { meetingSagas } from '../meeting/sagas';
import { GetBookingTemplatesByTenantIdResponse } from '../../generated-sources/internal-api/models/GetBookingTemplatesByTenantIdResponse';

const selectCreateBookingTemplateRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingTemplatesSelectors.selectBookingTemplate,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, lastModify) => ({
    ...bookingTemplate,
    workspaceId,
    lastModify,
  })
);

const selectCloneBookingTemplateRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingTemplatesSelectors.selectBookingTemplate,
  bookingTemplatesSelectors.selectCloneName,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, cloneName, lastModify) => ({
    ...bookingTemplate,
    id: '',
    workspaceId,
    lastModify,
    what: {
      ...bookingTemplate.what,
      customName: cloneName,
    },
  })
);

function* getBookingTemplatesSaga() {
  try {
    const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: GetBookingTemplatesResponse = yield call(getBookingTemplates, workspace);

    yield put(bookingTemplatesActions.getBookingTemplatesSuccess(response.bookingTemplates));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* getBookingTemplatesPageSaga(
  action: ReturnType<typeof bookingTemplatesActions.getBookingTemplatesPageRequest>
) {
  try {
    if (action.type === BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST) {
      const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
      let bookingTemplates: UpdateBookingTemplateInput[] = yield select(
        bookingTemplatesSelectors.selectBookingTemplates
      );
      const requestsList = [
        ...(!action.isInitialVisit
          ? [
              call(getBookingTemplates, workspace),
              call(bookingPagesSagas.getBookingPages), // required for delete modal
            ]
          : []),
        call(meetingSagas.getMembers),
      ];
      const [response]: [GetBookingTemplatesResponse] = yield all(requestsList);

      if (!action.isInitialVisit && response && response.bookingTemplates) {
        bookingTemplates = response.bookingTemplates;
      }

      yield put(bookingTemplatesActions.getBookingTemplatesSuccess(bookingTemplates));
    }
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* getBookingTemplateSaga(action: ReturnType<typeof bookingTemplatesActions.getBookingTemplateRequest>) {
  try {
    if (action.type === BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST) {
      const id = action.payload;

      // prepopulate with existing data
      if (id) {
        const bookingTemplates: UpdateBookingTemplateInput[] = yield select(
          bookingTemplatesSelectors.selectBookingTemplates
        );
        const bookingTemplate = bookingTemplates.find((template) => template.id === id);
        if (bookingTemplate) {
          yield put(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
        }
      }

      // set default data for new record
      if (!id) {
        const currentWorkspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
        const currentWorkspace: WorkspaceInput = yield select(
          workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
        );
        const userId: string = yield select(authenticationSelectors.selectUserId);
        const defaultVideoConference: VideoConferenceType = yield select(
          userSettingsSelectors.selectDefaultVideoIntegration
        );
        const bookingTemplate: UpdateBookingTemplateInput = {
          ...DEFAULT_BOOKING_TEMPLATE_DATA,
          workspaceId: currentWorkspaceId,
          labels: currentWorkspace.labels,
          style: currentWorkspace.style,
          potentialHosts: [userId],
          where: {
            ...DEFAULT_BOOKING_TEMPLATE_DATA.where,
            videoConferenceType: defaultVideoConference,
          },
        };
        yield put(bookingTemplatesActions.getBookingTemplateSuccess(bookingTemplate));
      }

      const requestsList = [
        ...(id ? [call(getBookingTemplate, id)] : []),
        call(meetingSagas.getMembers),
        call(smartAlertsSagas.getSmartTypes),
        call(locationsSagas.getLocations),
      ];
      const [response]: [GetBookingTemplatesResponse] = yield all(requestsList);

      if (id && response?.bookingTemplates?.length) {
        const bookingTemplate = response.bookingTemplates[0];
        yield put(bookingTemplatesActions.getBookingTemplateSuccess(bookingTemplate));

        const { potentialHosts, potentialTeams, where } = bookingTemplate;

        // Open "who" section if no hosts or teams
        if (!potentialHosts?.length && !potentialTeams?.length) {
          yield put(bookingTemplatesActions.updateAccordionIndexes({ who: true }));
        }
        // Open "where" section if location types include "In Person" and no locations exist
        else if (isLocationUnassigned(where)) {
          yield put(bookingTemplatesActions.updateAccordionIndexes({ where: true }));
        }
      } else if (id) {
        throw new Error('BookingTemaplate not found');
      }
    }
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* createBookingTemplateSaga() {
  try {
    const bookingTemplate: BookingTemplate = yield select(selectCreateBookingTemplateRequest);

    const response: UpsertBookingTemplateResponse = yield call(upsertBookingTemplate, bookingTemplate);
    if (response.bookingTemplateId) {
      yield put(
        bookingTemplatesActions.updateRecord({
          id: response.bookingTemplateId,
        })
      );
      navigationService.navigateTo(Path.EditBookingTemplate.replace(':bookingTemplateId', response.bookingTemplateId));
    }
    yield put(bookingTemplatesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));

    yield put(bookingTemplatesActions.createBookingTemplatesuccess());
    yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.createBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* cloneBookingTemplateSaga() {
  try {
    const createUserDataInput: BookingTemplate = yield select(selectCloneBookingTemplateRequest);

    yield call(upsertBookingTemplate, createUserDataInput);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.cloneBookingTemplatesuccess());
    yield put(notificationsActions.showToast(CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.cloneBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* updateBookingTemplateSaga() {
  try {
    const bookingTemplate: BookingTemplate = yield select(bookingTemplatesSelectors.selectBookingTemplate);
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(upsertBookingTemplate, { ...bookingTemplate, lastModify });

    yield put(bookingTemplatesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));

    yield put(bookingTemplatesActions.saveBookingTemplatesuccess());
    yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.saveBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* activateBookingTemplateSaga(action: ReturnType<typeof bookingTemplatesActions.enableBookingTemplateRequest>) {
  try {
    if (action.type === BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST) {
      const bookingTemplate = action.payload;
      const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
      yield call(upsertBookingTemplate, { ...bookingTemplate, lastModify } as BookingTemplate);

      yield put(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
      yield put(bookingTemplatesActions.enableBookingTemplatesuccess());
      yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
      yield put(bookingTemplatesActions.getBookingTemplatesRequest());
    }
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.enableBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* deleteBookingTemplatesSaga() {
  try {
    const ids: string[] = yield select(bookingTemplatesSelectors.selectSelectedBookingTemplates);
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);

    yield call(deleteBookingTemplates, ids, workspaceId);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.deleteBookingTemplatesSuccess());
    yield put(notificationsActions.showToast(DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.deleteBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_BOOKING_TEMPLATES_ERROR_TOAST);
  }
}

function* getBookingTemplatesByTenantSaga() {
  try {
    const response: GetBookingTemplatesByTenantIdResponse = yield call(getBookingTemplatesByTenant);

    yield put(bookingTemplatesActions.getBookingTemplatesByTenantSuccess(response.bookingTemplates || []));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplatesByTenantFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* updateUserTemplates(userId: string, templateIds: (string | null)[]) {
  const templatesInfo: BookingTemplate[] = yield select(bookingTemplatesSelectors.selectBookingTemplatesForTenant);
  const prevAssignedTemplates = templatesInfo.filter(
    (template) => template.id && template.potentialHosts?.includes(userId)
  );
  const requests: CallEffect<UpsertBookingTemplateResponse>[] = [];

  prevAssignedTemplates.forEach(
    (template) =>
      template.id &&
      !templateIds.includes(template.id) &&
      requests.push(
        call(upsertBookingTemplate, {
          ...template,
          potentialHosts: template.potentialHosts?.filter((host) => host != userId),
        } as BookingTemplate)
      )
  );

  templateIds.forEach((id) => {
    const isNew = !prevAssignedTemplates.some((template) => template.id === id);
    const newTemplate = isNew ? templatesInfo.find((template) => template.id === id) : undefined;
    if (newTemplate) {
      requests.push(
        call(upsertBookingTemplate, {
          ...newTemplate,
          potentialHosts: [...(newTemplate.potentialHosts || []), userId],
        } as BookingTemplate)
      );
    }
  });

  yield all(requests);
}

export function* watchBookingTemplatesSaga() {
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST, getBookingTemplatesSaga);
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST, getBookingTemplatesPageSaga);
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST, getBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST, createBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST, cloneBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST, updateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST, activateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST, deleteBookingTemplatesSaga);
  yield takeLatest(
    BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_BY_TENANT_REQUEST,
    getBookingTemplatesByTenantSaga
  );
}

export const bookingTemplatesSagas = {
  getBookingTemplates: getBookingTemplatesSaga,
  updateUserTemplates,
};