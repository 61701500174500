import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { MemberType } from '../../API';
import { CEPMemberSubType, MemberSubType } from '../../types/types';
import { authenticationSelectors } from '../authentication';
import { t } from '../../i18n/i18n';
import { FILTER_OPTION_DELETED_USERS, FILTER_OPTION_TEAMS } from './constants';

const meetingState = (state: State) => state.meeting;

const selectIsFetching = createSelector(meetingState, (state) => state.isFetching);
const selectMembers = createSelector(meetingState, (state) => state.members);

const selectSumo1Members = createSelector(selectMembers, (members) =>
  members.filter((member) => member.type === MemberType.SUMO1)
);
const selectSumo1Users = createSelector(selectSumo1Members, (members) =>
  members.filter((member) => member.subType === MemberSubType.USER)
);
const selectSumo1Teams = createSelector(selectSumo1Members, (members) =>
  members.filter((member) => member.subType === MemberSubType.TEAM)
);
const selectIsMultipleSumo1Users = createSelector(selectSumo1Users, (users) => users.length > 1);

const selectLoggedUsersOptions = createSelector(
  selectSumo1Users,
  authenticationSelectors.selectUserId,
  (usersList, currentUserId) => {
    const options = [];
    for (const user of usersList) {
      if (!user.id) {
        continue;
      }
      if (user.id === currentUserId) {
        options.unshift({ value: user.id, label: user.name || user.email });
      } else {
        options.push({ value: user.id, label: user.name || user.email });
      }
    }
    options.push({ value: FILTER_OPTION_TEAMS, label: t('Teams:TITLE') });
    options.push({ value: FILTER_OPTION_DELETED_USERS, label: t('Users:DELETED_USERS') });
    return options;
  }
);

const selectCEPSalesforceMembers = createSelector(selectMembers, (members) =>
  members.filter((member) => member.type === MemberType.CEP)
);
const selectCEPSalesforceUsers = createSelector(selectCEPSalesforceMembers, (members) =>
  members.filter((member) => member.subType === CEPMemberSubType.USER)
);
const selectCEPSalesforceTeams = createSelector(selectCEPSalesforceMembers, (members) =>
  members.filter((member) => member.subType === CEPMemberSubType.RESOURCE_POOL)
);

const selectUsers = createSelector(selectSumo1Users, selectCEPSalesforceUsers, (sumo1Users, CEPUsers) => [
  ...sumo1Users,
  ...CEPUsers,
]);
const selectTeams = createSelector(selectSumo1Teams, selectCEPSalesforceTeams, (sumo1Teams, CEPTeams) => [
  ...sumo1Teams,
  ...CEPTeams,
]);
const selectUserNamesById = createSelector(selectSumo1Users, selectCEPSalesforceUsers, (sumo1Users, CEPUsers) =>
  [...sumo1Users, ...CEPUsers].reduce((namesById, user) => {
    if (user.id) namesById[user.id] = user.name || user.email || '';
    return namesById;
  }, {} as Record<string, string>)
);
const selectTeamNamesById = createSelector(selectSumo1Teams, selectCEPSalesforceTeams, (sumo1Teams, CEPTeams) =>
  [...sumo1Teams, ...CEPTeams].reduce((namesById, team) => {
    if (team.id) namesById[team.id] = team.name || '';
    return namesById;
  }, {} as Record<string, string>)
);

export const meetingSelectors = {
  selectIsFetching,
  selectMembers,

  selectSumo1Members,
  selectSumo1Users,
  selectSumo1Teams,
  selectIsMultipleSumo1Users,

  selectLoggedUsersOptions,

  selectCEPSalesforceMembers,
  selectCEPSalesforceUsers,
  selectCEPSalesforceTeams,

  selectUsers,
  selectTeams,
  selectUserNamesById,
  selectTeamNamesById,
};