import { MemberDTO } from './types';

export enum MeetingActionTypes {
  GET_MEMBERS_REQUEST = 'meeting/GET_MEMBERS_REQUEST',
  GET_MEMBERS_SUCCESS = 'meeting/GET_MEMBERS_SUCCESS',
  GET_MEMBERS_FAIL = 'meeting/GET_MEMBERS_FAIL',
}

export type MeetingAction =
  | { type: MeetingActionTypes.GET_MEMBERS_REQUEST }
  | { type: MeetingActionTypes.GET_MEMBERS_SUCCESS; payload: MemberDTO[] }
  | { type: MeetingActionTypes.GET_MEMBERS_FAIL; error: unknown };

const getMembersRequest = (): MeetingAction => ({
  type: MeetingActionTypes.GET_MEMBERS_REQUEST,
});
const getMembersSuccess = (payload: MemberDTO[]): MeetingAction => ({
  type: MeetingActionTypes.GET_MEMBERS_SUCCESS,
  payload,
});
const getMembersFail = (error: unknown): MeetingAction => ({
  type: MeetingActionTypes.GET_MEMBERS_FAIL,
  error,
});

export const meetingActions = {
  getMembersRequest,
  getMembersSuccess,
  getMembersFail,
};