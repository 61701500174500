import {
  BackgroundType,
  BookingPageStyleInput,
  MemberInput,
  MemberType,
  NotificationsInput,
  UpdateBookingPageInput,
} from '../../API';
import { BackgroundOption } from './types';
import { MemberSubType } from '../../types/types';
import { t } from '../../i18n/i18n';
import { isTeamMember, isUserMember, MemberDTO, NotificationTypes } from '../meeting';

export const getWhoData = (users: MemberDTO[], teams: MemberDTO[], members: Array<MemberInput | null>): MemberDTO[] => {
  const hostData: MemberDTO[] = [];

  members.forEach((member) => {
    if (!member) return;

    if (member?.type === MemberType.SUMO1 && member.subType === MemberSubType.DELETED_USER) {
      hostData.push({
        id: member.id || member.email || '',
        email: member.email || '',
        hasPhoneNumber: false,
        integrationTypes: [],
        isDeleted: true,
      });
    } else if (isUserMember(member)) {
      const user = users.find((user) => user.id === member.id);
      if (user) hostData.push(user);
    } else if (isTeamMember(member)) {
      const team = teams.find((team) => team.id === member.id);
      if (team) hostData.push(team);
    }
  });

  return hostData;
};

export const isStylesEqual = (
  style1: BookingPageStyleInput | null | undefined,
  style2: BookingPageStyleInput | null | undefined
) => {
  return (
    (style1?.logoImage || '') == (style2?.logoImage || '') &&
    (style1?.backgroundImage || '') == (style2?.backgroundImage || '') &&
    (style1?.backgroundType || '') == (style2?.backgroundType || '') &&
    (style1?.css || '') == (style2?.css || '') &&
    (style1?.footerHtml || '') == (style2?.footerHtml || '') &&
    (style1?.primaryColor || '') == (style2?.primaryColor || '') &&
    (style1?.backgroundColor || '') == (style2?.backgroundColor || '') &&
    (style1?.fontColor || '') == (style2?.fontColor || '') &&
    (style1?.fontFamily || '') == (style2?.fontFamily || '')
  );
};

export const convertBackgroundTypeToOption = (type: BackgroundType | null | undefined) => {
  switch (type) {
    case BackgroundType.BLANK:
      return BackgroundOption.BLANK;
    case BackgroundType.COLOR:
      return BackgroundOption.COLOR;
    case BackgroundType.LEFT:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.RIGHT:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.TOP:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.FULL:
    default:
      return BackgroundOption.WALLPAPER;
  }
};

export const convertBackgroundOptionToType = (option: BackgroundOption) => {
  switch (option) {
    case BackgroundOption.BLANK:
      return BackgroundType.BLANK;
    case BackgroundOption.COLOR:
      return BackgroundType.COLOR;
    case BackgroundOption.WALLPAPER:
    default:
      return BackgroundType.TOP;
  }
};

export const getBackgroundLabelbyType = (type: BackgroundType) => {
  switch (type) {
    case BackgroundType.TOP:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_TOP');
    case BackgroundType.FULL:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_FULL');
    case BackgroundType.LEFT:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_LEFT');
    case BackgroundType.RIGHT:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_RIGHT');
    default:
      return '';
  }
};

export const canDeleteBookingPage = (
  bookingPage: UpdateBookingPageInput,
  userId: string,
  isMyDelete: boolean,
  isAllDelete: boolean,
  isAdmin: boolean
) => {
  const isExisting = !!bookingPage;
  const isMyBookingPage = bookingPage?.who?.hostMembers?.[0]?.id === userId;
  const isAdminLocked = bookingPage?.adminOnly && !isAdmin;
  return isExisting && !isAdminLocked && (isMyBookingPage ? isMyDelete : isAllDelete);
};

export const isMemberSumoUser = (member?: MemberInput | null) =>
  member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.USER;
export const isMemberSumoTeam = (member?: MemberInput | null) =>
  member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.TEAM;

export const getNotificationFieldByNotificationType = (
  type?: NotificationTypes | null
): keyof NotificationsInput | null => {
  if (!type) {
    return null;
  }

  switch (type) {
    case NotificationTypes.CONFIRMATION:
      return 'confirmations';
    case NotificationTypes.RESCHEDULE:
      return 'reschedule';
    case NotificationTypes.REMINDER:
      return 'reminders';
    case NotificationTypes.CANCELATION:
      return 'cancelations';
    case NotificationTypes.FOLLOW_UP:
      return 'followUp';
    case NotificationTypes.SMS:
      return 'smsReminder';
    default:
      return null;
  }
};
