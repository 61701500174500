import { SelectItem } from 'primereact/selectitem';
import { createSelector } from 'reselect';
import { TimeUnit, UpdateBookingPageInput, WorkspaceInput, WorkspaceIntegrationType } from '../../API';
import { isOldData } from '../../services/utils';
import { authenticationSelectors } from '../authentication';
import { globalSelectors } from '../global';
import { State } from '../rootStore';
import { DEFAULT_BOOKING_PAGE_HOW } from './constants';
import { SalesforceCepSettings } from './types';

const workspacesState = (state: State) => state.workspaces;

const selectIsFetching = createSelector(workspacesState, (workspaces) => workspaces.isFetching);
const selectAccordionIndexes = createSelector(workspacesState, (workspaces) => workspaces.accordionIndexes);

const selectLastLoadTime = createSelector(workspacesState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectWorkspaces = createSelector(workspacesState, (workspaces) => workspaces.workspaces);
//const selectTenantStyle = createSelector(workspacesState, (workspaces) => workspaces.tenantStyle);
const selectWorkspace = createSelector(workspacesState, (workspaces) => workspaces.workspace);
//const selectSelectedWorkspaces = createSelector(workspacesState, (workspaces) => workspaces.selectedWorkspaces);
const selectCloneName = createSelector(workspacesState, (workspaces) => workspaces.cloneName);
const selectIsCloneNameDuplicate = createSelector(selectCloneName, selectWorkspaces, (name, workspaces) =>
  workspaces.find((workspace) => workspace.name === name.trim())
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectWorkspaceById = (id: string) =>
  createSelector(selectWorkspaces, (workspaces) => workspaces.find((workspace) => workspace.id === id));
const selectWorkspacesByIds = (id: Array<string | null>) =>
  createSelector(selectWorkspaces, (workspaces) => workspaces.filter((workspace) => id && id.includes(workspace.id)));
const selectFilteredWorkspacesByIds = (id: Array<string | null>) =>
  createSelector(selectWorkspacesByIds(id), globalSelectors.selectLowercasedSearchString, (workspaces, searchString) =>
    workspaces
      .filter((workspace) => workspace.name?.toLowerCase().includes(searchString))
      .sort((a, b) => a.name.localeCompare(b.name))
  );
const selectWorkspacesById = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.reduce((workspacesById, workspace) => {
    if (workspace.id) {
      workspacesById[workspace.id] = workspace;
    }
    return workspacesById;
  }, {} as Record<string, WorkspaceInput>)
);
const selectWorkspacesNamesById = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.reduce((namesById, workspace) => {
    if (workspace.id) {
      namesById[workspace.id] = workspace.name;
    }
    return namesById;
  }, {} as Record<string, string>)
);

const selectName = createSelector(selectWorkspace, (workspace) => workspace.name);
const selectId = createSelector(selectWorkspace, (workspace) => workspace.id);
const selectIsActive = createSelector(selectWorkspace, (workspace) => workspace.isActive);
const selectWorkspaceStyle = createSelector(selectWorkspace, (workspace) => workspace.style || {});
const selectLogo = createSelector(selectWorkspaceStyle, (style) => style?.logoImage);
const selectBackground = createSelector(selectWorkspaceStyle, (style) => style?.backgroundImage);
const selectCss = createSelector(selectWorkspaceStyle, (style) => style?.css);
const selectFooterHtml = createSelector(selectWorkspaceStyle, (style) => style?.footerHtml);

const selectAdminList = createSelector(selectWorkspace, (workspace) => workspace.adminList || []);
const selectUserList = createSelector(selectWorkspace, (workspace) => workspace.userList || []);
const selectIsPhoneRequired = createSelector(selectWorkspace, (workspace) => workspace.isPhoneRequired);
const selectNoCustomerData = createSelector(selectWorkspace, (workspace) => workspace.noCustomerData || false);
const selectLabels = createSelector(selectWorkspace, (workspace) => workspace.labels || DEFAULT_BOOKING_PAGE_HOW);

const selectAdminEmailsList = createSelector(selectAdminList, (adminList) => adminList?.map((record) => record.email));
const selectUserEmailsList = createSelector(selectUserList, (userList) => userList?.map((record) => record.email));

const selectIsExistingWorkspace = createSelector(selectWorkspace, (workspace) => workspace.id !== '');
const selectIsSelectedWorkspaceEmpty = createSelector(selectWorkspace, (workspace) => !Object.keys(workspace).length);

const selectWorkspaceOptions = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.map((workspace) => ({ id: workspace.id, name: workspace.name }))
);
const selectWorkspaceOptionItems = createSelector(selectWorkspaces, (workspaces) =>
  workspaces.map((workspace) => ({ value: workspace.id, label: workspace.name } as SelectItem))
);
const selectWorkspaceOptionsByIds = (id: Array<string | null>) =>
  createSelector(selectWorkspacesByIds(id), (workspaces) => {
    return workspaces.map((workspace) => ({ id: workspace.id, name: workspace.name }));
  });

const selectWorkspaceUserRecords = createSelector(selectAdminList, selectUserList, (admins, users) => [
  ...admins,
  ...users,
]);
const selectUsersWithoutOtherWorkspace = createSelector(selectWorkspaceUserRecords, (users) =>
  users.filter((user) => user.workspaceIds && user.workspaceIds?.length <= 1)
);

const selectCurrentWorkspaceData = createSelector(
  authenticationSelectors.selectWorkspaceId,
  selectWorkspaces,
  (workspaceId, workspaces) => workspaces.find((workspace) => workspace.id === workspaceId)
);
const selectIsWorkspacePhoneRequired = (id: Array<string | null>) =>
  createSelector(selectWorkspacesByIds(id), (workspaces) =>
    workspaces.reduce((isRequired, workspace) => isRequired || workspace.isPhoneRequired, false)
  );
const selectCurrentWorkspaceName = createSelector(selectCurrentWorkspaceData, (workspace) => workspace?.name || '');

const selectIsNameDuplicate = createSelector(
  authenticationSelectors.selectTenantWorkspaces,
  selectId,
  selectName,
  (workspaces, id, name) =>
    Boolean(workspaces.find((workspace) => workspace && workspace.id !== id && workspace.name === name))
);

const selectCurrentCepIntegration = createSelector(selectCurrentWorkspaceData, (workspace) =>
  workspace?.integrations?.find((integration) => integration?.type === WorkspaceIntegrationType.SALESFORCE_CEP)
);

const selectCurrentCepIntegrationSettings = createSelector(selectCurrentCepIntegration, (integration) => 
  integration?.settings 
    ? (integration.settings as unknown as SalesforceCepSettings) 
    : {} as SalesforceCepSettings
);

const selectIsNameValid = createSelector(
  selectName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectIsWhatStepValid = createSelector(selectIsNameValid, (isNameValid) => isNameValid);

const selectBookingPageForPreview = createSelector(
  selectWorkspaceStyle,
  selectLabels,
  (style, labels) =>
    ({
      id: '',
      workspaceId: '',
      labels,
      style,
      when: { duration: { timeUnit: TimeUnit.MINUTE, count: 30 } },
      confirmationsPage: { anotherEventEnabled: true, rescheduleEnabled: true, cancelEnabled: true },
    } as UpdateBookingPageInput)
);

export const workspacesSelectors = {
  selectIsFetching,
  selectAccordionIndexes,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,

  selectWorkspaces,
  selectWorkspace,

  selectCloneName,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,

  selectWorkspaceById,
  selectWorkspacesById,
  selectWorkspacesNamesById,

  selectName,
  selectId,
  selectIsActive,
  selectWorkspaceStyle,
  selectLogo,
  selectBackground,
  selectCss,
  selectFooterHtml,

  selectAdminList,
  selectUserList,
  selectIsPhoneRequired,
  selectNoCustomerData,
  selectAdminEmailsList,
  selectUserEmailsList,
  selectLabels,

  selectIsExistingWorkspace,
  selectIsSelectedWorkspaceEmpty,
  selectIsNameDuplicate,
  selectWorkspaceOptions,
  selectWorkspaceOptionItems,
  selectWorkspacesByIds,
  selectFilteredWorkspacesByIds,
  selectWorkspaceOptionsByIds,

  selectUsersWithoutOtherWorkspace,
  selectIsWorkspacePhoneRequired,
  selectCurrentWorkspaceData,
  selectCurrentWorkspaceName,
  selectCurrentCepIntegration,
  selectCurrentCepIntegrationSettings,

  selectIsWhatStepValid,
  selectBookingPageForPreview,
};
