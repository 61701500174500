import { createSelector } from 'reselect';
import { checkTimeWrongOrder, checkTimeOverlapping } from '../../services/DateService';
import { State } from '../rootStore';
import { AvailabilityOption, AvailabilityOptions } from './types';
import { DEFAULT_AVAILABILITY_MODEL } from './constants';
import { globalSelectors } from '../global';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';
import { bookingPageSelectors } from '../bookingPages';
import { locationsSelectors } from '../locations';
import { bookingTemplatesSelectors } from '../bookingTemplates';

const selectAvailabilityState = (state: State) => state.availability;

// TODO: start hadling availability.error
const selectIsFetching = createSelector(selectAvailabilityState, (availabilityState) => availabilityState.isFetching);

const selectAvailabilities = createSelector(
  selectAvailabilityState,
  (availabilityState): AvailabilityModel[] => availabilityState.availabilities
);

const selectUserAvailabilitiesNames = createSelector(selectAvailabilities, (availabilities) =>
  availabilities.map((availability) => availability?.name)
);

const selectDefaultAvailabilityId = createSelector(
  selectAvailabilityState,
  (availabilityState) => availabilityState.defaultAvailability
);

const selectUserAvailabilitiesOptions = createSelector(
  selectAvailabilities,
  globalSelectors.selectLowercasedSearchString,
  selectDefaultAvailabilityId,
  (availabilities, searchString, defaultAvailabilityId) =>
    availabilities.length
      ? [...availabilities] // Create a shallow copy of the array
          .filter((availability) => availability?.name?.toLowerCase().includes(searchString))
          .sort((a, b) => {
            // Sorting that isDefault item should be the first
            if (defaultAvailabilityId && a.id === defaultAvailabilityId) {
              return -1;
            }
            if (defaultAvailabilityId && b.id === defaultAvailabilityId) {
              return 1;
            }
            const aCreatedAt = a.createdAt ? a.createdAt : '';
            const bCreatedAt = b.createdAt ? b.createdAt : '';
            return aCreatedAt <= bCreatedAt ? -1 : 1;
          })
          .map(
            ({ id, name }) =>
              ({
                label: name,
                value: id,
                isDefault: id === defaultAvailabilityId,
              } as AvailabilityOption)
          )
      : ([] as AvailabilityOptions)
);

const selectAvailability = createSelector(
  selectAvailabilityState,
  (availabilityState): AvailabilityModel => availabilityState.availability
);

// selectedAvailability before changes
const selectInitAvailability = createSelector(
  selectAvailability,
  selectAvailabilities,
  (availability, availabilities) => {
    if (availability !== DEFAULT_AVAILABILITY_MODEL) {
      return availabilities.find((item) => availability.id === item.id) || availability;
    } else {
      return DEFAULT_AVAILABILITY_MODEL;
    }
  }
);

const selectAvailabilityId = createSelector(selectAvailability, (availability) => availability.id);

const selectBookingPages = createSelector(selectAvailability, (availabilityData) => availabilityData?.bookingPages);
const selectBookingTemplates = createSelector(
  selectAvailability,
  (availabilityData) => availabilityData?.bookingTemplates
);
const selectLocations = createSelector(selectAvailability, (availabilityData) => availabilityData?.locations);

const selectIsPresentFilters = createSelector(
  selectBookingPages,
  selectBookingTemplates,
  selectLocations,
  (bookingPages, bookingTemplates, locations) =>
    Boolean(bookingPages?.length || bookingTemplates?.length || locations?.length)
);

const selectIsDefault = createSelector(
  selectAvailability,
  selectDefaultAvailabilityId,
  (availability, defaultAvailabilityId) => availability.id === defaultAvailabilityId
);
const selectName = createSelector(selectAvailability, (availabilityData) => availabilityData?.name);
const selectWeeklyHours = createSelector(selectAvailability, (availabilityData) => availabilityData?.weeklyHours || []);
const selectOverrides = createSelector(selectAvailability, (availabilityData) => availabilityData?.overrides || []);
const selectSortedOverrides = createSelector(selectOverrides, (overrides) =>
  overrides
    .filter((date) => {
      // '2024-06-20T14:37:22.986Z' -> '2024-06-20T00:00:00.000Z'
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      return date?.days?.to && date?.days?.to >= todayDate.getTime();
    })
    .sort((a, b) => {
      if (!a?.days?.from || !b?.days?.from) {
        return 0;
      }
      return a.days.from > b.days.from ? 1 : -1;
    })
);
const selectSortedAvailableOverrides = createSelector(selectSortedOverrides, (overrides) =>
  overrides.filter(
    (override) => override && override.time && override.time[0] && override.time[0].from !== override.time[0].to
  )
);
const selectSortedUnavailableOverrides = createSelector(selectSortedOverrides, (overrides) =>
  overrides.filter(
    (override) =>
      override && override.time?.length === 1 && override.time[0] && override.time[0].from === override.time[0].to
  )
);

const selectCloneName = createSelector(selectAvailabilityState, (availability) => availability.cloneName);

const selectIsNameDuplicate = createSelector(
  selectAvailabilities,
  selectAvailability,
  selectCloneName,
  (availabilities, availability, name) =>
    Boolean(availabilities?.find((item) => item?.id !== availability?.id && item?.name === name))
);
const selectIsNameValid = createSelector(
  selectName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsCloneNameDuplicate = createSelector(selectUserAvailabilitiesNames, selectCloneName, (names, cloneName) =>
  Boolean(names?.find((name) => name === cloneName))
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsWeeklyHoursValid = createSelector(selectWeeklyHours, (weeklyHours) =>
  weeklyHours.every((day) => !day?.time || (!checkTimeOverlapping(day?.time) && !checkTimeWrongOrder(day?.time)))
);

const selectDefaultAvailability = createSelector(
  selectAvailabilities,
  selectDefaultAvailabilityId,
  (availabilities, defaultAvailabilityId) => availabilities.find(({ id }) => id === defaultAvailabilityId)
);
const selectDefaultAvailabilityWeeklyHours = createSelector(
  selectDefaultAvailability,
  (availability) => availability?.weeklyHours
);

const selectAvailabilityById = (id?: string) =>
  createSelector(selectAvailabilities, (availabilities) =>
    availabilities.find((availability) => availability.id === id)
  );

const selectOldestAvailability = (id?: string) =>
  createSelector(selectAvailabilities, (availabilities) => {
    const sortedAvailabilities = (
      id ? [...availabilities].filter((availability) => availability.id !== id) : [...availabilities]
    )
      .filter(
        (availability) =>
          !(
            availability?.bookingPages?.length ||
            availability?.bookingTemplates?.length ||
            availability?.locations?.length
          )
      )
      .sort((a, b) => {
        const aCreatedAt = a.createdAt ? a.createdAt : '';
        const bCreatedAt = b.createdAt ? b.createdAt : '';
        return aCreatedAt <= bCreatedAt ? -1 : 1;
      });

    return sortedAvailabilities[0];
  });

const selectIsAvailabilityLocked = createSelector(
  selectAvailability,
  bookingPageSelectors.selectBookingPageIds,
  bookingTemplatesSelectors.selectBookingTemplateIds,
  locationsSelectors.selectLocationIds,
  (availability, bookingPageIds, bookingTemplateIds, locationIds) =>
    !!availability.bookingPages?.some((id) => !bookingPageIds.includes(id)) ||
    !!availability.bookingTemplates?.some((id) => !bookingTemplateIds.includes(id)) ||
    !!availability.locations?.some((id) => !locationIds.includes(id))
);

const selectIsAvailabilityLoaded = createSelector(
  selectAvailability,
  (availability) => availability !== DEFAULT_AVAILABILITY_MODEL
);

export const availabilitySelectors = {
  selectIsFetching,
  selectAvailabilities,
  selectUserAvailabilitiesNames,
  selectUserAvailabilitiesOptions,
  selectDefaultAvailability,
  selectAvailability,
  selectInitAvailability,
  selectIsDefault,
  selectName,
  selectBookingPages,
  selectBookingTemplates,
  selectLocations,
  selectIsPresentFilters,
  selectSortedOverrides,
  selectSortedAvailableOverrides,
  selectSortedUnavailableOverrides,
  selectWeeklyHours,
  selectCloneName,
  selectIsNameDuplicate,
  selectIsNameValid,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsWeeklyHoursValid,
  selectDefaultAvailabilityWeeklyHours,
  selectAvailabilityById,
  selectOldestAvailability,
  selectDefaultAvailabilityId,
  selectAvailabilityId,
  selectIsAvailabilityLocked,
  selectIsAvailabilityLoaded,
};
