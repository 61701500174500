import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';

export type IntegrationDataType = {
  type: IntegrationType | WorkspaceIntegrationType | AppType;
  label: string;
  icon: string;
  description: string;
};

export enum IntegrationFilter {
  ALL = 'ALL',
  CALENDARS = 'CALENDARS',
  EXTENSIONS = 'EXTENSIONS',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
  WORKSPACE_INTEGRATIONS = 'WORKSPACE_INTEGRATIONS',
}

export type GetIntegrationURLParamsType = {
  code: string | null;
  type: IntegrationType | WorkspaceIntegrationType | null;
  workspaceId: string | null;
  customParameters?: any | null;
  error?: string | null;
  errorDescription?: string | null;
};

export type HubSpotCustomParameters = {
  bookContacts: string;
  bookLeads: string;
  createEvents: string;
};

export type GoogleAnalyticsCustomParameters = {
  trackingId: string;
  measurementId: string;
};

export enum SFOrgType {
  PRODUCTION = 'PRODUCTION',
  SANDBOX = 'SANDBOX',
}

export type SalesforceCustomParameters = {
  orgType: SFOrgType;
  contactsPersonAccounts: string;
  createEvents: string;
  usersAndResourcePools: string;
  orgId: string;
};

export type GoogleMapsCustomParameters = {
  apiKey: string;
};

export type ConnectFormDataType = { [uniqueName: string]: string };

export type CodeRedirectPayload = {
  integrationType: IntegrationType | WorkspaceIntegrationType | AppType;
  uri: string;
  workspaceId?: string;
  customParameters?: HubSpotCustomParameters | SalesforceCustomParameters;
};
