import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { DefaultVideoConference, QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupNavigationActions } from '../../../store/quickSetup';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { IntegrationType, VideoConferenceType } from '../../../API';
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import { ReactComponent as GoogleMeetIcon } from '../../../assets/icons/32-googleMeet.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/icons/21-teams.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/icons/32-zoom.svg';
import { integrationsActions } from '../../../store/integration';

export const SetupVideoConferenceStep = () => {
  const isZoomConnected = useSelector(userSettingsSelectors.selectIsZoomConnected);
  const isThirdPartyGoogle = useSelector(authenticationSelectors.selectIsThirdPartyGoogle);
  const isThirdPartyMicrosoft = useSelector(authenticationSelectors.selectIsThirdPartyMicrosoft);

  const dispatch = useDispatch<AppDispatch>();

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };
  const handleNext = () => {
    dispatch(quickSetupNavigationActions.navigateNext());
  };

  const handleGetZoomCodeRedirect = () => {
    dispatch(integrationsActions.getCodeRedirect({ integrationType: IntegrationType.ZOOM, uri: window.location.href }));
  };

  return (
    <QuickSetupScreen.Container title={labels.stepMessage} titleIcon="👋">
      <div className="flex flex-column">
        <div className="text-display-md-med">{labels.title}</div>
        <div className="text-body-lg-reg text-heavy-80 pt-2px pb-32px w-580px">{labels.description}</div>

        {isThirdPartyGoogle && (
          <div className="flex align-items-center gap-20px">
            <div className="flex align-items-center auth-button w-360px">
              <GoogleMeetIcon />
              <div className="ml-20px">{labels.googleMeetTitle}</div>
              <CheckIcon className="ml-auto text-cyan-main icon-18px" />
              <div className="ml-4px text-label-s-med text-cyan-main">{labels.connected}</div>
            </div>
            <DefaultVideoConference videoConferenceType={VideoConferenceType.GOOGLE_MEET} />
          </div>
        )}

        {isThirdPartyMicrosoft && (
          <div className="flex align-items-center gap-20px">
            <div className="flex align-items-center auth-button w-360px">
              <TeamsIcon className="w-32px h-32px" />
              <div className="ml-20px">{labels.microsoftTeamsTitle}</div>
              <CheckIcon className="ml-auto text-cyan-main icon-18px" />
              <div className="ml-4px text-label-s-med text-cyan-main">{labels.connected}</div>
            </div>
            <DefaultVideoConference videoConferenceType={VideoConferenceType.MICROSOFT_TEAMS} />
          </div>
        )}

        <div className="flex align-items-center gap-20px">
          <div className="flex align-items-center auth-button w-360px mt-8px" onClick={handleGetZoomCodeRedirect}>
            <ZoomIcon />
            <div className="ml-20px">{labels.zoomTitle}</div>
            {isZoomConnected ? (
              <div className="ml-auto flex gap-4px text-cyan-main text-label-s-med">
                <CheckIcon className="icon-18px" />
                <div>{labels.connected}</div>
              </div>
            ) : (
              <div className="ml-auto text-heavy-60 bg-heavy-1 text-label-s-med border-radius-8px p-10px h-30px">
                {labels.connect}
              </div>
            )}
          </div>
          {isZoomConnected && <DefaultVideoConference videoConferenceType={VideoConferenceType.ZOOM} />}
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <Button
          label={labels.back}
          className="min-w-120px flex-center button-xl"
          onClick={handleBack}
          outlined
        />
        <Button className="min-w-120px flex-center gap-8px button-xl" onClick={handleNext}>
          <div>{labels.next}</div>
          <ArrowRightIcon className="icon-18px" />
        </Button>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
