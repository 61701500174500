import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { ConnectIntegrationResponse } from '../../generated-sources/internal-api/models/ConnectIntegrationResponse';
import { DisconnectIntegrationRequest } from '../../generated-sources/internal-api/models/DisconnectIntegrationRequest';
import { DisconnectIntegrationResponse } from '../../generated-sources/internal-api/models/DisconnectIntegrationResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const connectIntegration = async (data: ConnectIntegrationRequest): Promise<ConnectIntegrationResponse> => {
  return handleAPIRequest<ConnectIntegrationResponse>(`${API_PUBLIC}/connectIntegration`, data);
};

export const disconnectIntegration = async (data: DisconnectIntegrationRequest): Promise<DisconnectIntegrationResponse> => {
  return handleAPIRequest<DisconnectIntegrationResponse>(`${API_PUBLIC}/disconnectIntegration`, data);
};
