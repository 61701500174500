import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { authenticationSelectors } from '../authentication';
import { rolesSelectors } from '../roles';
import { UserAdminDataFullRecord, UserOption, UsersGroupedByWorkspaceOption } from './types';
import { workspacesSelectors } from '../workspaces';
import { isOldData, validatePhoneNumber } from '../../services/utils';
import { t } from '../../i18n/i18n';
import { DEFAULT_FILTER, FILTER_USER_OPTION_DELETED_USERS } from './constants';
import { globalSelectors } from '../global';
import { SelectItem } from 'primereact/selectitem';
import { userSettingsSelectors } from '../userSettings';
import { AdminDataStatus } from '../../API';
import { locationsSelectors } from '../locations';

const usersState = (state: State) => state.users;

const selectIsFetching = createSelector(usersState, (state) => state.isFetching);
const selectIsInviteUsersFetching = createSelector(usersState, (state) => state.isInviteUsersFetching);
const selectError = createSelector(usersState, (state) => state.error);
const selectUsers = createSelector(usersState, (state) => state.users);
const selectUserIds = createSelector(usersState, (state) => state.users.map((user) => user.userId));
const selectSelectedUser = createSelector(usersState, (state) => state.selectedUser);
const selectUserResources = createSelector(usersState, (state) => state.userResources);
const selectActiveSuperAdminRecords = createSelector(usersState, (state) => state.activeSuperAdmins);
const selectFilter = createSelector(usersState, (state) => state.filter);
const selectSelectedUsers = createSelector(usersState, (state) => state.selectedUsers);
const selectLastLoadTime = createSelector(usersState, (state) => state.lastLoadTime);

const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectActiveSuperAdminEmails = createSelector(selectActiveSuperAdminRecords, (state) =>
  state.map((admin) => admin.email)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
const selectFilterWorkspaces = createSelector(selectFilter, (filter) => filter.workspaceIds);
const selectFilterRoles = createSelector(selectFilter, (filter) => filter.roles);
const selectFilterStatuses = createSelector(selectFilter, (filter) => filter.statuses);
const selectIsFilterInUse = createSelector(
  selectFilter,
  userSettingsSelectors.selectIsSuperAdmin,
  workspacesSelectors.selectWorkspaceOptionItems,
  authenticationSelectors.selectWorkspaceId,
  rolesSelectors.selectRoleOptions,
  (filter, isSuperAdmin, workspaceOptions, workspaceId, roleOptions) =>
    (isSuperAdmin
      ? filter.workspaceIds.length !== workspaceOptions.length
      : filter.workspaceIds.length !== 1 || filter.workspaceIds[0] !== workspaceId) ||
    filter.roles.length < roleOptions.length ||
    filter.statuses.length < DEFAULT_FILTER.statuses.length
);

const selectTeamUserOptions = createSelector(selectUsers, (users) =>
  // TODO: uncomment when team.createdBy is id
  // users.map((user) => ({ value: user.userId, label: user.fullName } as SelectItem))
  users.map((user) => ({ value: user.fullName, label: user.fullName } as SelectItem))
);
const selectUserOptions = createSelector(selectUsers, (users) =>
  users.map((user) => ({ value: user.userId, label: user.fullName } as SelectItem))
);
const selectFilteredUsers = createSelector(
  selectUsers,
  selectFilter,
  globalSelectors.selectLowercasedSearchString,
  (users, filter, searchString) =>
    users.filter(
      (user) =>
        Boolean(filter.workspaceIds?.find((id) => user.workspaceIds?.includes(id))) &&
        filter.statuses?.includes(user.status || '') &&
        filter.roles?.includes(user.roleId || '') &&
        Boolean(
          !searchString ||
            user.fullName?.toLowerCase().includes(searchString) ||
            user.email.toLowerCase().includes(searchString)
        )
    )
);

const selectUsersEmails = createSelector(selectUsers, (users) => users.map((user) => user.email.toLowerCase()));

const selectSuperAdmins = createSelector(selectUsers, rolesSelectors.selectSuperAdminIds, (users, superAdminRoleIds) =>
  users.filter((user) => superAdminRoleIds.includes(user.roleId))
);

const selectActiveSuperAdmins = createSelector(selectSuperAdmins, (superAdmins) =>
  superAdmins.filter((superAdmin) => superAdmin.status === AdminDataStatus.ACTIVE)
);

const selectAdminUsers = createSelector(selectUsers, rolesSelectors.selectAdminsRolesId, (users, rolesId) =>
  users.filter((user) => rolesId.includes(user.roleId))
);
const selectStandardUsers = createSelector(selectUsers, rolesSelectors.selectAdminsRolesId, (users, rolesId) =>
  users.filter((user) => !rolesId.includes(user.roleId))
);

const selectCurrentUser = createSelector(
  selectUsers,
  authenticationSelectors.selectUserId,
  (users, currentUserId) => users.filter((user) => (currentUserId ? user.userId?.includes(currentUserId) : false))[0]
);

const selectCurrentUserNameOrEmail = createSelector(selectCurrentUser, (user) => user?.fullName || user?.email || '');

const selectUserWorkspacesIds = createSelector(selectCurrentUser, (user) => user?.workspaceIds || []);

const selectUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectUsers, (userList) => userList.filter((record) => record.workspaceIds?.includes(workspaceId)));

const selectUsersInCurrentWorkspace = createSelector(
  selectUsers,
  authenticationSelectors.selectWorkspaceId,
  (userList, workspaceId) => userList.filter((record) => record.workspaceIds?.includes(workspaceId))
);
const selectLoggedUsersInCurrentWorkspace = createSelector(selectUsersInCurrentWorkspace, (userList) =>
  userList.filter((record) => !!record.userId)
);
const selectLoggedUsersInCurrentWorkspaceOptions = createSelector(selectLoggedUsersInCurrentWorkspace, (usersList) => {
  const options: UserOption[] = [];
  for (const user of usersList) {
    if (user.userId) {
      options.push({
        value: user.userId,
        label: user.fullName || user.email,
        workspaceIds: user.workspaceIds || [],
      });
    }
  }
  return options;
});
const selectLoggedUsersInCurrentWorkspaceAndDeletedUsersOptions = createSelector(
  selectLoggedUsersInCurrentWorkspaceOptions,
  (users) => [
    ...users,
    {
      value: FILTER_USER_OPTION_DELETED_USERS,
      label: t('Users:DELETED_USERS'),
      workspaceIds: [],
    },
  ]
);

const selectHasMultipleUsersInOrganization = createSelector(selectUsers, (users) => users.length > 1);

const selectHasMultipleLoggedUsersInWorkspace = createSelector(
  selectLoggedUsersInCurrentWorkspace,
  (usersList) => usersList.length > 1
);

const selectLoggedUsers = createSelector(selectUsers, (userList) => userList.filter((record) => !!record.userId));

const selectLoggedUserListOptions = createSelector(selectLoggedUsers, (usersList) => {
  const options: UserOption[] = [];
  for (const user of usersList) {
    if (user.userId) {
      options.push({
        value: user.userId,
        label: user.fullName || user.email,
        workspaceIds: user.workspaceIds || [],
      });
    }
  }
  return options;
});

const selectLoggedUserListOptionsGroupByWorkspace = createSelector(
  selectLoggedUsers,
  authenticationSelectors.selectTenantWorkspacesOptions,
  (usersList, workspaces) => {
    const options: UsersGroupedByWorkspaceOption[] = [];
    workspaces.forEach((workspace) =>
      options.push({ workspaceId: workspace.id, workspaceName: workspace.name, users: [] })
    );
    for (const user of usersList) {
      if (user.userId) {
        options.forEach(
          (option) =>
            user.workspaceIds?.includes(option.workspaceId) &&
            option.users.push({ value: `${option.workspaceId}_${user.userId}`, label: user.fullName || user.email })
        );
      }
    }
    return options;
  }
);

const selectAdminUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectAdminUsers, (adminList) =>
    adminList.filter((record) => record.workspaceIds?.includes(workspaceId))
  );

const selectStandardUsersByWorkspace = (workspaceId: string) =>
  createSelector(selectStandardUsers, (standardUserList) =>
    standardUserList.filter((record) => record.workspaceIds?.includes(workspaceId))
  );

const selectUsersById = createSelector(selectUsers, (users) =>
  users.reduce((usersById, user) => {
    if (user.userId) {
      usersById[user.userId] = user;
    }
    return usersById;
  }, {} as Record<string, UserAdminDataFullRecord>)
);

const selectUserNamesById = createSelector(selectUsers, (users) =>
  users.reduce((namesById, user) => {
    if (user.userId) namesById[user.userId] = user.fullName || user.email;
    return namesById;
  }, {} as Record<string, string>)
);

const selectUserInfoById = createSelector(selectUsers, (users) =>
  users.reduce((namesById, user) => {
    if (user.userId) {
      namesById[user.userId] = { email: user.email, fullName: user.fullName || '' };
    }
    return namesById;
  }, {} as Record<string, { email: string; fullName: string }>)
);

const selectUserNameById = (userId: string) =>
  createSelector(selectUsers, (users) => {
    const user = users.find((user) => user.userId === userId);
    return user ? user.fullName || user.email : '';
  });

const selectUserById = (userId: string) =>
  createSelector(selectUsers, (users) => users.find((user) => user.userId === userId));

const selectUserNamesByIds = (userIds: (string | null)[] | null | undefined) =>
  createSelector(selectUsers, (users) =>
    users.reduce((result, user) => {
      if (user && userIds?.includes(user.userId || '')) {
        result.push(user.fullName || user.email);
      }
      return result;
    }, [] as string[])
  );

const selectSelectedUserWorkspaces = createSelector(selectSelectedUser, (user) => user.workspaceIds || []);
const selectSelectedUserPhone = createSelector(selectSelectedUser, (user) => user.phoneNumber);
const selectIsUserPhoneInvalid = createSelector(
  selectSelectedUserPhone,
  selectSelectedUserWorkspaces,
  (state: State) => state,
  (phoneNumber, workspaceIds, state) =>
    workspacesSelectors.selectIsWorkspacePhoneRequired(workspaceIds)(state) && !validatePhoneNumber(phoneNumber)
);

const selectLocationDependencesUsers = createSelector(
  locationsSelectors.selectDeleteDependences,
  selectUsers,
  (dependences, users) => {
    const uniqueBookingUsers = new Set();

    return dependences.users?.reduce((result, userId) => {
      const user = users.find((user) => user.userId === userId);
      if (user && !uniqueBookingUsers.has(user.userId)) {
        uniqueBookingUsers.add(user.userId);
        result.push(user);
      }
      return result;
    }, [] as typeof users);
  }
);

const selectIsUserInvited = createSelector(selectSelectedUser, (user) => user.status === AdminDataStatus.INVITE_SENT);

const selectAssignedTeams = createSelector(
  selectSelectedUser,
  selectUserResources,
  (user, resources) =>
    user.status === AdminDataStatus.INVITE_SENT
      ? user.team
      : resources.team
);

const selectAssignedTemplates = createSelector(
  selectSelectedUser,
  selectUserResources,
  (user, resources) =>
    user.status === AdminDataStatus.INVITE_SENT
      ? user.bookingTemplateIds
      : resources.bookingTemplateIds
);

export const usersSelectors = {
  selectIsFetching,
  selectIsInviteUsersFetching,
  selectError,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectUsers,
  selectUserIds,
  selectSelectedUser,
  selectUserResources,
  selectSuperAdmins,
  selectActiveSuperAdmins,
  selectFilter,
  selectSelectedUsers,
  selectIsDefaultFilter,
  selectFilterWorkspaces,
  selectFilterRoles,
  selectFilterStatuses,
  selectIsFilterInUse,
  selectUsersEmails,
  selectTeamUserOptions,
  selectUserOptions,
  selectFilteredUsers,
  selectAdminUsers,
  selectStandardUsers,
  selectUsersByWorkspace,
  selectUsersInCurrentWorkspace,
  selectLoggedUsersInCurrentWorkspace,
  selectLoggedUsersInCurrentWorkspaceOptions,
  selectLoggedUsersInCurrentWorkspaceAndDeletedUsersOptions,
  selectHasMultipleUsersInOrganization,
  selectHasMultipleLoggedUsersInWorkspace,
  selectLoggedUsers,
  selectLoggedUserListOptions,
  selectLoggedUserListOptionsGroupByWorkspace,
  selectAdminUsersByWorkspace,
  selectStandardUsersByWorkspace,
  selectUserNameById,
  selectUserNamesByIds,
  selectUsersById,
  selectUserNamesById,
  selectUserInfoById,
  selectIsUserPhoneInvalid,
  selectUserWorkspacesIds,
  selectCurrentUser,
  selectCurrentUserNameOrEmail,
  selectActiveSuperAdminRecords,
  selectUserById,
  selectActiveSuperAdminEmails,

  selectLocationDependencesUsers,

  selectIsUserInvited,
  selectAssignedTeams,
  selectAssignedTemplates
};
