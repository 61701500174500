import { createSelector } from 'reselect';
import {
  ConfirmationsPageType,
  CustomFieldType,
  DateRangeType,
  InPersonType,
  IntegrationType,
  LocationType,
  MemberType,
  PhoneCallType,
  TimeZoneType,
  UpdateBookingPageInput,
  UpdateGroupBookingPageInput,
} from '../../API';
import { t } from '../../i18n/i18n';
import { calculateMinutes, isOldData, validatePhoneNumber } from '../../services/utils';
import { TIME_ZONE_LIST } from '../../types/constants';
import { MemberSubType } from '../../types/types';
import { authenticationSelectors } from '../authentication';
import { globalSelectors } from '../global';
import { locationsSelectors } from '../locations';
import { validateCustomFieldPrepopulate } from '../publicBookingPage/utils';
import { State } from '../rootStore';
import { userSettingsSelectors } from '../userSettings';
import {
  FILTER_OPTION_DELETED_USERS,
  FILTER_OPTION_TEAMS,
  FILTER_OPTION_UNASSIGNED,
  SUB_TYPE_DELETED_USER,
} from './constants';
import { BookingPageOption, BookingPagesByIdType } from './types';
import { canDeleteBookingPage, getWhoData, isMemberSumoTeam, isMemberSumoUser } from './utils';
import { bookingTemplatesSelectors } from '../bookingTemplates/selectors';
import { AccordionIndexValues, assignTime, isTeamMember, meetingSelectors, MemberDTO } from '../meeting';
import { VIDEO_CONFERENCES } from '../integration';

const bookingPagesState = (state: State) => state.bookingPages;

const selectIsFetching = createSelector(bookingPagesState, (bookingPages) => bookingPages.isFetching);

const selectLastLoadTime = createSelector(bookingPagesState, (state) => state.lastLoadTime);

const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectBookingPages = createSelector(bookingPagesState, (bookingPages) => bookingPages.bookingPages);
const selectSelectedBookingPages = createSelector(
  bookingPagesState,
  (bookingPages) => bookingPages.selectedBookingPages
);

const selectIsEdited = createSelector(bookingPagesState, (bookingPages) => bookingPages.isEdited);
const selectFilterByUser = createSelector(
  bookingPagesState,
  authenticationSelectors.selectUserId,
  (bookingPages, currentUserId) => bookingPages.filterByUser || currentUserId
);
const selectRecordWho = createSelector(bookingPagesState, (bookingPages) => bookingPages.recordWho);
const selectRecordWhoTeamIds = createSelector(selectRecordWho, (who) =>
  [...(who?.hostMembers || []), ...(who?.guests || []), who?.mainGuest]
    .filter((member) => member && isTeamMember(member))
    .map((member) => member?.id)
);

const selectAddToWebsiteLink = createSelector(bookingPagesState, (bookingPages) => bookingPages.addToWebSiteLink);

const selectBookingPageIds = createSelector(selectBookingPages, (bookingPages) =>
  bookingPages.map((bookingPage) => bookingPage.id)
);

const selectMyDefaultBookingPage = createSelector(
  selectBookingPages,
  authenticationSelectors.selectUserId,
  (bookingPages, currentUserId) =>
    bookingPages.find((page) => page.who?.hostMembers && page.who?.hostMembers[0]?.id === currentUserId) || null
);

const selectBookingPagesLength = createSelector(selectBookingPages, (bookingPages) => bookingPages.length);
const selectIsUnassignedPages = createSelector(selectBookingPages, (bookingPages) =>
  bookingPages.some((page) => !page.who?.hostMembers?.length || !page.who?.hostMembers[0]?.id)
);

const selectFilteredBookingPages = createSelector(
  selectBookingPages,
  selectFilterByUser,
  meetingSelectors.selectSumo1Teams,
  (bookingPages, filterByUser, teams) => {
    switch (filterByUser) {
      case FILTER_OPTION_UNASSIGNED:
        return bookingPages?.filter((bookingPage) => !bookingPage.who?.hostMembers?.[0]?.id);
      case FILTER_OPTION_TEAMS:
        return bookingPages?.filter((bookingPage) => {
          const hostMember = bookingPage.who?.hostMembers?.[0];
          const byHost =
            hostMember &&
            hostMember.type === MemberType.SUMO1 &&
            hostMember.subType === MemberSubType.TEAM &&
            hostMember.id;

          const byGuest =
            bookingPage.who?.guests && bookingPage.who.guests.some((cohost) => cohost?.id && isTeamMember(cohost));

          return byHost || byGuest;
        });
      case FILTER_OPTION_DELETED_USERS:
        return bookingPages.filter((bp) =>
          [...(bp.who?.hostMembers || []), ...(bp.who?.guests || [])].some(
            (member) => member?.subType === SUB_TYPE_DELETED_USER
          )
        );
      default: {
        const userTeamIds = teams
          .filter(
            ({ active, teamMember }) => active && teamMember?.some((member) => member && member.userId === filterByUser)
          )
          .map(({ id }) => id);

        return bookingPages?.filter((bookingPage) => {
          const hostTeamIds =
            bookingPage.who?.hostMembers?.filter((member) => isMemberSumoTeam(member)).map((record) => record?.id) ||
            [];

          const coHostTeamIds =
            bookingPage.who?.guests?.filter((member) => isMemberSumoTeam(member)).map((record) => record?.id) || [];

          const hostUserIds =
            bookingPage.who?.hostMembers?.filter((member) => isMemberSumoUser(member)).map((record) => record?.id) ||
            [];

          const coHostUserIds =
            bookingPage.who?.guests?.filter((member) => isMemberSumoUser(member)).map((record) => record?.id) || [];

          return (
            hostTeamIds.some((teamId) => teamId && userTeamIds.includes(teamId)) ||
            coHostTeamIds.some((teamId) => teamId && userTeamIds.includes(teamId)) ||
            hostUserIds.includes(filterByUser) ||
            coHostUserIds.includes(filterByUser)
          );
        });
      }
    }
  }
);

const selectSearchedBookingPages = createSelector(
  selectFilteredBookingPages,
  globalSelectors.selectLowercasedSearchString,
  (bookingPages, searchString) =>
    bookingPages
      .filter((bookingPage) => {
        const name = bookingPage.what?.customName || bookingPage.displayId || '';
        return name.toLowerCase().includes(searchString);
      })
      .sort((a, b) => {
        const nameA = a.what?.customName || a.displayId || '';
        const nameB = b.what?.customName || b.displayId || '';

        return nameA.localeCompare(nameB);
      })
);

const selectPersonalSearchedBookingPages = createSelector(
  selectSearchedBookingPages,
  (searchedBookingPages) =>
    searchedBookingPages.filter(
      (record) =>
        [...(record.who?.hostMembers || []), ...(record.who?.guests || [])].filter(
          (member) => member && member.subType !== SUB_TYPE_DELETED_USER
        ).length <= 1
    ) // TODO: i don't really like this filtering of deleted user, better to delete it from bp instead of updating subType, and revert back this selector
);
const selectSharedSearchedBookingPages = createSelector(selectSearchedBookingPages, (searchedBookingPages) =>
  searchedBookingPages.filter(
    (record) =>
      [...(record.who?.hostMembers || []), ...(record.who?.guests || [])].filter(
        (member) => member && member.subType !== SUB_TYPE_DELETED_USER
      ).length > 1 // TODO: i don't really like this filtering of deleted user, better to delete it from bp instead of updating subType, and revert back this selector
  )
);

const selectBookingPagesById = createSelector(selectBookingPages, (pages) =>
  pages.reduce((res, page) => {
    res[page.id] = page;
    return res;
  }, {} as BookingPagesByIdType)
);

const selectBookingPage = createSelector(bookingPagesState, (bookingPages) => bookingPages.bookingPage);
const selectAccordionIndexes = createSelector(bookingPagesState, (bookingPages) => bookingPages.accordionIndexes);
const selectIsBookingPageSelector = () => true;

const selectBookingPageById = (id: string) =>
  createSelector(selectBookingPages, (bookingPages) => bookingPages.find((bookingPage) => bookingPage.id === id));
const selectBookingPagesOptions = createSelector(selectBookingPages, (bookingPages) =>
  bookingPages
    .map(
      (page) => ({ label: page.what?.customName || '', value: page.id, displayId: page.displayId } as BookingPageOption)
    )
    .sort((a, b) => a.label.localeCompare(b.label))
);

const selectBookingPageId = createSelector(selectBookingPage, (bookingPage) => bookingPage.id);
const selectDisplayId = createSelector(selectBookingPage, (bookingPage) => bookingPage.displayId);
const selectIsAdminOnly = createSelector(selectBookingPage, (bookingPage) => bookingPage.adminOnly);

const selectWhat = createSelector(selectBookingPage, (bookingPage) => bookingPage.what);
const selectWhatColor = createSelector(selectWhat, (what) => what?.color);
const selectWhatName = createSelector(selectWhat, (what) => what?.customName);
const selectIsActive = createSelector(selectWhat, (what) => what?.isActive);
const selectInstructions = createSelector(selectWhat, (what) => what?.instructions);
const selectLastModify = createSelector(selectBookingPage, (bookingPage) => bookingPage.lastModify);
const selectBookingTemplateId = createSelector(selectWhat, (what) => what?.bookingTemplateId);
const selectBookingTemplateName = createSelector(
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplateNamesById,
  (templateId, bookingTemplateNamesById) => (templateId && bookingTemplateNamesById[templateId]) || ''
);
const selectHasTemplate = createSelector(selectBookingTemplateId, (templateId) => Boolean(templateId));
const selectBookingPageNameById = (id: string) =>
  createSelector(
    selectBookingPageById(id),
    bookingTemplatesSelectors.selectBookingTemplateNamesById,
    (bookingPage, bookingTemplateNamesById) =>
      bookingPage?.what?.bookingTemplateId
        ? bookingTemplateNamesById[bookingPage?.what?.bookingTemplateId]
        : bookingPage?.what?.customName || ''
  );

const selectInviteOthers = createSelector(selectBookingPage, (bookingPage) => bookingPage.inviteOthers);
const selectEnterNote = createSelector(selectBookingPage, (bookingPage) => bookingPage.enterNote);

const selectShortLink = createSelector(selectBookingPage, (bookingPage) => bookingPage.shortLink);

const selectStyle = createSelector(selectBookingPage, (bookingPage) => bookingPage.style);

const selectCalendar = createSelector(selectBookingPage, (bookingPage) => bookingPage.calendar);
const selectTimeFormat = createSelector(selectCalendar, (calendar) => calendar?.timeFormat);
const selectTopOfInterval = createSelector(selectCalendar, (calendar) => calendar?.topOfInterval);
const selectTimeInterval = createSelector(selectCalendar, (calendar) => calendar?.timeInterval);
const selectTimeZoneType = createSelector(selectCalendar, (calendar) => calendar?.timeZoneType);
const selectSelectedTimeZone = createSelector(selectCalendar, (calendar) => calendar?.selectedTimeZone);
const selectDefaultTimeZone = createSelector(selectCalendar, (calendar) => calendar?.defaultTimeZone);

const selectAvailableTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => !timeZones?.includes(zone.timeZone))
);
const selectAvailableTimeZonesOptions = createSelector(
  selectAvailableTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectAssignedTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => timeZones?.includes(zone.timeZone))
);
const selectAssignedTimeZonesOptions = createSelector(
  selectAssignedTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectCloneName = createSelector(bookingPagesState, (bookingPages) => bookingPages.cloneName);
const selectIsCloneNameDuplicate = createSelector(
  selectCloneName,
  selectFilteredBookingPages,
  (name, pages) => !!pages.find((page) => page.what?.customName === name)
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectIsExistingBookingPage = createSelector(selectBookingPageId, (id) => Boolean(id));

const selectIsNameValid = createSelector(selectWhatName, (name) => Boolean(name));

const selectIsTimeZonesValid = createSelector(
  selectTimeZoneType,
  selectAssignedTimeZones,
  (timeZoneType, timeZones) => timeZoneType === TimeZoneType.DETECT_INVITEE || timeZones.length
);

const selectIsWhatStepValid = createSelector(selectIsNameValid, (isNameValid) => isNameValid);

const selectIsCalendarStepValid = createSelector(selectIsTimeZonesValid, (isTimeZonesValid) => isTimeZonesValid);

const selectWho = createSelector(selectBookingPage, (bookingPage) => bookingPage.who);
const selectHosts = createSelector(selectWho, (who) => who?.hostMembers || []);
const selectIsNoHost = createSelector(selectHosts, (hosts) => !hosts.length);
const selectIsHostDeletedUser = createSelector(
  selectHosts,
  (hosts) => hosts[0]?.subType === MemberSubType.DELETED_USER
);
const selectIsHostUser = createSelector(selectWho, (who) => who?.hostMembers && isMemberSumoUser(who?.hostMembers[0]));
const selectHostUserIds = createSelector(
  selectHosts,
  (hosts) => hosts?.filter((member) => isMemberSumoUser(member)).map((host) => host?.id) || []
);
const selectHostTeamIds = createSelector(
  selectHosts,
  (hosts) => hosts?.filter((member) => isMemberSumoTeam(member)).map((host) => host?.id) || []
);
const selectAllHostsIds = createSelector(selectHostUserIds, selectHostTeamIds, (hostIds, teamsIds) => [
  ...hostIds,
  ...teamsIds,
]);
const selectAllHostsData = createSelector(
  meetingSelectors.selectSumo1Users,
  meetingSelectors.selectSumo1Teams,
  selectHosts,
  (users, teams, hosts) => getWhoData(users, teams, hosts)
);
const selectGuests = createSelector(selectWho, (who) => who?.guests || []);

const selectHostAndGuestsData = createSelector(
  meetingSelectors.selectUsers,
  meetingSelectors.selectTeams,
  selectHosts,
  selectGuests,
  (users, teams, hosts, guests) => getWhoData(users, teams, [...hosts, ...guests])
);
const selectDeletedHostAndGuestsData = createSelector(selectHostAndGuestsData, (data) =>
  data.filter((record) => record.isDeleted)
);

const selectHostIntegrations = createSelector(
  selectAllHostsData,
  (hosts) => (hosts.length && hosts[0] && hosts[0].integrationTypes) || []
);
const selectHostVideoConferences = createSelector(selectIsHostUser, selectHostIntegrations, (isUser, integrations) =>
  isUser ? integrations.filter((integration) => VIDEO_CONFERENCES.includes(integration)) : []
);

const selectIsUserRequiredHost = createSelector(
  selectIsExistingBookingPage,
  userSettingsSelectors.selectIsCreateOnlyMyBookingPages,
  userSettingsSelectors.selectIsEditOnlyMyBookingPages,
  (isExisting, isCreateOnlyMy, isEditOnlyMy) => (isExisting ? isEditOnlyMy : isCreateOnlyMy)
);

const selectIsWhoStepValid = createSelector(
  selectAllHostsIds,
  selectIsUserRequiredHost,
  authenticationSelectors.selectUserId,
  (allHostIds, isUserRequiredHost, userId) =>
    Boolean(allHostIds.length) && (!isUserRequiredHost || allHostIds[0] === userId)
);

const selectWhere = createSelector(selectBookingPage, (bookingPage) => bookingPage.where);
const selectLocationTypes = createSelector(selectWhere, (where) => where?.locationTypes || []);
const selectDefaultLocationType = createSelector(selectWhere, (where) => where?.defaultLocationType || null);
const selectSkipWhereStep = createSelector(selectWhere, (where) => where?.skipTheWhereStep);
const selectPhoneCallType = createSelector(selectWhere, (where) => where?.phoneCallType);
const selectIsPromptInviteCallType = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  (locations, callType) => locations.includes(LocationType.PHONE_CALL) && callType === PhoneCallType.PROMPT_INVITE
);
const selectPhoneCallCustomPhoneCode = createSelector(selectWhere, (where) => where?.customCountryCode);
const selectPhoneCallCustomPhoneNumber = createSelector(selectWhere, (where) => where?.customPhone);
const selectInPersonType = createSelector(selectWhere, (where) => where?.inPersonType);
const selectInPersonCustomAddress = createSelector(selectWhere, (where) => where?.customAddress);
const selectOrganizationLocations = createSelector(
  selectWhere,
  (where) => where?.locations?.filter((location): location is string => !!location) || []
);
const selectVideoConferenceType = createSelector(selectWhere, (where) => where?.videoConferenceType);

const selectIsVideoConferenceValid = createSelector(
  selectLocationTypes,
  selectAllHostsData,
  (types, hosts) =>
    !types.includes(LocationType.VIDEO_CONFERENCE) ||
    hosts.every(
      (host) =>
        host.integrationTypes?.includes(IntegrationType.GOOGLE_MEET) ||
        host.integrationTypes?.includes(IntegrationType.MICROSOFT_TEAMS) ||
        host.integrationTypes?.includes(IntegrationType.ZOOM)
    )
);

const selectVideoConferenceNotification = createSelector(selectIsVideoConferenceValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE_NOTIFICATION')
);

const selectIsHostPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectAllHostsData,
  (types, type, hosts) =>
    !types.includes(LocationType.PHONE_CALL) ||
    type !== PhoneCallType.HOST_PHONE_NUMBER ||
    hosts.every((host) => !!host.hasPhoneNumber)
);
const selectHostPhoneNotification = createSelector(selectIsHostPhoneValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:HOSTS_PHONE_NOTIFICATION')
);

const selectIsCustomPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectPhoneCallCustomPhoneNumber,
  (types, type, customPhone) =>
    !types.includes(LocationType.PHONE_CALL) || type !== PhoneCallType.CUSTOM_PHONE || validatePhoneNumber(customPhone)
);
const selectIsCustomAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectInPersonCustomAddress,
  (types, type, address) =>
    !types.includes(LocationType.IN_PERSON) || type !== InPersonType.CUSTOM_ADDRESS || Boolean(address)
);
const selectIsCompanyAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectOrganizationLocations,
  locationsSelectors.selectLocations,
  (types, type, organizationLocations, locations) =>
    !types.includes(LocationType.IN_PERSON) ||
    type !== InPersonType.LOCATION ||
    locations.some((location) => location.active && organizationLocations.includes(location.id))
);
const selectIsDefaultLocationTypeValid = createSelector(
  selectLocationTypes,
  selectDefaultLocationType,
  (types, defaultLocationType) => types.includes(defaultLocationType)
);

const selectWhen = createSelector(selectBookingPage, (bookingPage) => bookingPage.when);
const selectDateRange = createSelector(selectWhen, (when) => when?.dateRange);
const selectDateRangeType = createSelector(selectDateRange, (dateRange) => dateRange?.type);
const selectDateRangeCount = createSelector(selectDateRange, (dateRange) => dateRange?.count);
const selectDateRangeList = createSelector(selectDateRange, (dateRange) => [
  ...(dateRange?.from ? [dateRange.from] : []),
  ...(dateRange?.to ? [dateRange.to] : []),
]);
const selectDuration = createSelector(selectWhen, (when) => when?.duration);
const selectDurationCount = createSelector(selectDuration, (duration) => duration?.count);
const selectDurationTimeUnit = createSelector(selectDuration, (duration) => duration?.timeUnit);
const selectScheduleBuffer = createSelector(selectWhen, (when) => when?.scheduleBuffer);
const selectScheduleBufferCount = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.count);
const selectScheduleBufferTimeUnit = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.timeUnit);
const selectTimeBeforeStartTime = createSelector(selectWhen, (when) => when?.beforeStartTime);
const selectTimeAfterEndTime = createSelector(selectWhen, (when) => when?.afterEndTime);
const selectIsDurationCountValid = createSelector(selectDurationCount, (count) => Boolean(count));
const selectIsDateRangeCountValid = createSelector(selectDateRangeCount, (count) => Boolean(count));
const selectIsDateRangeValid = createSelector(selectDateRangeList, (dateRangeList) => dateRangeList.length === 2);

const selectDurationMinutes = createSelector(selectDurationCount, selectDurationTimeUnit, (count, timeUnit) =>
  calculateMinutes(count, timeUnit)
);
const selectBeforeBufferMinutes = createSelector(
  selectTimeBeforeStartTime,
  (beforeStartTime) => Number(beforeStartTime?.replace('m', '')) || 0
);
const selectAfterBufferMinutes = createSelector(
  selectTimeAfterEndTime,
  (afterEndTime) => Number(afterEndTime?.replace('m', '')) || 0
);

const selectInputFields = createSelector(selectBookingPage, (bookingPage) => bookingPage.inputFields || []);
const selectPhoneInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.PHONE)
);
const selectLocationInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.LOCATION)
);

const selectNotifications = createSelector(selectBookingPage, (bookingPage) => bookingPage.notifications);
const selectConfirmation = createSelector(selectNotifications, (notifications) => notifications?.confirmations || {});
const selectReschedule = createSelector(selectNotifications, (notifications) => notifications?.reschedule || {});
const selectReminder = createSelector(selectNotifications, (notifications) => notifications?.reminders || {});
const selectReminderTiming = createSelector(selectReminder, (reminders) => reminders?.timing || []);
const selectReminderDuplicateTimerIndexes = createSelector(selectReminderTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectCancelation = createSelector(selectNotifications, (notifications) => notifications?.cancelations || {});
const selectFollowUp = createSelector(selectNotifications, (notifications) => notifications?.followUp || {});

const selectSmsReminder = createSelector(selectNotifications, (notifications) => notifications?.smsReminder || {});
const selectSmsTiming = createSelector(selectSmsReminder, (smsReminder) => smsReminder?.timing || []);
const selectSmsDuplicateTimerIndexes = createSelector(selectSmsTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectSmsReminderOptionEnabled = createSelector(selectSmsReminder, (smsReminder) => smsReminder.enabled);

const selectConfirmationPage = createSelector(selectBookingPage, (bookingPage) => bookingPage.confirmationsPage);
const selectConfirmationType = createSelector(selectConfirmationPage, (confirmationsPage) => confirmationsPage?.type);
const selectConfirmationExternalLink = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.externalLink
);
const selectConfirmationAnotherEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.anotherEventEnabled
);
const selectConfirmationRescheduleEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.rescheduleEnabled
);
const selectConfirmationCancelEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelEnabled
);
const selectConfirmationCustomLinks = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.customLinks
);
const selectIsExternalLinkValid = createSelector(
  selectConfirmationType,
  selectConfirmationExternalLink,
  (type, link) => type !== ConfirmationsPageType.REDIRECT || Boolean(link)
);
const selectCancelationPolicy = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelationPolicy
);

const selectIsWhereStepValid = createSelector(
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,
  (isPhoneValid, isCustomAddressValid, isCompanyAddressValid, isDefaultLocationTypeValid) =>
    isPhoneValid && isCustomAddressValid && isCompanyAddressValid && isDefaultLocationTypeValid
);
const selectIsWhenStepValid = createSelector(
  selectDateRangeType,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,
  (type, isDurationCountValid, isDateRangeCountValid, isDateRangeValid) =>
    isDurationCountValid &&
    (type === DateRangeType.DAYS_IN_FUTURE
      ? isDateRangeCountValid
      : type === DateRangeType.SPECIFIC_DATES
      ? isDateRangeValid
      : true)
);
const selectIsNotificationsStepValid = createSelector(
  selectConfirmation,
  selectReminder,
  selectCancelation,
  selectFollowUp,
  selectSmsReminder,
  selectReminderDuplicateTimerIndexes,
  selectSmsDuplicateTimerIndexes,
  (confirmations, reminders, cancelations, followUp, smsReminder, reminderDuplicateIndexes, smsDuplicateIndexes) =>
    (!confirmations.enabled || (Boolean(confirmations.subject) && Boolean(confirmations.body))) &&
    (!reminders.enabled || (Boolean(reminders.subject) && Boolean(reminders.body))) &&
    (!cancelations.enabled || (Boolean(cancelations.subject) && Boolean(cancelations.body))) &&
    (!followUp.enabled || (Boolean(followUp.subject) && Boolean(followUp.body))) &&
    (!smsReminder.enabled || Boolean(smsReminder.body)) &&
    reminderDuplicateIndexes.length === 0 &&
    smsDuplicateIndexes.length === 0
);
const selectIsInviteeStepValid = createSelector(selectInputFields, (inputFields) =>
  inputFields.every((field) => validateCustomFieldPrepopulate(field))
);
const selectIsConfirmationStepValid = createSelector(selectIsExternalLinkValid, (isLinkValid) => isLinkValid);

const selectPotentialHosts = createSelector(
  selectHasTemplate,
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplates,
  meetingSelectors.selectUsers,
  (hasTemplate, bookingTemplateId, bookingTemplates, workspaceUsers) => {
    let userLists: MemberDTO[] = [];
    if (hasTemplate) {
      const bookingTemplate = bookingTemplates.find((template) => template.id === bookingTemplateId);
      if (bookingTemplate?.potentialHosts) {
        const usersId = bookingTemplate.potentialHosts.filter((id) => !!id) as string[];
        userLists = workspaceUsers.filter((user) => user.id && usersId.includes(user.id));
      }
    } else {
      userLists = workspaceUsers;
    }
    return userLists;
  }
);
const selectPotentialTeams = createSelector(
  selectHasTemplate,
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplates,
  meetingSelectors.selectTeams,
  meetingSelectors.selectUsers,
  selectRecordWhoTeamIds,
  (hasTemplate, bookingTemplateId, bookingTemplates, teams, users, recordTeamIds) => {
    let teamList: MemberDTO[] = [];
    if (hasTemplate) {
      const bookingTemplate = bookingTemplates.find((template) => template.id === bookingTemplateId);
      if (bookingTemplate?.potentialTeams) {
        const teamIds = bookingTemplate.potentialTeams.filter((id) => !!id) as string[];
        teamList = teams.filter(
          (team) => team.id && teamIds.includes(team.id) && (team.active || recordTeamIds.includes(team.id))
        );
      }
    } else {
      teamList = teams.filter((team) => team.id && (team.active || recordTeamIds.includes(team.id)));
    }
    return teamList;
  }
);

const selectPotentialHostsAndTeams = createSelector(selectPotentialHosts, selectPotentialTeams, (hosts, teams) => [
  ...hosts,
  ...teams,
]);

const selectIsAdminOnlyLocked = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsAdminOnly,
    userSettingsSelectors.selectIsAdminRole,
    (isAdminOnly, isAdmin) => (bookingPage !== undefined ? !!bookingPage.adminOnly : isAdminOnly) && !isAdmin
  );

const selectIsMyBookingPage = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(authenticationSelectors.selectUserId, selectHostUserIds, (userId, hostsIds) =>
    bookingPage !== undefined
      ? bookingPage.who?.hostMembers && bookingPage.who?.hostMembers[0]?.id === userId
      : hostsIds.includes(userId)
  );

const selectIsReadOnlyBookingPage = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    userSettingsSelectors.selectMyBookingPagesReadOnly,
    userSettingsSelectors.selectAllBookingPagesReadOnly,
    (isExisting, isMyBookingPage, isMyReadOnly, isAllReadOnly) =>
      (!!bookingPage || isExisting) && (isMyBookingPage ? isMyReadOnly : isAllReadOnly)
  );

const selectIsBookingPageCreate = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    userSettingsSelectors.selectMyBookingPagesCreate,
    userSettingsSelectors.selectAllBookingPagesCreate,
    (isExisting, isMyBookingPage, isMyCreate, isAllCreate) =>
      (!!bookingPage || isExisting) && (isMyBookingPage ? isMyCreate : isAllCreate)
  );

const selectIsBookingPageEdit = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    userSettingsSelectors.selectMyBookingPagesEdit,
    userSettingsSelectors.selectAllBookingPagesEdit,
    (isExisting, isMyBookingPage, isAdminLocked, isMyEdit, isAllEdit) =>
      (!!bookingPage || isExisting) && !isAdminLocked && (isMyBookingPage ? isMyEdit : isAllEdit)
  );

const selectIsBookingPageDelete = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    userSettingsSelectors.selectMyBookingPagesDelete,
    userSettingsSelectors.selectAllBookingPagesDelete,
    (isExisting, isMyBookingPage, isAdminLocked, isMyDelete, isAllDelete) =>
      (!!bookingPage || isExisting) && !isAdminLocked && (isMyBookingPage ? isMyDelete : isAllDelete)
  );

const selectIsBookingPageLocked = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsReadOnlyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    (isReadonly, isAdminLocked) => isReadonly || !!isAdminLocked
  );

const selectDisableDeleteSelectedBookingPages = createSelector(
  selectBookingPages,
  selectSelectedBookingPages,
  authenticationSelectors.selectUserId,
  userSettingsSelectors.selectMyBookingPagesDelete,
  userSettingsSelectors.selectAllBookingPagesDelete,
  userSettingsSelectors.selectIsAdminRole,
  (bookingPages, selectedBookingPages, userId, isMyDelete, isAllDelete, isAdmin) =>
    bookingPages
      .filter((bookingPage) => selectedBookingPages.includes(bookingPage.id))
      .some((bookingPage) => !canDeleteBookingPage(bookingPage, userId, isMyDelete, isAllDelete, isAdmin))
);

const selectLockedTooltip = createSelector(
  selectIsReadOnlyBookingPage(),
  selectIsAdminOnlyLocked(),
  selectLastModify,
  (isReadonly, isAdminLocked, lastModify) =>
    isAdminLocked
      ? `${t('EditBookingPage:LOCKED_ADMIN_ONLY')} ${lastModify}`
      : isReadonly
      ? `${t('EditBookingPage:LOCKED_NO_PERMISSIONS')} ${lastModify}`
      : ''
);

const selectHostsInfoById = createSelector(
  meetingSelectors.selectUserNamesById,
  meetingSelectors.selectTeamNamesById,
  selectFilteredBookingPages,
  (users, teams, bookingPages) => {
    const hostsInfoMap: Record<string, string> = {};
    bookingPages.forEach((bookingPage) => {
      const hosts = [
        ...(bookingPage.who?.hostMembers ? bookingPage.who.hostMembers : []),
        ...(bookingPage.who?.guests ? bookingPage.who.guests : []),
      ];
      let hostsInfo = '';
      const isHostDeletedUser = bookingPage.who?.hostMembers?.[0]?.subType === MemberSubType.DELETED_USER;
      !isHostDeletedUser &&
        hosts.forEach((host) => {
          const name = host?.id && (users[host?.id] || teams[host?.id] || host?.name);
          if (name) {
            hostsInfo += (hostsInfo.length ? ', ' : '') + name;
          }
        });
      hostsInfoMap[bookingPage.id] = hostsInfo;
    });

    return hostsInfoMap;
  }
);

const selectHostsInfo = createSelector(
  meetingSelectors.selectUserNamesById,
  meetingSelectors.selectTeamNamesById,
  selectIsHostDeletedUser,
  selectBookingPage,
  (users, teams, isHostDeletedUser, bookingPage) => {
    const hosts = [...(bookingPage.who?.hostMembers || []), ...(bookingPage.who?.guests || [])];

    let hostsInfo = '';
    !isHostDeletedUser &&
      hosts.forEach((host) => {
        const name = host?.id && (users[host?.id] || teams[host?.id] || host?.name);
        if (name) {
          hostsInfo += (hostsInfo.length ? ', ' : '') + name;
        }
      });

    return hostsInfo;
  }
);

const selectBookingPageForPreview = createSelector(selectBookingPage, (bookingPage) => bookingPage);

const selectSmartAlerts = createSelector(selectBookingPage, (page) => page.smartAlertIds || []);

const selectBookingPagesAvailForEdit = createSelector(
  selectBookingPages,
  (state: State) => state,
  (pages, state) => pages.filter((page) => selectIsBookingPageEdit(page)(state))
);

const selectLinkedBookingPages = (groupBookingPage: UpdateGroupBookingPageInput) =>
  createSelector(selectBookingPages, (bookingPages) => {
    const res: UpdateBookingPageInput[] = [];
    groupBookingPage.bookingPages?.forEach((record) => {
      if (record?.bookingPageId) {
        const page = bookingPages.find((page) => page?.id === record?.bookingPageId);
        if (page) {
          res.push(page);
        }
      }
    });
    return res;
  });

const selectLocationDependencesBP = createSelector(
  locationsSelectors.selectDeleteDependences,
  selectBookingPages,
  (dependences, bookingPages) => {
    const uniqueBookingPages = new Set();

    return dependences.bookingPages?.reduce((result, id) => {
      const bookingPage = bookingPages.find((bp) => bp.id === id);
      if (bookingPage && !uniqueBookingPages.has(bookingPage.id)) {
        uniqueBookingPages.add(bookingPage.id);
        result.push(bookingPage);
      }
      return result;
    }, [] as typeof bookingPages);
  }
);

const selectStepsIsValidInfo = createSelector(
  selectIsWhatStepValid,
  selectIsWhoStepValid,
  selectIsWhereStepValid,
  selectIsWhenStepValid,
  selectIsNotificationsStepValid,
  selectIsInviteeStepValid,
  selectIsConfirmationStepValid,
  (
    isWhatStepValid,
    isWhoStepValid,
    isWhereStepValid,
    isWhenStepValid,
    isNotificationsStepValid,
    isInviteeStepValid,
    isConfirmationStepValid
  ) => [
    { isValid: isWhatStepValid, key: AccordionIndexValues.what },
    { isValid: isWhoStepValid, key: AccordionIndexValues.who },
    { isValid: isWhereStepValid, key: AccordionIndexValues.where },
    { isValid: isWhenStepValid, key: AccordionIndexValues.when },
    { isValid: isNotificationsStepValid, key: AccordionIndexValues.alerts },
    { isValid: isInviteeStepValid, key: AccordionIndexValues.invitee },
    { isValid: isConfirmationStepValid, key: AccordionIndexValues.after },
  ]
);

export const bookingPageSelectors = {
  selectIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectIsEdited,
  selectBookingPages,
  selectBookingPageIds,
  selectMyDefaultBookingPage,
  selectBookingPagesLength,
  selectIsUnassignedPages,
  selectFilteredBookingPages,
  selectSearchedBookingPages,
  selectPersonalSearchedBookingPages,
  selectSharedSearchedBookingPages,
  selectSelectedBookingPages,
  selectAddToWebsiteLink,
  selectBookingPage,
  selectBookingPagesById,
  selectAccordionIndexes,
  selectFilterByUser,
  selectIsBookingPageSelector,

  selectBookingPageById,
  selectBookingPagesOptions,
  selectWhat,
  selectBookingPageId,
  selectDisplayId,
  selectIsAdminOnly,
  selectWhatName,
  selectIsActive,
  selectInstructions,

  selectBookingTemplateId,
  selectBookingTemplateName,
  selectHasTemplate,
  selectBookingPageNameById,
  selectInviteOthers,
  selectEnterNote,
  selectShortLink,
  selectStyle,

  selectCalendar,
  selectTimeFormat,
  selectTopOfInterval,
  selectTimeInterval,
  selectTimeZoneType,
  selectSelectedTimeZone,
  selectDefaultTimeZone,
  selectAvailableTimeZones,
  selectAvailableTimeZonesOptions,
  selectAssignedTimeZones,
  selectAssignedTimeZonesOptions,
  selectCloneName,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsExistingBookingPage,
  selectIsNameValid,
  selectIsTimeZonesValid,
  selectIsCalendarStepValid,

  selectWhatColor,

  selectWhere,
  selectLocationTypes,
  selectDefaultLocationType,
  selectSkipWhereStep,
  selectPhoneCallType,
  selectIsPromptInviteCallType,
  selectPhoneCallCustomPhoneCode,
  selectPhoneCallCustomPhoneNumber,
  selectInPersonType,
  selectInPersonCustomAddress,
  selectOrganizationLocations,
  selectVideoConferenceType,
  selectIsVideoConferenceValid,
  selectVideoConferenceNotification,
  selectIsHostPhoneValid,
  selectHostPhoneNotification,
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,

  selectDateRangeType,
  selectDateRangeCount,
  selectDateRangeList,
  selectDuration,
  selectDurationCount,
  selectDurationTimeUnit,
  selectScheduleBufferCount,
  selectScheduleBufferTimeUnit,
  selectTimeBeforeStartTime,
  selectTimeAfterEndTime,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,

  selectDurationMinutes,
  selectBeforeBufferMinutes,
  selectAfterBufferMinutes,

  selectInputFields,
  selectPhoneInputFields,
  selectLocationInputFields,

  selectNotifications,
  selectConfirmation,
  selectReschedule,
  selectReminder,
  selectCancelation,
  selectFollowUp,

  selectSmsReminder,
  selectSmsReminderOptionEnabled,

  selectConfirmationType,
  selectConfirmationExternalLink,
  selectConfirmationAnotherEnabled,
  selectConfirmationRescheduleEnabled,
  selectConfirmationCancelEnabled,
  selectConfirmationCustomLinks,
  selectIsExternalLinkValid,
  selectCancelationPolicy,

  selectIsWhatStepValid,
  selectIsWhoStepValid,
  selectIsWhereStepValid,
  selectIsWhenStepValid,
  selectIsNotificationsStepValid,
  selectIsInviteeStepValid,
  selectIsConfirmationStepValid,

  selectIsHostUser,
  selectAllHostsData,
  selectHostUserIds,
  selectHostTeamIds,
  selectAllHostsIds,
  selectHosts,
  selectIsNoHost,
  selectIsHostDeletedUser,
  selectGuests,

  selectPotentialHosts,
  selectPotentialTeams,
  selectPotentialHostsAndTeams,
  selectHostAndGuestsData,
  selectDeletedHostAndGuestsData,
  selectHostIntegrations,
  selectHostVideoConferences,
  selectIsUserRequiredHost,
  selectIsMyBookingPage,
  selectIsAdminOnlyLocked,
  selectIsBookingPageCreate,
  selectIsBookingPageEdit,
  selectIsBookingPageDelete,
  selectDisableDeleteSelectedBookingPages,
  selectIsBookingPageLocked,
  selectLockedTooltip,
  selectHostsInfoById,
  selectHostsInfo,
  selectBookingPageForPreview,
  selectSmartAlerts,
  selectBookingPagesAvailForEdit,

  selectLinkedBookingPages,

  selectLocationDependencesBP,
  selectStepsIsValidInfo,
};
