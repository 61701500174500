import { createSelector } from 'reselect';
import { State } from '../rootStore';
import {
  DateRangeType,
  PhoneCallType,
  ConfirmationsPageType,
  UpdateBookingTemplateInput,
  InPersonType,
  LocationType,
  IntegrationType,
  CustomFieldType,
  TimeZoneType,
  UpdateBookingPageInput,
} from '../../API';
import { BookingTemplateOption, FilterType } from './types';
import { userSettingsSelectors } from '../userSettings';
import { calculateMinutes, isOldData, validatePhoneNumber } from '../../services/utils';
import { t } from '../../i18n/i18n';
import { authenticationSelectors } from '../authentication';
import { globalSelectors } from '../global';
import { TIME_ZONE_LIST } from '../../types/constants';
import { locationsSelectors } from '../locations';
import { validateCustomFieldPrepopulate } from '../publicBookingPage/utils';
import { assignTime, meetingSelectors } from '../meeting';
import { workspacesSelectors } from '../workspaces';
import { UserResourceOption } from '../users/types';

const bookingTemplatesState = (state: State) => state.bookingTemplates;

const selectIsFetching = createSelector(bookingTemplatesState, (state) => state.isFetching);
const selectSelectedBookingTemplates = createSelector(bookingTemplatesState, (state) => state.selectedBookingTemplates);
const selectIsEdited = createSelector(bookingTemplatesState, (state) => state.isEdited);
const selectBookingTemplates = createSelector(bookingTemplatesState, (state) => state.bookingTemplates);
const selectBookingTemplatesForTenant = createSelector(
  bookingTemplatesState,
  (state) => state.bookingTemplatesForTenant
);

const selectLastLoadTime = createSelector(bookingTemplatesState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectBookingTemplateIds = createSelector(selectBookingTemplates, (bookingTemplates) =>
  bookingTemplates.map((bookingTemplate) => bookingTemplate.id)
);

const selectBookingTemplateNamesById = createSelector(selectBookingTemplates, (bookingTemplates) =>
  bookingTemplates.reduce((namesById, bookingTemplate) => {
    namesById[bookingTemplate.id] = bookingTemplate.what?.customName;
    return namesById;
  }, {} as Record<string, string | undefined | null>)
);
const selectBookingTemplatesOptions = (selectedId: string, withCurrentUser?: boolean) =>
  createSelector(selectBookingTemplates, authenticationSelectors.selectUserId, (bookingTemplates, userId) =>
    bookingTemplates
      .filter(
        (bookingTemplate) =>
          bookingTemplate.id === selectedId ||
          (bookingTemplate.enabled && (!withCurrentUser || bookingTemplate.potentialHosts?.includes(userId)))
      )
      .map((type) => ({ name: type.what?.customName || '', id: type.id } as BookingTemplateOption))
  );

const selectFilterType = createSelector(bookingTemplatesState, (state) => state.filterType);

const selectFilteredBookingTemplates = createSelector(
  selectBookingTemplates,
  selectFilterType,
  authenticationSelectors.selectUserId,
  (bookingTemplates, filterType, currentUserId) =>
    filterType === FilterType.MY
      ? bookingTemplates.filter((template) => template.potentialHosts?.includes(currentUserId))
      : bookingTemplates
);

const selectSearchedBookingTemplates = createSelector(
  selectFilteredBookingTemplates,
  globalSelectors.selectLowercasedSearchString,
  (bookingTemplates, searchString) =>
    bookingTemplates
      .filter((bookingTemplate) => bookingTemplate.what?.customName?.toLowerCase().includes(searchString))
      .sort((a, b) => (a.what?.customName?.toLowerCase() ?? '').localeCompare(b.what?.customName?.toLowerCase() ?? ''))
);

const selectCloneName = createSelector(bookingTemplatesState, (bookingTemplate) => bookingTemplate.cloneName || '');
const selectIsCloneNameDuplicate = createSelector(
  selectCloneName,
  selectBookingTemplates,
  (name, types) => !!types.find((type) => type.what?.customName === name)
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectBookingTemplate = createSelector(
  bookingTemplatesState,
  (bookingTemplates) => bookingTemplates.bookingTemplate
);
const selectAccordionIndexes = createSelector(
  bookingTemplatesState,
  (bookingTemplates) => bookingTemplates.accordionIndexes
);
const selectIsBookingPageSelector = () => false;

const selectId = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.id);
const selectIsExisting = createSelector(selectId, (id) => Boolean(id));
const selectIsActive = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.enabled);
const selectLastModify = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.lastModify);

const selectWhat = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.what);
const selectInstructions = createSelector(selectWhat, (what) => what?.instructions);
const selectWhatName = createSelector(selectWhat, (what) => what?.customName);
const selectWhatColor = createSelector(selectWhat, (what) => what?.color);

const selectIsNameDuplicate = createSelector(selectBookingTemplates, selectId, selectWhatName, (templates, id, name) =>
  Boolean(templates.find((template) => template.id !== id && template.what?.customName === name))
);
const selectIsNameValid = createSelector(
  selectWhatName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectWhen = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.when);
const selectDateRange = createSelector(selectWhen, (when) => when?.dateRange);
const selectDateRangeType = createSelector(selectDateRange, (dateRange) => dateRange?.type);
const selectDateRangeCount = createSelector(selectDateRange, (dateRange) => dateRange?.count);
const selectDateRangeList = createSelector(selectDateRange, (dateRange) => [
  ...(dateRange?.from ? [dateRange.from] : []),
  ...(dateRange?.to ? [dateRange.to] : []),
]);
const selectDuration = createSelector(selectWhen, (when) => when?.duration);
const selectDurationCount = createSelector(selectDuration, (duration) => duration?.count);
const selectDurationTimeUnit = createSelector(selectDuration, (duration) => duration?.timeUnit);
const selectScheduleBuffer = createSelector(selectWhen, (when) => when?.scheduleBuffer);
const selectScheduleBufferCount = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.count);
const selectScheduleBufferTimeUnit = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.timeUnit);
const selectTimeBeforeStartTime = createSelector(selectWhen, (when) => when?.beforeStartTime);
const selectTimeAfterEndTime = createSelector(selectWhen, (when) => when?.afterEndTime);
const selectIsDurationCountValid = createSelector(selectDurationCount, (count) => Boolean(count));
const selectIsDateRangeCountValid = createSelector(selectDateRangeCount, (count) => Boolean(count));
const selectIsDateRangeValid = createSelector(selectDateRangeList, (dateRangeList) => dateRangeList.length === 2);

const selectDurationMinutes = createSelector(selectDurationCount, selectDurationTimeUnit, (count, timeUnit) =>
  calculateMinutes(count, timeUnit)
);
const selectBeforeBufferMinutes = createSelector(
  selectTimeBeforeStartTime,
  (beforeStartTime) => Number(beforeStartTime?.replace('m', '')) || 0
);
const selectAfterBufferMinutes = createSelector(
  selectTimeAfterEndTime,
  (afterEndTime) => Number(afterEndTime?.replace('m', '')) || 0
);

const selectPotentialHosts = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.potentialHosts);
const selectIsHostsValid = createSelector(selectPotentialHosts, (hosts) => hosts?.length);

const selectPotentialTeams = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.potentialTeams);
const selectIsTeamsValid = createSelector(selectPotentialTeams, (teams) => teams?.length);

const selectAvailableHostsAndTeams = createSelector(
  meetingSelectors.selectSumo1Members,
  meetingSelectors.selectCEPSalesforceMembers,
  (sumo1Member, CEPMember) => [...sumo1Member, ...CEPMember]
);

const selectAssignedHostsAndTeams = createSelector(
  meetingSelectors.selectUsers,
  meetingSelectors.selectTeams,
  selectPotentialHosts,
  selectPotentialTeams,
  (users, teams, hostIds, teamIds) => [
    ...users.filter((user) => hostIds?.includes(user.id)),
    ...teams.filter((team) => teamIds?.includes(team.id)),
  ]
);
const selectIsNoHost = createSelector(
  selectPotentialHosts,
  selectPotentialTeams,
  (hosts, teams) => !hosts?.length && !teams?.length
);

const selectWhere = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.where);
const selectLocationTypes = createSelector(selectWhere, (where) => where?.locationTypes || []);
const selectDefaultLocationType = createSelector(selectWhere, (where) => where?.defaultLocationType || null);
const selectSkipWhereStep = createSelector(selectWhere, (where) => where?.skipTheWhereStep);
const selectPhoneCallType = createSelector(selectWhere, (where) => where?.phoneCallType);
const selectIsPromptInviteCallType = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  (locations, callType) => locations.includes(LocationType.PHONE_CALL) && callType === PhoneCallType.PROMPT_INVITE
);
const selectPhoneCallCustomPhoneCode = createSelector(selectWhere, (where) => where?.customCountryCode);
const selectPhoneCallCustomPhoneNumber = createSelector(selectWhere, (where) => where?.customPhone);
const selectInPersonType = createSelector(selectWhere, (where) => where?.inPersonType);
const selectInPersonCustomAddress = createSelector(selectWhere, (where) => where?.customAddress);
const selectOrganizationLocations = createSelector(
  selectWhere,
  (where) => where?.locations?.filter((location): location is string => !!location) || []
);
const selectVideoConferenceType = createSelector(selectWhere, (where) => where?.videoConferenceType);

const selectIsVideoConferenceValid = createSelector(
  selectLocationTypes,
  selectAssignedHostsAndTeams,
  (types, hostAndTeams) =>
    !types.includes(LocationType.VIDEO_CONFERENCE) ||
    hostAndTeams.every(
      (member) =>
        member.integrationTypes?.includes(IntegrationType.GOOGLE_MEET) ||
        member.integrationTypes?.includes(IntegrationType.MICROSOFT_TEAMS) ||
        member.integrationTypes?.includes(IntegrationType.ZOOM)
    )
);
const selectVideoConferenceNotification = createSelector(selectIsVideoConferenceValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE_NOTIFICATION')
);

const selectIsHostPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectAssignedHostsAndTeams,
  (types, type, hostAndTeams) =>
    !types.includes(LocationType.PHONE_CALL) ||
    type !== PhoneCallType.HOST_PHONE_NUMBER ||
    hostAndTeams.every((host) => !!host.hasPhoneNumber)
);
const selectHostPhoneNotification = createSelector(selectIsHostPhoneValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:HOSTS_PHONE_NOTIFICATION')
);

const selectIsCustomPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectPhoneCallCustomPhoneNumber,
  (types, type, customPhone) =>
    !types.includes(LocationType.PHONE_CALL) || type !== PhoneCallType.CUSTOM_PHONE || validatePhoneNumber(customPhone)
);
const selectIsCustomAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectInPersonCustomAddress,
  (types, type, address) =>
    !types.includes(LocationType.IN_PERSON) || type !== InPersonType.CUSTOM_ADDRESS || Boolean(address)
);
const selectIsCompanyAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectOrganizationLocations,
  locationsSelectors.selectLocations,
  (types, type, organizationLocations, locations) =>
    !types.includes(LocationType.IN_PERSON) ||
    type !== InPersonType.LOCATION ||
    locations.some((location) => location.active && organizationLocations.includes(location.id))
);
const selectIsDefaultLocationTypeValid = createSelector(
  selectLocationTypes,
  selectDefaultLocationType,
  (types, defaultLocationType) => types.includes(defaultLocationType)
);

const selectInputFields = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.inputFields || []);
const selectPhoneInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.PHONE)
);
const selectLocationInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.LOCATION)
);

const selectNotifications = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.notifications);
const selectConfirmation = createSelector(selectNotifications, (notifications) => notifications?.confirmations || {});
const selectReschedule = createSelector(selectNotifications, (notifications) => notifications?.reschedule || {});
const selectReminder = createSelector(selectNotifications, (notifications) => notifications?.reminders || {});
const selectReminderTiming = createSelector(selectReminder, (reminders) => reminders?.timing || []);
const selectReminderDuplicateTimerIndexes = createSelector(selectReminderTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectCancelation = createSelector(selectNotifications, (notifications) => notifications?.cancelations || {});
const selectFollowUp = createSelector(selectNotifications, (notifications) => notifications?.followUp || {});

const selectSmsReminder = createSelector(selectNotifications, (notifications) => notifications?.smsReminder || {});
const selectSmsTiming = createSelector(selectSmsReminder, (smsReminder) => smsReminder?.timing || []);
const selectSmsDuplicateTimerIndexes = createSelector(selectSmsTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectSmsReminderOptionEnabled = createSelector(selectSmsReminder, (smsReminder) => smsReminder.enabled);

const selectConfirmationPage = createSelector(
  selectBookingTemplate,
  (bookingTemplate) => bookingTemplate.confirmationsPage
);
const selectConfirmationType = createSelector(selectConfirmationPage, (confirmationsPage) => confirmationsPage?.type);
const selectConfirmationExternalLink = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.externalLink
);
const selectConfirmationAnotherEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.anotherEventEnabled
);
const selectConfirmationRescheduleEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.rescheduleEnabled
);
const selectConfirmationCancelEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelEnabled
);
const selectConfirmationCustomLinks = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.customLinks
);
const selectIsExternalLinkValid = createSelector(
  selectConfirmationType,
  selectConfirmationExternalLink,
  (type, link) => type !== ConfirmationsPageType.REDIRECT || Boolean(link)
);
const selectCancelationPolicy = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelationPolicy
);

const selectIsWhatStepValid = createSelector(selectIsNameValid, (isNameValid) => isNameValid);
const selectIsWhoStepValid = createSelector(selectIsHostsValid, selectIsTeamsValid, (isHostsValid, isTeamValid) =>
  Boolean(isHostsValid || isTeamValid)
);
const selectIsWhereStepValid = createSelector(
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,
  (isPhoneValid, isCustomAddressValid, isCompanyAddressValid, isDefaultLocationTypeValid) =>
    isPhoneValid && isCustomAddressValid && isCompanyAddressValid && isDefaultLocationTypeValid
);
const selectIsWhenStepValid = createSelector(
  selectDateRangeType,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,
  (type, isDurationCountValid, isDateRangeCountValid, isDateRangeValid) =>
    isDurationCountValid &&
    (type === DateRangeType.DAYS_IN_FUTURE
      ? isDateRangeCountValid
      : type === DateRangeType.SPECIFIC_DATES
      ? isDateRangeValid
      : true)
);
const selectIsNotificationsStepValid = createSelector(
  selectConfirmation,
  selectReminder,
  selectCancelation,
  selectFollowUp,
  selectSmsReminder,
  selectReminderDuplicateTimerIndexes,
  selectSmsDuplicateTimerIndexes,
  (confirmations, reminders, cancelations, followUp, smsReminder, reminderDuplicateIndexes, smsDuplicateIndexes) =>
    (!confirmations.enabled || (Boolean(confirmations.subject) && Boolean(confirmations.body))) &&
    (!reminders.enabled || (Boolean(reminders.subject) && Boolean(reminders.body))) &&
    (!cancelations.enabled || (Boolean(cancelations.subject) && Boolean(cancelations.body))) &&
    (!followUp.enabled || (Boolean(followUp.subject) && Boolean(followUp.body))) &&
    (!smsReminder.enabled || Boolean(smsReminder.body)) &&
    reminderDuplicateIndexes.length === 0 &&
    smsDuplicateIndexes.length === 0
);
const selectIsInviteeStepValid = createSelector(selectInputFields, (inputFields) =>
  inputFields.every((field) => validateCustomFieldPrepopulate(field))
);
const selectIsConfirmationStepValid = createSelector(selectIsExternalLinkValid, (isLinkValid) => isLinkValid);

const selectBookingTemplatesById = createSelector(selectBookingTemplates, (bookingTemplates) =>
  bookingTemplates.reduce((templatesMap, bookingTemplate) => {
    templatesMap[bookingTemplate.id] = bookingTemplate;
    return templatesMap;
  }, {} as Record<string, UpdateBookingTemplateInput>)
);

const selectIsEditBookingTemplate = createSelector(
  selectIsExisting,
  userSettingsSelectors.selectBookingTemplatesCreate,
  userSettingsSelectors.selectBookingTemplatesEdit,
  (isExisting, isCreate, isEdit) => (isExisting ? isEdit : isCreate)
);

const selectIsReadOnlyBookingTemplate = createSelector(
  selectIsExisting,
  userSettingsSelectors.selectBookingTemplatesReadOnly,
  (isExisting, isReadOnly) => isExisting && isReadOnly
);

const selectLockedTooltip = createSelector(
  selectLastModify,
  (lastModify) => `${t('EditBookingTemplate:LOCKED_TOOLTIP')} ${lastModify}`
);

const selectInviteOthers = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.inviteOthers);
const selectEnterNote = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.enterNote);

const selectStyle = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.style);

const selectCalendar = createSelector(selectBookingTemplate, (bookingTemplate) => bookingTemplate.calendar);
const selectTimeFormat = createSelector(selectCalendar, (calendar) => calendar?.timeFormat);
const selectTopOfInterval = createSelector(selectCalendar, (calendar) => calendar?.topOfInterval);
const selectTimeInterval = createSelector(selectCalendar, (calendar) => calendar?.timeInterval);
const selectTimeZoneType = createSelector(selectCalendar, (calendar) => calendar?.timeZoneType);
const selectSelectedTimeZone = createSelector(selectCalendar, (calendar) => calendar?.selectedTimeZone);
const selectDefaultTimeZone = createSelector(selectCalendar, (calendar) => calendar?.defaultTimeZone);

const selectAvailableTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => !timeZones?.includes(zone.timeZone))
);
const selectAvailableTimeZonesOptions = createSelector(
  selectAvailableTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectAssignedTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => timeZones?.includes(zone.timeZone))
);
const selectAssignedTimeZonesOptions = createSelector(
  selectAssignedTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectIsTimeZonesValid = createSelector(
  selectTimeZoneType,
  selectAssignedTimeZones,
  (timeZoneType, timeZones) => timeZoneType === TimeZoneType.DETECT_INVITEE || timeZones.length
);

const selectIsCalendarStepValid = createSelector(selectIsTimeZonesValid, (isTimeZonesValid) => isTimeZonesValid);

const selectHostsInfoById = createSelector(
  meetingSelectors.selectUserNamesById,
  meetingSelectors.selectTeamNamesById,
  selectFilteredBookingTemplates,
  (users, teams, templates) => {
    const hostsInfoMap: Record<string, string> = {};

    templates.forEach((template) => {
      let hostsInfo = '';
      [...(template.potentialHosts || []), ...(template.potentialTeams || [])].forEach((host) => {
        const name = host && (users[host] || teams[host]);
        if (name) {
          hostsInfo += (hostsInfo.length ? ', ' : '') + name;
        }
      });

      hostsInfoMap[template.id] = hostsInfo;
    });

    return hostsInfoMap;
  }
);

const selectHostsInfo = createSelector(
  meetingSelectors.selectUserNamesById,
  meetingSelectors.selectTeamNamesById,
  selectBookingTemplate,
  (users, teams, template) => {
    const hosts = [...(template.potentialHosts || []), ...(template.potentialTeams || [])];

    let hostsInfo = '';
    hosts.forEach((host) => {
      const name = host && (users[host] || teams[host]);
      if (name) {
        hostsInfo += (hostsInfo.length ? ', ' : '') + name;
      }
    });

    return hostsInfo;
  }
);

const selectBookingPageForPreview = createSelector(
  selectBookingTemplate,
  selectStyle,
  (template, style) =>
    ({
      id: '',
      workspaceId: '',
      inviteOthers: template.inviteOthers,
      enterNote: template.enterNote,
      labels: template.labels,
      style,
      calendar: template.calendar,
      what: { instructions: template.what?.instructions, noteFromHost: template.what?.noteFromHost },
      where: template.where,
      when: template.when,
      inputFields: template.inputFields,
      notifications: template.notifications,
      confirmationsPage: template.confirmationsPage,
    } as UpdateBookingPageInput)
);

const selectSmartAlerts = createSelector(selectBookingTemplate, (template) => template.smartAlertIds || []);

const selectBookingTemplatesAvailForEdit = createSelector(
  selectBookingTemplates,
  userSettingsSelectors.selectBookingTemplatesEdit,
  (templates, isEdit) => (isEdit ? templates : [])
);

const selectHostVideoConferences = () => [] as IntegrationType[];

const selectLocationDependencesBT = createSelector(
  locationsSelectors.selectDeleteDependences,
  selectBookingTemplates,
  (dependences, bookingTemplates) => {
    const uniqueBookingTemplates = new Set();

    return dependences.bookingTemplates?.reduce((result, id) => {
      const bookingTemplate = bookingTemplates.find((bp) => bp.id === id);
      if (bookingTemplate && !uniqueBookingTemplates.has(bookingTemplate.id)) {
        uniqueBookingTemplates.add(bookingTemplate.id);
        result.push(bookingTemplate);
      }
      return result;
    }, [] as typeof bookingTemplates);
  }
);

const selectTemplateOptionsByWorkspaces = createSelector(
  selectBookingTemplatesForTenant,
  workspacesSelectors.selectWorkspacesById,
  (templates, workspacesById) =>
    templates.reduce((res, template) => {
      const workspaceId = template.workspaceId ?? '';

      let workspaceRecord = res.find((record) => record.workspaceId === workspaceId);

      if (!workspaceRecord) {
        workspaceRecord = {
          workspaceId,
          label: workspacesById[workspaceId]?.name ?? '',
          resource: [],
        };
        res.push(workspaceRecord);
      }

      workspaceRecord.resource.push({
        id: template.id ?? '',
        name: template.what?.customName ?? '',
      });

      return res;
    }, [] as Array<UserResourceOption>)
);

const selectHasUnassignedTemplates = createSelector(selectBookingTemplates, (bookingTemplates) =>
  bookingTemplates.some((template) => !template.potentialHosts?.length && !template.potentialTeams?.length)
);

export const bookingTemplatesSelectors = {
  selectIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectBookingTemplates,
  selectBookingTemplatesForTenant,
  selectIsEdited,
  selectFilterType,
  selectBookingTemplateIds,
  selectBookingTemplateNamesById,
  selectBookingTemplatesOptions,
  selectFilteredBookingTemplates,
  selectSearchedBookingTemplates,
  selectSelectedBookingTemplates,
  selectCloneName,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsBookingPageSelector,

  selectBookingTemplate,
  selectAccordionIndexes,
  selectId,
  selectIsExisting,
  selectIsActive,

  selectWhatName,
  selectWhatColor,
  selectInstructions,
  selectIsNameDuplicate,
  selectIsNameValid,

  selectDateRangeType,
  selectDateRangeCount,
  selectDateRangeList,
  selectDuration,
  selectDurationCount,
  selectDurationTimeUnit,
  selectScheduleBufferCount,
  selectScheduleBufferTimeUnit,
  selectTimeBeforeStartTime,
  selectTimeAfterEndTime,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,

  selectDurationMinutes,
  selectBeforeBufferMinutes,
  selectAfterBufferMinutes,

  selectPotentialHosts,
  selectPotentialTeams,
  selectAvailableHostsAndTeams,
  selectAssignedHostsAndTeams,
  selectIsNoHost,

  selectLocationTypes,
  selectDefaultLocationType,
  selectInPersonType,
  selectInPersonCustomAddress,
  selectOrganizationLocations,
  selectPhoneCallType,
  selectIsPromptInviteCallType,
  selectPhoneCallCustomPhoneNumber,
  selectPhoneCallCustomPhoneCode,
  selectSkipWhereStep,
  selectIsVideoConferenceValid,
  selectVideoConferenceType,
  selectVideoConferenceNotification,
  selectIsHostPhoneValid,
  selectHostPhoneNotification,
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,

  selectInputFields,
  selectPhoneInputFields,
  selectLocationInputFields,

  selectNotifications,
  selectConfirmation,
  selectReschedule,
  selectReminder,
  selectCancelation,
  selectFollowUp,

  selectSmsReminder,
  selectSmsReminderOptionEnabled,

  selectConfirmationType,
  selectConfirmationExternalLink,
  selectConfirmationAnotherEnabled,
  selectConfirmationRescheduleEnabled,
  selectConfirmationCancelEnabled,
  selectConfirmationCustomLinks,
  selectIsExternalLinkValid,
  selectCancelationPolicy,

  selectIsWhatStepValid,
  selectIsWhoStepValid,
  selectIsWhereStepValid,
  selectIsWhenStepValid,
  selectIsNotificationsStepValid,
  selectIsInviteeStepValid,
  selectIsConfirmationStepValid,

  selectBookingTemplatesById,
  selectIsEditBookingTemplate,
  selectIsReadOnlyBookingTemplate,
  selectLockedTooltip,

  selectInviteOthers,
  selectEnterNote,
  selectStyle,

  selectCalendar,
  selectTimeFormat,
  selectTopOfInterval,
  selectTimeInterval,
  selectTimeZoneType,
  selectSelectedTimeZone,
  selectDefaultTimeZone,
  selectAvailableTimeZones,
  selectAvailableTimeZonesOptions,
  selectAssignedTimeZones,
  selectAssignedTimeZonesOptions,
  selectIsTimeZonesValid,
  selectIsCalendarStepValid,
  selectHostsInfoById,
  selectHostsInfo,
  selectBookingPageForPreview,
  selectSmartAlerts,
  selectBookingTemplatesAvailForEdit,
  selectHostVideoConferences,

  selectLocationDependencesBT,

  selectTemplateOptionsByWorkspaces,
  selectHasUnassignedTemplates,
};