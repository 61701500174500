export const DefaultBookingTemplate = Object.freeze({
  NAME: `Reunión de 30 Minutos`,
  CONFIRMATION_SUBJECT: 'Nueva reunión: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Reprogramado: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Recordatorio: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Cancelado: <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Seguimiento: <Meeting Name> con <Host Name>',
  CONFIRMATION_BODY: `Hola <Invitee Name>,

Su nueva reunión <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date> está programada.

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Hola <Invitee Name>,

Su reunión <Meeting Name> con <Host Name> ha sido reprogramada.

<b>Hora y Fecha actualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Hola <Invitee Name>,

Este es un recordatorio amistoso de que su <Meeting Name> con <Host Name> es a las <Meeting Time>, <Meeting Date>.

<b>Ubicación</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Hola <Invitee Name>,

Su reunión <Meeting Name> con <Host Name> a las <Meeting Time>, <Meeting Date> ha sido cancelada.`,
  FOLLOW_UP_BODY: `Hola <Invitee Name>,

Esperamos que su reunión "<Meeting Name>" con <Host Name> haya sido productiva.

Si tiene algún comentario o necesita más asistencia, no dude en comunicarse con nosotros.`,
  SMS_TEXT:
    'Este es un recordatorio amistoso de que su <Meeting Name> con <Host Name> es a las <Meeting Time>, <Meeting Date>. Ver o editar reunión <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Nueva plantilla`,
  TITLE: `Plantillas de reserva`,
  VIEW_DETAILS: `Ver Detalles`,
  MINUTE: `min`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Clonar plantilla de reserva`,
  DELETE_MODAL_CONFIRM_TEXT: `¿Está seguro de que desea eliminar esta plantilla de reserva?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `¿Está seguro de que desea eliminar estas plantillas de reserva?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Primero debe eliminar estas plantillas de reserva de las siguientes páginas de reserva:`,
  FILTER_MY_TEMPLATES: `Mis plantillas`,
  FILTER_ALL_TEMPLATES: `Todas las plantillas`,
  TOOLTIP: `Crear plantillas de reserva para páginas de reserva.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Error al clonar la plantilla de reserva`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Plantilla de reserva clonada exitosamente`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Error al eliminar la plantilla de reserva`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Plantilla de reserva eliminada correctamente`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `No se pudo obtener la plantilla de reserva`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Error al guardar la plantilla de reserva`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de reserva guardada correctamente`,
  GET_MEMBERS_ERROR_MESSAGE: `Error al obtener miembros`,
});

export const EditBookingTemplate = Object.freeze({
  WHAT_TITLE: `Qué`,
  WHAT_DESCRIPTION: `Nombre y color`,
  WHO_TITLE: `Quién`,
  WHO_DESCRIPTION: `Potenciales anfitriones y equipo`,
  WHERE_TITLE: `Dónde`,
  WHERE_DESCRIPTION: `Detalles y ubicaciones del evento`,
  WHEN_TITLE: `Cuándo`,
  WHEN_DESCRIPTION: `Duración y disponibilidad`,
  HOW_TITLE: `¿Cómo debería verse?`,
  HOW_DESCRIPTION: `Estilos y presentaciones de marca`,
  ALERTS_TITLE: `Alertas`,
  ALERTS_DESCRIPTION: `Correos electrónicos y recordatorios`,
  INVITEE_TITLE: `Configuración del invitado`,
  INVITEE_DESCRIPTION: `Formulario de reserva y permiso del invitado`,
  AFTER_TITLE: `Después de la reserva`,
  AFTER_DESCRIPTION: `Página de confirmación y política de cancelación`,
  ADDITIONAL_CONFIGURATIONS: `Configuraciones adicionales`,
  ACTIVE: `Activo`,
  LOCKED_TOOLTIP: `Registro bloqueado. No tienes permisos para editar. Última modificación por:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Anfitriones y equipos`,
  SEARCH_PLACEHOLDER: `Buscar anfitriones y equipos`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Selecciona una o más opciones para presentar al invitado al programar ellos mismos en la página de reservas:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Omitir el paso "Dónde" para reuniones virtuales`,
  SKIP_WHERE_STEP_DESCRIPTION: `El enlace de la videoconferencia se agregará automáticamente a la reunión.`,
  PHONE_CALL: `Llamada telefónica`,
  HOSTS_PHONE: `Número de teléfono del anfitrión`,
  HOSTS_PHONE_DESCRIPTION: `El número de teléfono del anfitrión se agregará automáticamente a la reunión.`,
  HOSTS_PHONE_NOTIFICATION: `Uno o más anfitriones no tienen número de teléfono`,
  INVITEE_PHONE: `Pedir al invitado que ingrese su número de teléfono`,
  INVITEE_PHONE_DESCRIPTION: `Se le pedirá al invitado que agregue su número de teléfono.`,
  CUSTOM_PHONE: `Número de teléfono personalizado`,
  CUSTOM_PHONE_PLACEHOLDER: `Ingresar número de teléfono`,
  CUSTOM_PHONE_DESCRIPTION: `Este número de teléfono se agregará automáticamente a la reunión.`,
  IN_PERSON: `En persona`,
  CUSTOM_ADDRESS: `Dirección personalizada`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Ingresar dirección de la reunión`,
  CUSTOM_ADDRESS_DESCRIPTION: `Esta dirección se agregará automáticamente a la reunión.`,
  LOCATION: `Ubicación de la empresa`,
  LOCATION_DESCRIPTION: `Se le pedirá al invitado que elija una de estas ubicaciones.`,
  ASSIGN_ACTIVE_LOCATION: `Seleccione al menos una ubicación activa`,
  INVITEE_LOCATION: `Ubicación del invitado`,
  INVITEE_LOCATION_DESCRIPTION: `Se le pedirá al invitado que agregue una dirección de reunión.`,
  DEFAULT_LOCATION: `Opción de reunión predeterminada`,
  VIDEO_CONFERENCE: `Videoconferencia`,
  VIDEO_CONFERENCE_NOTIFICATION: `Uno o más anfitriones no tienen ninguna videoconferencia`,
  ORGANIZATION_LOCATIONS_TITLE: `Ubicaciones de la organización`,
  ORGANIZATION_LOCATIONS_DESCRIPTION: `El invitado deberá elegir una de estas ubicaciones`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Nombre completo`,
  EMAIL_LABEL: `Correo Electrónico`,
  ADD_QUESTION_BUTTON: `Agregar Nueva Pregunta`,
  QUESTION_TITLE_NEW: `Nueva Pregunta`,
  QUESTION_TITLE_EDIT: `Editar Pregunta`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  TITLE_NAME: `Nombre del invitado`,
  TITLE_EMAIL: `Correo electrónico del invitado`,
  TITLE_PHONE: `Número de teléfono del invitado`,
  TITLE_LOCATION: `Ubicación del invitado`,
  DESCRIPTION_NAME: `Este campo obligatorio recopila el nombre del invitado. Puede cambiar las etiquetas, pero manténgalas claras para evitar confusiones.`,
  DESCRIPTION_EMAIL: `Este campo obligatorio recopila el correo electrónico del invitado. Puede cambiar las etiquetas, pero manténgalas claras para evitar confusiones.`,
  DESCRIPTION_PHONE: `Este campo obligatorio recopila el número de teléfono del invitado. Puede cambiar las etiquetas, pero manténgalas claras para evitar confusiones.`,
  DESCRIPTION_LOCATION: `Este campo obligatorio recopila la ubicación del invitado. Puede cambiar las etiquetas, pero manténgalas claras para evitar confusiones.`,
  QUESTION_LABEL: `Pregunta`,
  QUESTION_NAME_LABEL: `Etiqueta del campo de nombre`,
  QUESTION_EMAIL_LABEL: `Etiqueta del campo de correo electrónico`,
  QUESTION_PHONE_LABEL: `Etiqueta del campo de número de teléfono`,
  QUESTION_LOCATION_LABEL: `Etiqueta del campo de ubicación`,
  FIELD_LABEL: `etiqueta del campo`,
  REQUIRED_LABEL: `Requerido`,
  TYPE_LABEL: `Tipo de respuesta`,
  TYPE_CHECKBOXES: `Casillas de verificación`,
  TYPE_RADIO_BUTTONS: `Botones de opción`,
  TYPE_SELECT: `Desplegable`,
  TYPE_TEL: `Número de teléfono`,
  TYPE_TEXT: `Línea única`,
  TYPE_TEXTAREA: `Varias líneas`,
  TYPE_CHECKBOXES_DESCRIPTION: `Opciones de elección múltiple`,
  TYPE_RADIO_BUTTONSS_DESCRIPTION: `Opción de elección única`,
  TYPE_SELECTS_DESCRIPTION: `El invitado puede seleccionar una opción de una lista`,
  TYPE_TELS_DESCRIPTION: `Entrada de número de teléfono con código`,
  TYPE_TEXTS_DESCRIPTION: `Campo de respuesta corta simple`,
  TYPE_TEXTAREAS_DESCRIPTION: `Campo de respuesta larga`,
  ANSWERS_LABEL: `Respuestas`,
  ANSWER_PLACEHOLDER: `Respuesta`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Confirmaciones por correo electrónico`,
  CONFIRMATION_DESCRIPTION: `Siempre se creará una cita en el calendario del anfitrión de SUMO1 y su invitado se añadirá como asistente.
Al habilitar esta función, SUMO1 también enviará una confirmación por correo electrónico.`,
  RESCHEDULE_TITLE: `Reprogramar correo electrónico`,
  RESCHEDULE_DESCRIPTION: `El invitado recibirá una notificación por correo electrónico de cualquier cambio en su reunión.`,
  REMINDER_TITLE: `Recordatorios por correo electrónico`,
  REMINDER_DESCRIPTION: `El invitado recibirá un correo electrónico de recordatorio antes de la reunión reservada.`,
  CANCELATION_TITLE: `Cancelaciones por correo electrónico`,
  CANCELATION_DESCRIPTION: `Se enviará una notificación por correo electrónico a su invitado si cancela la reunión.`,
  FOLLOW_UP_TITLE: `Seguimiento por correo electrónico`,
  FOLLOW_UP_DESCRIPTION: `Automatice el seguimiento de los próximos pasos con un correo electrónico enviado después de la reunión.`,
  SMS_TITLE: `Recordatorio de texto SMS`,
  SMS_DESCRIPTION: `El invitado tendrá la opción de recibir recordatorios por texto antes de una reunión reservada.`,
  SMS_BODY: `Cuerpo del SMS`,
  SMS_LABEL_PART1: `Solo EE.UU. y Canadá`,
  SMS_LABEL_PART2: `Actualmente, los recordatorios por texto son gratuitos y solo están disponibles para números de teléfono de EE.UU. y Canadá.`,
  PERSONALIZE: `Personalizar`,
  EMAIL_SUBJECT: `Asunto del correo electrónico`,
  EMAIL_BODY: `Cuerpo del correo electrónico`,
  MERGE_VARIABLES: `Combinar variables`,
  TIMING: `Temporización`,
  RESET: `Restablecer`,
  ADD_ANOTHER_REMINDER: `Agregar otro recordatorio`,
  CANCELATION_POLICY: `Política de cancelación`,
  CANCELATION_POLICY_TOOLTIP: `Cualquier texto que ingrese aquí aparecerá en todas las notificaciones por correo electrónico enviadas al invitado.`,
  BEFORE_EVENT: `antes del evento`,
  AFTER_EVENT: `después del evento`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Después de Reservar`,
  TYPE_DISPLAY_SUMO: `Mostrar Página de Confirmación SUMO`,
  TYPE_DISPLAY_EXTERNAL: `Redirigir a una Página Externa`,
  EXTERNAL_LINK_LABEL: `URL de Redirección Externa`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redireccion-aqui.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Mostrar botón "Reservar Otra Reunión"`,
  ALLOW_RESCHEDULE_LABEL: `Permitir Reprogramación`,
  ALLOW_CANCEL_LABEL: `Permitir Cancelación`,
  ADD_CUSTOM_LINK_LABEL: `Agregar Enlace Personalizado`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Agrega un enlace personalizado a la Página de Confirmación, como un enlace a una encuesta.`,
  ADD_CUSTOM_LINK_TITLE: `Agregar Enlace Personalizado`,
  EDIT_CUSTOM_LINK_TITLE: `Editar Enlace Personalizado`,
  CUSTOM_LINK_LABEL: `Rótulo del Enlace Personalizado`,
  CUSTOM_LINK_PLACEHOLDER: `Ejemplo de Enlace`,
  LINK_URL_LABEL: `URL del Enlace`,
  LINK_URL_PLACEHOLDER: `https://ejemplo-enlace.com`,
});
