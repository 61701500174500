import { useSelector } from 'react-redux';
import labels from './labels';
import { IntegrationCard } from '../../components/integration';
import {
  IntegrationFilter,
  integrationSelectors,
  integrationsActions,
  INTEGRATION_FILTER_OPTIONS,
} from '../../store/integration';
import { SectionHeader } from '../../components/common';
import { useDispatch } from 'react-redux';
import { Path } from '../../routing';

// TODO: rename to Integrations and the folder as integrations
export const Integration = () => {
  const dispatch = useDispatch();
  const integrationFilter = useSelector(integrationSelectors.selectIntegrationFilter);
  const integrationList = useSelector(integrationSelectors.selectIntegrationList);
  const integrationFilteredList = useSelector(integrationSelectors.selectFilteredIntegrations);
  const isFilterActive = useSelector(integrationSelectors.selectIsFilterInUse);

  const handleFilterChange = (value: IntegrationFilter) => {
    dispatch(integrationsActions.setIntegrationFilter(value));
  };

  const resetFilters = () => {
    dispatch(integrationsActions.setIntegrationFilter(IntegrationFilter.ALL));
  };

  return (
    <div className="sumo-card-bg flex flex-column pt-16px">
      <SectionHeader
        title={labels.title}
        itemsLength={integrationList.length}
        searchPaths={[Path.Integration]}
        dropdownfilters={[
          {
            label: labels.type,
            value: integrationFilter,
            options: INTEGRATION_FILTER_OPTIONS,
            onChange: (e) => handleFilterChange(e.target.value),
            pt: { wrapper: { className: 'max-h-fit' } },
          },
        ]}
        onFilterReset={resetFilters}
        isFilterActive={isFilterActive}
        hideButton
      />

      {/* <div className="flex justify-content-between mb-32px">
        <div className="text-title-xl-med pt-12px">{labels.title}</div>
        <div className="flex gap-6px h-fit">
          <Dropdown
            className="flex-none p-0 no-trigger p-button button-xl button-white"
            panelClassName="mt-4px"
            options={INTEGRATION_FILTER_OPTIONS}
            value={integrationFilter}
            valueTemplate={filterValueTemplate}
            onChange={(e) => handleFilterChange(e.target.value)}
          />
          {integrationList.length >= MIN_ITEMS_FOR_SEARCH && (
            <Search
              placeholder={labels.search}
              value={searchString}
              onChange={handleSearchChange}
              maxTextLength={MAX_LENGTH_NAME}
            />
          )}
        </div>
      </div> */}

      <div className="grid -mb-2">
        {integrationFilteredList.map((integration) => (
          <div className="col-12 lg:col-6" key={integration.type}>
            <IntegrationCard integration={integration} />
          </div>
        ))}
      </div>

      {!integrationFilteredList.length && (
        <div className="mt-12px text-body-lg-reg text-heavy-60">{labels.noItems}</div>
      )}
    </div>
  );
};
