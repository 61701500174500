import dayjs from 'dayjs';
import { InPersonType, LocationType, MemberInput, MemberType, NotificationsInput, TimeFormat, WhereInput } from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';
import { MemberDTO, NotificationTypes, TimeZoneOptionType } from './types';
import { FILE_COPY_POSTFIX } from '../../types/constants';
import { CEPMemberSubType, MemberSubType } from '../../types/types';

export const generatePublicFileKey = (filePath: string | null | undefined) =>
  filePath ? filePath.substring(filePath.lastIndexOf('public/') + 7) : '';

export const generatePublicFileKeyCopy = (filePath: string | null | undefined) => {
  const fileName = generatePublicFileKey(filePath);
  if (!fileName) {
    return '';
  }
  return fileName.indexOf('.') ? fileName.replace('.', FILE_COPY_POSTFIX + '.') : fileName + FILE_COPY_POSTFIX;
};

export const generateWhereLocationTypeToggle = (where: WhereInput, locationType: LocationType) => {
  const locationTypesState = where?.locationTypes || [];
  const defaultLocationTypeState = where?.defaultLocationType || null;

  const updatedLocationTypes = locationTypesState.includes(locationType)
    ? locationTypesState.filter((type) => type !== locationType)
    : [...locationTypesState, locationType];

  // prevent deactivating the only value
  const locationTypes = updatedLocationTypes.length ? updatedLocationTypes : locationTypesState;
  // update default location type if selected value is deactivated
  const defaultLocationType = locationTypes.includes(defaultLocationTypeState)
    ? defaultLocationTypeState
    : locationTypes[0];

  return { ...where, locationTypes, defaultLocationType } as WhereInput;
};

export const assignTime = (timeZoneList: timeZoneItem[], UTC: number, timeFormat: TimeFormat | null | undefined) =>
  timeZoneList.map(
    (timeZone) =>
      ({
        ...timeZone,
        value: timeZone.timeZone,
        time: dayjs(UTC)
          .tz(timeZone.timeZone)
          .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
      } as TimeZoneOptionType)
  );

export const isLocationUnassigned = (where: WhereInput | null | undefined) =>
  where?.locationTypes?.includes(LocationType.IN_PERSON) &&
  where.inPersonType === InPersonType.LOCATION &&
  !where.locations?.length;

export const getNotificationFieldByNotificationType = (
  type?: NotificationTypes | null
): keyof NotificationsInput | null => {
  if (!type) {
    return null;
  }

  switch (type) {
    case NotificationTypes.CONFIRMATION:
      return 'confirmations';
    case NotificationTypes.RESCHEDULE:
      return 'reschedule';
    case NotificationTypes.REMINDER:
      return 'reminders';
    case NotificationTypes.CANCELATION:
      return 'cancelations';
    case NotificationTypes.FOLLOW_UP:
      return 'followUp';
    case NotificationTypes.SMS:
      return 'smsReminder';
    default:
      return null;
  }
};

export const isUserMember = (member: MemberDTO | MemberInput) =>
  (member.type === MemberType.SUMO1 && member.subType === MemberSubType.USER) ||
  (member.type === MemberType.CEP && member.subType === CEPMemberSubType.USER);

export const isTeamMember = (member: MemberDTO | MemberInput) =>
  (member.type === MemberType.SUMO1 && member.subType === MemberSubType.TEAM) ||
  (member.type === MemberType.CEP && member.subType === CEPMemberSubType.RESOURCE_POOL);