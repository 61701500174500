export const ConnectIntegrationPage = Object.freeze({
  LEADS: `Clientes potenciales`,
  CONTACTS_PERSON_ACCOUNTS: `Kontaktoj/Personaj Kontoj`,
  PRODUCTION: `Produktado`,
  SANDBOX: `Sablokesto`,
  CREATE: `Crear`,
  BENEFITS_TITLE: `Beneficios`,
  DISCONNECT_INTEGRATION: `Desconectar Integración`,
  DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION: `¿Está seguro de que desea desconectar Google Calendar?`,
  DISCONNECT_GOOGLE_MEET_DESCRIPTION: `¿Está seguro de que desea desconectar Google Meet?

  Esto podría afectar las páginas de reservas que se crearon anteriormente con esta integración.`,
  DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION: `¿Está seguro de que desea desconectar Microsoft Office365?`,
  DISCONNECT_TEAMS_DESCRIPTION: `¿Está seguro de que desea desconectar Microsoft Teams?

  Esto podría afectar a las páginas de reserva que se crearon anteriormente utilizando esta integración.`,
  DISCONNECT_ZOOM_DESCRIPTION: `¿Está seguro de que desea desconectar Zoom Meeting?

  Esto podría afectar a las páginas de reserva que se crearon anteriormente utilizando esta integración.`,
  DISCONNECT_HUBSPOT_DESCRIPTION: `¿Está seguro de que desea desconectar HubSpot?`,
  DISCONNECT_SALESFORCE_DESCRIPTION: `¿Está seguro de que desea desconectar Salesforce?`,
  DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION: `¿Está seguro de que desea desconectar Google Analytics?`,
  DISCONNECT_GOOGLE_MAPS_DESCRIPTION: `¿Está seguro de que desea desconectar Google Maps?`,
  GOOGLE_ANALYTICS_BENEFITS: `Obtenga información valiosa sobre quién visita sus páginas de reserva públicas. \\p Mida las tasas de conversión y cuántas personas reservan eventos. \\p Integre su cuenta de SUMO con Google Analytics`,
  GOOGLE_ANALYTICS_REQUIREMENTS: `Cuenta de Google Analytics`,
  GOOGLE_ANALYTICS_SETUP_DESC: `Ingrese una ID de seguimiento de Universal Analytics o una ID de medición de Google Analytics 4 para conectar SUMO con su propiedad. Si ingresa ambas, rastrearemos conversiones en ambas de sus propiedades de Google Analytics.`,
  GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID: `UA-12345678-9`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID: `ID de seguimiento de Universal Analytics`,
  GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID: `G-1234567890`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID: `ID de medición de Google Analytics 4`,
  GOOGLE_CALENDAR_BENEFITS: `Reserve y reprograme automáticamente reuniones en su Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Necesita conceder acceso a su Google Calendar.`,
  GOOGLE_MAPS_BENEFITS: `\\p Proporcione a sus clientes un mapa con sus ubicaciones \\p Integre su cuenta de SUMO con Google Maps`,
  GOOGLE_MAPS_REQUIREMENTS: `Cuenta de Google Cloud \\p Clave de API con Geocoding API, Maps JavaScript API, Places API`,
  GOOGLE_MAPS_SETUP_DESC: `Los productos de Google Cloud están protegidos contra el uso no autorizado restringiendo las llamadas a la API a aquellas que proporcionan credenciales de autenticación adecuadas. Estas credenciales se presentan en forma de una clave API, una cadena alfanumérica única. Asegúrese de que su clave API esté conectada a la API de Geocodificación, API de JavaScript de Maps y API de Places. Para obtener más información sobre cómo obtener y usar las claves API, consulte la documentación de la plataforma Google Maps.\nUna vez conectada correctamente la API de Google Maps, todas las direcciones introducidas manualmente se convertirán automáticamente a formatos estandarizados usando esta API.`,
  GOOGLE_MAPS_NAME_API_KEY: `Clave API`,
  GOOGLE_MAPS_NAME_MAP_ID: `ID de Mapa`,
  GOOGLE_MEET_BENEFITS: `Cree automáticamente reuniones de Google Meet en el momento en que se programe un evento. Comparta instantáneamente los detalles de la conferencia única tras la confirmación.`,
  GOOGLE_MEET_REQUIREMENTS: `Dependiendo de su configuración, es posible que su administrador de Google Meet deba preaprobar SUMO1 en el Google Marketplace.`,
  HUBSPOT_BENEFITS: `Cree automáticamente eventos de HubSpot y reserve con clientes como clientes potenciales y contactos de HubSpot.`,
  HUBSPOT_REQUIREMENTS: `Cuenta de HubSpot \n Privilegios de administrador de HubSpot`,
  HUBSPOT_CREATE_EVENTS: `Crear eventos de Hubspot`,
  HUBSPOT_BOOK_USERS: `Permitir reservar con registros de Hubspot desde SUMO Meeting Booker`,
  INBOX_BENEFITS: `La mejor experiencia de SUMO1 es usar SUMO dentro de su entorno, instalando la extensión de Chrome / complemento de Edge. Use Sumo en:\n\u2022 Google Gmail en el navegador\n\u2022 Microsoft Office Web en el navegador`,
  INBOX_REQUIREMENTS: `Debe estar conectado a SUMO1 uno de los siguientes:\n\u2022 Cuenta de Google\n\u2022 Cuenta de Microsoft Office365`,
  INBOX_INSTALL_CHROME_EXTENSION: `Instalar extensión de Chrome`,
  INBOX_INSTALL_EDGE_ADD_ON: `Instalar complemento de Edge`,
  MICROSOFT_CALENDAR_BENEFITS: `Reserve y reprograme automáticamente reuniones en su Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Necesita conceder acceso a su Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Cree automáticamente reuniones de Microsoft Teams en el momento en que se programe un evento. Comparta instantáneamente los detalles de la conferencia única tras la confirmación.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Dependiendo de su configuración, es posible que su administrador de Microsoft Teams deba preaprobar SUMO1 en el Microsoft Marketplace.`,
  NO_CANCEL: `No, cancelar`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SALESFORCE_BENEFITS: `Aŭtomate kreu Salesforce Eventojn kaj rezervu kun klientoj kiel Salesforce-kondukantoj, kontaktoj aŭ personaj kontoj. Libro kun uzantoj de "SUMO por Salesforce" (CEP). Ĉi tiu funkcio povas aldoni haveblecon de kaj "SUMO1" kaj "SUMO por Salesforce" uzantoj, nur montrante fendojn kiam ambaŭ estas haveblaj.`,
  SALESFORCE_REQUIREMENTS: `Cuenta de Salesforce \n Privilegios de administrador de Salesforce`,
  SALESFORCE_EVENTS: `Salesforce Eventoj`,
  SALESFORCE_BOOK_USERS: `Permitir reservar con registros de Salesforce desde SUMO Meeting Booker`,
  SALESFORCE_BOOKING_WITH_SF_SUMO_INBOX: `Rezervado kun Salesforce-rekordoj de SUMO Meeting Booker`,
  SALESFORCE_ORG_TYPE: `Salesforce Org-tipo`,
  ALLOW_BOOKING_CEP: `Permesu rezervon kun "SUMO por Salesforce" (CEP)`,
  USERS_AND_RESOURCE_POOLS: `Uzantoj/Rimedoj`,
  PERSON_ACCOUNT: `Cuentas personales`,
  YES_DISCONNECT: `Sí, desconectar`,
  ZOOM_BENEFITS: `Cree automáticamente reuniones de Zoom en el momento en que se programe un evento. Comparta instantáneamente los detalles de la conferencia única tras la confirmación.`,
  ZOOM_REQUIREMENTS: `Dependiendo de su configuración, es posible que su administrador de Zoom deba preaprobar SUMO1 en el Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Ahorra más tiempo automatizando videollamadas, calendarios y más.`,
  FILTER_ALL: `Todo`,
  FILTER_CALENDARS: `Calendarios`,
  FILTER_VIDEO_CONFERENCE: `Videollamada`,
  FILTER_WORKSPACE_INTEGRATIONS: `Integraciones de Espacio de Trabajo`,
  FILTER_EXTENSIONS: `Extensiones`,
  GOOGLE_ANALYTICS_DESC: `Rastrea el compromiso con tus páginas de reservas`,
  GOOGLE_ANALYTICS_TITLE: `Google Analytics`,
  GOOGLE_CALENDAR_DESC: `Reserva y reprograma automáticamente reuniones en tu Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MAPS_DESC: `Agrega ubicaciones al mapa para proporcionar una mejor experiencia de reunión en persona`,
  GOOGLE_MAPS_TITLE: `Google Maps`,
  GOOGLE_MEET_DESC: `Añade automáticamente detalles de Google Meet a tus reuniones.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  HUBSPOT_DESC: `Añade automáticamente reuniones creadas por SUMO1 a HubSpot CRM`,
  HUBSPOT_TITLE: `HubSpot`,
  INBOX_TITLE: `SUMO Meeting Booker`,
  INBOX_DESC: `Instala la extensión de Chrome o el complemento de Edge para enviar enlaces de invitación desde Gmail o Outlook Web.`,
  MICROSOFT_CALENDAR_DESC: `Reserva y reprograma automáticamente reuniones en tu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Añade automáticamente detalles de Microsoft Teams a tus reuniones.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `No se encontraron elementos.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SALESFORCE_DESC: `Reserva usuarios de Salesforce, registros de CRM y añade automáticamente reuniones de Salesforce`,
  SALESFORCE_TITLE: `Salesforce`,
  TITLE: `Integración`,
  ZOOM_DESC: `Añade automáticamente detalles de Zoom a tus reuniones de SUMO.`,
  ZOOM_TITLE: `Zoom Meeting`,
  FILL_ONE_FIELD: `Rellene al menos un campo`,
});

export const IntegrationToastsNotifications = Object.freeze({
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE: `Integración de espacio de trabajo conectada con éxito`,
  CONNECT_USER_INTEGRATION_SUCCESS_MESSAGE: `Integración de usuario conectada con éxito`,
  CONNECT_INTEGRATION_ERROR_MESSAGE: `Error en la solicitud de integración`,
  INVALID_GOOGLE_MAP_API_KEY: `Clave API de Google no válida o servicios requeridos no están habilitados.`,
});