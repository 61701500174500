import { useDispatch } from 'react-redux';
import labels from './labels';
import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { MemberInput, MemberType } from '../../../API';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { HostCard } from '../../common/hostCard/HostCard';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { InformationBlock, SumoMultiSelect, SumoTooltip } from '../../common';

import { isUserMember, MemberDTO } from '../../../store/meeting';

export const BookingPageWhoStep = () => {
  const dispatch = useDispatch();
  const hostAndGuests = useSelector(bookingPageSelectors.selectHostAndGuestsData);
  const deletedHostAndGuests = useSelector(bookingPageSelectors.selectDeletedHostAndGuestsData);
  const potentialHostsAndTeams = useSelector(bookingPageSelectors.selectPotentialHostsAndTeams);
  const isUserRequiredHost = useSelector(bookingPageSelectors.selectIsUserRequiredHost);
  const userId = useSelector(authenticationSelectors.selectUserId);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const isTeamRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isNoHost = useSelector(bookingPageSelectors.selectIsNoHost);
  const isHostDeletedUser = useSelector(bookingPageSelectors.selectIsHostDeletedUser);

  const handleHostsAndGuestsChange = (e: MultiSelectChangeEvent) => {
    const selectedValues: string[] = e.value;
    updateMembers([
      ...potentialHostsAndTeams.filter((member) => selectedValues.includes(member.id)),
      ...deletedHostAndGuests, // don't remove automatically, leave deleted users up to the user
    ]);
  };

  const handleSelectAllChange = (checked: boolean) => {
    const members = [...(checked ? potentialHostsAndTeams : []), ...deletedHostAndGuests]; // don't remove automatically, leave deleted users up to the user]
    updateMembers(members);
  };

  const handleRemoveMember = (memberId: string) => {
    updateMembers(hostAndGuests.filter((member) => member.id !== memberId));
  };

  const handleMakeMainHost = (memberId: string) => {
    const newMainHost = hostAndGuests.find((member) => member.id === memberId);
    const newCoHosts = hostAndGuests.filter((member) => member.id !== memberId);

    if (newMainHost) {
      updateMembers([newMainHost, ...newCoHosts]);
    }
  };

  const updateMembers = (members: MemberDTO[]) => {
    const newMembers = members.map(
      (member) =>
        ({
          id: member.id,
          type: member.type,
          subType: member.subType,
        } as MemberInput)
    );
    if (newMembers[0] && newMembers[0].type === MemberType.SUMO1) {
      // if first member is sumo1 member - mark as host
      dispatch(
        bookingPagesActions.updateWhoStep({
          hostMembers: [...newMembers.slice(0, 1)],
          guests: [...newMembers.slice(1)],
        })
      );
    } else {
      // otherwise put all as guest
      dispatch(
        bookingPagesActions.updateWhoStep({
          hostMembers: [],
          guests: newMembers,
        })
      );
    }

    const previousHostId = hostAndGuests[0] && hostAndGuests[0].id;
    const newHostId = members[0] && members[0].id;
    if (newHostId && newHostId !== previousHostId) {
      dispatch(
        bookingPagesActions.updateWhereStep({
          videoConferenceType: members[0].defaultVideoIntegration,
        })
      );
    }
  };

  return (
    <div className="flex flex-column gap-16px">
      {(isNoHost || isHostDeletedUser) && <InformationBlock text={labels.unassigned} />}
      {isUserRequiredHost && hostAndGuests[0]?.id !== userId && <InformationBlock text={labels.wrongHost} />}

      <div className="flex-left-center gap-8px">
        <div className="text-title-xs-med text-heavy-100">{labels.hostsAndTeams}</div>
        <SumoTooltip text={labels.hostsTooltip} />
      </div>

      <div className="flex flex-wrap -mx-20px -my-6px">
        <div className="w-12 lg:w-6 px-20px py-6px">
          <SumoMultiSelect
            className="pr-24px"
            onSelectAllChange={handleSelectAllChange}
            options={potentialHostsAndTeams}
            value={hostAndGuests.map((member) => member.id)}
            onChange={handleHostsAndGuestsChange}
            filterPlaceholder={labels.searchPlaceholder}
            disabled={isBookingPageLocked}
            templateType="member"
            optionValue="id"
          />
        </div>
        <div className="w-12 lg:w-6 px-20px py-6px"></div>
        {hostAndGuests.map((host, index) => (
          <div key={host.id} className="w-12 lg:w-6 px-20px py-6px">
            <HostCard
              host={host}
              onRemove={
                !isBookingPageLocked && !(isUserRequiredHost && host.id === userId) && (isUserMember || isTeamRead)
                  ? handleRemoveMember
                  : undefined
              }
              isMainHost={index === 0 && host.type === MemberType.SUMO1}
              onMakeMainHost={
                isBookingPageLocked || host.type !== MemberType.SUMO1 || host.isDeleted ? undefined : handleMakeMainHost
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
