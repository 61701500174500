import { useEffect, useRef, useState } from 'react';
import { IntegrationStatus, WorkspaceIntegrationType } from '../../../API';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { SalesforceCustomParameters, integrationsActions, SFOrgType } from '../../../store/integration';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { SalesforceAuthButton } from '../../private';
import { getIntegrationURLParams } from '../../../store/integration/utils';
import { getUrlWithoutParams } from '../../../services/URLService';
import { InputSwitch } from 'primereact/inputswitch';
import labels from './labels';
import { ConnectIntegrationRequest } from '../../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { workspacesSelectors } from '../../../store/workspaces';
import { WorkspaceIntegration as WorkspaceIntegrationModel } from '../../../generated-sources/internal-api/models/WorkspaceIntegration';
import { Tooltip } from 'primereact/tooltip';
import { TOAST_SUCCESS_DURATION } from '../../../types/constants';
import { TooltipEvent } from 'primereact/tooltip/tooltipoptions';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';

interface ISalesforceIntegration {
  integrationType: WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const SalesforceIntegration = (props: ISalesforceIntegration) => {
  const { integrationType, handleDisconnect } = props;
  const dispatch = useDispatch();
  const isAuthenticationFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const userSettingsError = useSelector(userSettingsSelectors.selectError);
  const initialLoad = useRef(true); // Use a ref to track the initial load
  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const workspaceIntegrationSettings = useSelector(
    authenticationSelectors.selectWorkspaceIntegrationSettings(integrationType)
  );
  const isWorkspaceOrSuperAdmin = useSelector(userSettingsSelectors.selectIsWorkspaceOrSuperAdmin);
  const currentCepIntegration = useSelector(workspacesSelectors.selectCurrentCepIntegration);
  const currentCepIntegrationSettings = useSelector(workspacesSelectors.selectCurrentCepIntegrationSettings);

  const [customSettingsLoaded, setCustomSettingsLoaded] = useState(false);
  const [createEvents, setCreateEvents] = useState(true);

  const [usersAndResourcePools, setUsersAndResourcePools] = useState(false);
  const [contactsPersonAccounts, setContactsPersonAccounts] = useState(false);
  const [orgType, setOrgType] = useState(SFOrgType.PRODUCTION);
  const [savedSettings, setSavedSettings] = useState({} as any);
  const [orgId, setOrgId] = useState('');
  const [isChangeRequest, setIsChangeRequest] = useState(false);

  const copyTooltip = useRef<Tooltip>(null);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (linkCopied) {
      timer = setTimeout(() => {
        setLinkCopied(false);
        copyTooltip.current?.hide();
      }, TOAST_SUCCESS_DURATION);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [linkCopied]);

  useEffect(() => {
    setCustomSettingsLoaded(true);
    if (workspaceIntegrationSettings) {
      const settings = workspaceIntegrationSettings as unknown as SalesforceCustomParameters;
      setCreateEvents('true' === settings.createEvents);

      setContactsPersonAccounts('true' === settings.contactsPersonAccounts);
      setOrgType(settings.orgType);
      setOrgId(settings.orgId);
      setSavedSettings((prev: any) => ({
        ...prev,
        createEvents: 'true' === settings.createEvents,
        contactsPersonAccounts: 'true' === settings.contactsPersonAccounts,
        orgType: settings.orgType,
        orgId: settings.orgId,
      }));
    }
    if (!isChangeRequest) {
      setUsersAndResourcePools(!!currentCepIntegration);
      setSavedSettings((prev: any) => ({ ...prev, usersAndResourcePools: !!currentCepIntegration }));
    }
  }, [workspaceIntegrationSettings, currentCepIntegration]);

  const hasCustomSettingsChanges = () => {
    return (
      savedSettings &&
      (savedSettings.createEvents !== createEvents ||
        savedSettings.usersAndResourcePools != usersAndResourcePools ||
        savedSettings.contactsPersonAccounts != contactsPersonAccounts ||
        savedSettings.orgType !== orgType ||
        savedSettings.orgId != orgId)
    );
  };

  const handleChangeOrg = () => {
    setIsChangeRequest(true);
    dispatch(integrationsActions.disconnectCEP(workspaceId, currentCepIntegration as WorkspaceIntegrationModel));
  };

  const disconnect = () => {
    setIsChangeRequest(false);
    dispatch(integrationsActions.disconnectCEP(workspaceId, currentCepIntegration as WorkspaceIntegrationModel));
  };
  useEffect(() => {
    if (isUserSettingsReceived && !isAuthenticationFetching && !userSettingsError) {
      const { code, type, workspaceId, customParameters } = getIntegrationURLParams();

      if (code && type && workspaceId) {
        const workspaceIntegrationResponse = {
          code,
          type,
          workspaceId,
          redirectURI: getUrlWithoutParams(),
          customParameters,
        } as ConnectIntegrationRequest;

        handleGenericIntegrationAuthentication(workspaceIntegrationResponse);
      }
    }
  }, [isUserSettingsReceived, isAuthenticationFetching, userSettingsError, integrationType]);

  const handleGenericIntegrationAuthentication = async (integration: ConnectIntegrationRequest) => {
    dispatch(integrationsActions.connectIntegrationRequest(integration));
    navigationService.navigateTo(Path.ConnectIntegration); // clear the URL from code
    initialLoad.current = false; // after navigating, mark that the initial load is complete
  };

  const handleGetSalesforceCodeRedirect = () => {
    dispatch(
      integrationsActions.getCodeRedirect({
        integrationType,
        uri: window.location.href,
        workspaceId,
        customParameters: {
          createEvents: createEvents.toString(),
          bookLeads: 'true',
          usersAndResourcePools: usersAndResourcePools.toString(),
          contactsPersonAccounts: contactsPersonAccounts.toString(),
          orgType: orgType,
          orgId: orgId,
        },
      })
    );
  };

  const handleConnect = () => {
    setIsChangeRequest(false);
    dispatch(
      integrationsActions.generateCepAuthCode(workspaceId, {
        createEvents: createEvents.toString(),
        usersAndResourcePools: usersAndResourcePools.toString(),
        contactsPersonAccounts: contactsPersonAccounts.toString(),
        orgType: orgType,
        orgId: orgId,
      })
    );
  };

  const handleCopyCode = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(currentCepIntegrationSettings.VERIFICATION_CODE);
    handleShowTooltip({
      ...event,
      originalEvent: event,
      target: event.currentTarget,
    });
  };

  const handleShowTooltip = (event: TooltipEvent) => {
    copyTooltip.current?.show(event);
    setLinkCopied(true);
  };

  const handleUpdate = () => {
    dispatch(
      integrationsActions.connectIntegrationRequest({
        type: WorkspaceIntegrationType.SALESFORCE,
        workspaceId,
        customParameters: {
          createEvents: createEvents.toString(),
          usersAndResourcePools: usersAndResourcePools.toString(),
          contactsPersonAccounts: contactsPersonAccounts.toString(),
          orgType: orgType,
          orgId: orgId,
          bookLeads: 'true',
        },
      })
    );
  };

  const customSettings = (
    <>
      <div className="flex flex-column gap-24px">
        <Divider />
        <div className="flex flex-column gap-12px">
          <div className="text-title-xs-med ">{labels.events}</div>
          <div className="flex-left-center gap-12px">
            <InputSwitch
              checked={createEvents}
              onChange={(e) => setCreateEvents(!!e.value)}
              disabled={!isWorkspaceOrSuperAdmin}
            />
            <div className="text-body-s-reg text-heavy-60">{labels.create}</div>
          </div>
        </div>
        <div className="flex flex-column gap-12px mt-8px">
          <div className="text-title-xs-med ">{labels.orgType}</div>
          <div className="flex-left-center gap-12px text-label-input-reg text-heavy-80">
            <RadioButton
              inputId="orgTypeProduction"
              value="production"
              checked={orgType === SFOrgType.PRODUCTION}
              onChange={() => setOrgType(SFOrgType.PRODUCTION)}
            />
            <label htmlFor="orgTypeProduction" className="cursor-pointer mr-20px">
              {labels.production}
            </label>
            <RadioButton
              inputId="orgTypeSandbox"
              value="sandbox"
              checked={orgType === SFOrgType.SANDBOX}
              onChange={() => setOrgType(SFOrgType.SANDBOX)}
            />
            <label htmlFor="orgTypeSandbox" className="cursor-pointer">
              {labels.sandbox}
            </label>
          </div>
        </div>
        <Divider />

        <div className="flex flex-column gap-12px">
          <div className="text-title-xs-med ">{labels.allowBookingCEP}</div>
          <div className="flex-left-center gap-12px">
            <InputSwitch
              checked={usersAndResourcePools}
              disabled={
                (currentCepIntegration && currentCepIntegration?.status === IntegrationStatus.CONNECTED) ||
                !isWorkspaceOrSuperAdmin
              }
              onChange={(e) => setUsersAndResourcePools(!!e.value)}
            />
            <div className="text-body-s-reg text-heavy-60">{labels.usersAndResourcePools}</div>
            {currentCepIntegration &&
              currentCepIntegration?.status === IntegrationStatus.CONNECTED &&
              usersAndResourcePools && (
                <>
                  <Button
                    className="button-text-line button-blue flex-none"
                    label={labels.disconnect}
                    onClick={disconnect}
                  />
                </>
              )}
          </div>
        </div>

        {!currentCepIntegration && usersAndResourcePools && (
          <>
            <div className="text-title-xs-med mt-4px">{labels.salesforceOrgId}</div>
            <div className="flex-left-center gap-12px">
              <InputText
                className="flex-1"
                type="text"
                value={orgId}
                onChange={(e) => setOrgId(e.target.value.trimStart())}
              />
              <Button label={labels.generateCode} onClick={handleConnect} />
            </div>
          </>
        )}
        {currentCepIntegration &&
          currentCepIntegration?.status === IntegrationStatus.UNAUTHORIZED &&
          usersAndResourcePools && (
            <>
              <div className="flex flex-column gap-12px">
                <div className="text-title-xs-med text-heavy-80">{labels.salesforceOrgId}</div>
                <div className="flex-left-center gap-4px">
                  <div className="text-body-s-reg text-heavy-60">{currentCepIntegrationSettings.ORG_ID}</div>
                  <Button className="button-text-line button-blue" label={labels.change} onClick={handleChangeOrg} />
                </div>
              </div>

              <div className="flex flex-column gap-12px">
                <div className="text-title-xs-med text-heavy-80 ">{labels.authCode} </div>
                <div className="flex-left-center gap-4px">
                  <div className="text-body-s-reg text-heavy-60">{labels.authCodeDescription}</div>
                </div>
              </div>

              <div className="flex-left-center gap-16px">
                <div className="text-title-md-med">{currentCepIntegrationSettings.VERIFICATION_CODE}</div>
                <Tooltip
                  ref={copyTooltip}
                  position="top"
                  pt={{ text: { className: 'bg-egg-blue-dark text-primary-white text-label-s-reg' } }}
                >
                  {labels.copiedLink}
                </Tooltip>
                <Button className="flex-none button-text-line" label={labels.copyCode} onClick={handleCopyCode} />
              </div>
            </>
          )}

        <Divider />

        <div className="flex flex-column gap-12px">
          <div className="text-title-xs-med ">{labels.bookingWithSFSumoInbox}</div>
          <div className="flex-left-center gap-12px">
            <InputSwitch
              checked={contactsPersonAccounts}
              onChange={(e) => setContactsPersonAccounts(!!e.value)}
              disabled={!isWorkspaceOrSuperAdmin}
            />
            <div className="text-body-s-reg text-heavy-60">{labels.contactsPersonAccounts}</div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <ConnectIntegration
      integrationType={integrationType}
      handleDisconnect={handleDisconnect}
      ConnectButton={(props) => <SalesforceAuthButton {...props} disabled={!createEvents && !contactsPersonAccounts} />}
      handleAuthRedirect={handleGetSalesforceCodeRedirect}
      hasCustomSettingsChanges={hasCustomSettingsChanges()}
      handleUpdate={handleUpdate}
      CustomSettingsSection={customSettingsLoaded ? customSettings : undefined}
    />
  );
};
