import { all, fork } from 'redux-saga/effects';
// TODO: sort to A-Z
import { watchAuthenticationSaga } from './authentication/sagas';
import { watchAvailabilitySaga } from './availability/sagas';
import { watchBillingSaga } from './billing/sagas';
import { watchBookedMeetingsSaga } from './bookedMeetings/sagas';
import { watchBookingPagesSaga } from './bookingPages/sagas';
import { watchBookingTemplatesSaga } from './bookingTemplates/sagas';
import { watchGlobalSaga } from './global/sagas';
import { watchGroupBookingPagesSaga } from './groupBookingPages/sagas';
import { watchIntegrationsSaga } from './integration/sagas';
import { watchJourneysSaga } from './journeyBuilder/sagas';
import { watchLocationsSaga } from './locations/sagas';
import { watchNotificationsSaga } from './notifications/sagas';
import { watchOPSConsoleAccountsSaga } from './opsConsole/accounts/sagas';
import { watchOPSConsoleOrgsSaga } from './opsConsole/orgs/sagas';
import { watchOPSConsoleStaffSaga } from './opsConsole/staff/sagas';
import { watchOPSConsoleUsersSaga } from './opsConsole/users/sagas';
import { watchEventSaga } from './publicBookingPage/sagas';
import { watchQuickSetupSaga } from './quickSetup/sagas';
import { watchRolesSaga } from './roles/sagas';
import { watchSmartAlertsSaga } from './smartAlerts/sagas';
import { watchTeamsSaga } from './teams/sagas';
import { watchUsersSaga } from './users/sagas';
import { watchUserSettingsSaga } from './userSettings/sagas';
import { watchWorkspacesSaga } from './workspaces/sagas';
import { watchMeetingSaga } from './meeting/sagas';

// TODO: sort to A-Z
export default function* rootSaga() {
  try {
    yield all([
      fork(watchUsersSaga),
      fork(watchAuthenticationSaga),
      fork(watchBillingSaga),
      fork(watchBookingPagesSaga),
      fork(watchEventSaga),
      fork(watchBookingTemplatesSaga),
      fork(watchQuickSetupSaga),
      fork(watchBookedMeetingsSaga),
      fork(watchAvailabilitySaga),
      fork(watchUserSettingsSaga),
      fork(watchWorkspacesSaga),
      fork(watchGlobalSaga),
      fork(watchRolesSaga),
      fork(watchOPSConsoleAccountsSaga),
      fork(watchOPSConsoleOrgsSaga),
      fork(watchOPSConsoleUsersSaga),
      fork(watchOPSConsoleStaffSaga),
      fork(watchTeamsSaga),
      fork(watchNotificationsSaga),
      fork(watchIntegrationsSaga),
      fork(watchSmartAlertsSaga),
      fork(watchLocationsSaga),
      fork(watchMeetingSaga),
      fork(watchJourneysSaga),
      fork(watchGroupBookingPagesSaga),
    ]);
  } catch (error: unknown) {
    console.error(error);
  }
}
