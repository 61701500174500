import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Path } from '../../../routing';
import { useDispatch } from 'react-redux';
import { WorkspaceIntegrationType } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { integrationsActions } from '../../../store/integration';
import labels from './labels';

type NoGoogleMapsWarningProps = {
  className?: string;
};

export const NoGoogleMapsWarning = ({ className }: NoGoogleMapsWarningProps) => {
  const dispatch = useDispatch();

  const handleNavigation = () => {
    dispatch(integrationsActions.setConnectIntegrationType(WorkspaceIntegrationType.GOOGLE_MAPS));
    navigationService.navigateTo(Path.ConnectIntegration);
  };

  return (
    <div
      className={`flex-left-center gap-6px bg-saffron-light text-saffron-dark text-label-xs-reg px-6px py-12px border-radius-6px ${className}`}
    >
      <InformationCircleIcon className="icon-18px flex-shrink-0" />
      <div>
        {labels.noMapsIntegrationPart1}
        <a onClick={handleNavigation} className="cursor-pointer">
          {labels.noMapsIntegrationPart2}
        </a>
        {labels.noMapsIntegrationPart3}
      </div>
    </div>
  );
};
