export const DefaultBookingTemplate = Object.freeze({
  NAME: `Reunião de 30 Minutos`,
  CONFIRMATION_SUBJECT: 'Nova reunião: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Reagendado: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Lembrete: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Cancelado: <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Acompanhamento: <Meeting Name> com <Host Name>',
  CONFIRMATION_BODY: `Olá <Invitee Name>,

Sua nova reunião <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date> está agendada.

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Olá <Invitee Name>,

Sua reunião <Meeting Name> com <Host Name> foi reagendada.

<b>Hora e Data atualizadas</b>
<Meeting Time>, <Meeting Date>

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Olá <Invitee Name>,

Este é um lembrete amigável de que sua <Meeting Name> com <Host Name> é às <Meeting Time>, <Meeting Date>.

<b>Local</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Olá <Invitee Name>,

Sua reunião <Meeting Name> com <Host Name> às <Meeting Time>, <Meeting Date> foi cancelada.`,
  FOLLOW_UP_BODY: `Olá <Invitee Name>,

Esperamos que sua reunião "<Meeting Name>" com <Host Name> tenha sido produtiva.

Se você tiver algum comentário ou precisar de mais assistência, não hesite em nos contatar.`,
  SMS_TEXT:
    'Este é um lembrete amigável de que sua <Meeting Name> com <Host Name> é às <Meeting Time>, <Meeting Date>. Ver ou editar reunião <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Novo modelo`,
  TITLE: `Modelos de reserva`,
  VIEW_DETAILS: `Ver Detalhes`,
  MINUTE: `min`,
  HOUR: `h`,
  CLONE_MODAL_TITLE: `Clone modelo de reserva`,
  DELETE_MODAL_CONFIRM_TEXT: `Tem certeza que deseja excluir este modelo de reserva?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Tem certeza que deseja excluir estes modelos de reserva?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Primeiro você deve remover esses modelos de reservas das seguintes páginas de reservas:`,
  FILTER_MY_TEMPLATES: `Meus modelos`,
  FILTER_ALL_TEMPLATES: `Todos os modelos`,
  TOOLTIP: `Criar modelos de reserva para páginas de reserva.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha na clonagem do modelo de reserva`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modelo de reserva clonado com sucesso`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha na remoção do modelo de reserva`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Modelo de reserva removido com sucesso`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Falha ao obter modelo de reserva`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Falha ao salvar o modelo de reserva`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Modelo de reserva salvo com sucesso`,
  GET_MEMBERS_ERROR_MESSAGE: `Erro ao obter membros`,
});

export const EditBookingTemplate = Object.freeze({
  WHAT_TITLE: `O que`,
  WHAT_DESCRIPTION: `Nome e cor`,
  WHO_TITLE: `Quem`,
  WHO_DESCRIPTION: `Potenciais anfitriões e equipe`,
  WHERE_TITLE: `Onde`,
  WHERE_DESCRIPTION: `Detalhes e locais do evento`,
  WHEN_TITLE: `Quando`,
  WHEN_DESCRIPTION: `Duração e disponibilidade`,
  HOW_TITLE: `Como deve ser?`,
  HOW_DESCRIPTION: `Estilos e exibições de marca`,
  ALERTS_TITLE: `Alertas`,
  ALERTS_DESCRIPTION: `E-mails e lembretes`,
  INVITEE_TITLE: `Configurações do convidado`,
  INVITEE_DESCRIPTION: `Formulário de reserva e permissão do convidado`,
  AFTER_TITLE: `Após a reserva`,
  AFTER_DESCRIPTION: `Página de confirmação e política de cancelamento`,
  ADDITIONAL_CONFIGURATIONS: `Configurações adicionais`,
  ACTIVE: `Ativo`,
  LOCKED_TOOLTIP: `Registro bloqueado. Você não tem permissões para editar. Última modificação por:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Anfitriões e equipes`,
  SEARCH_PLACEHOLDER: `Procurar anfitriões e equipes`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Selecione uma ou mais opções para apresentar ao convidado ao agendar por conta própria na página de reservas:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Pular o passo "Onde" para reuniões virtuais`,
  SKIP_WHERE_STEP_DESCRIPTION: `O link da videoconferência será adicionado automaticamente à reunião.`,
  PHONE_CALL: `Chamada telefônica`,
  HOSTS_PHONE: `Número de telefone do Anfitrião`,
  HOSTS_PHONE_DESCRIPTION: `O número de telefone do anfitrião será adicionado automaticamente à reunião.`,
  HOSTS_PHONE_NOTIFICATION: `Um ou mais anfitriões não possuem número de telefone`,
  INVITEE_PHONE: `Solicitar ao convidado que informe o número de telefone deles`,
  INVITEE_PHONE_DESCRIPTION: `O convidado será solicitado a adicionar o número de telefone.`,
  CUSTOM_PHONE: `Número de telefone personalizado`,
  CUSTOM_PHONE_PLACEHOLDER: `Informe o número de telefone`,
  CUSTOM_PHONE_DESCRIPTION: `Este número de telefone será adicionado automaticamente à reunião.`,
  IN_PERSON: `Presencial`,
  CUSTOM_ADDRESS: `Endereço Personalizado`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Informe o endereço da reunião`,
  CUSTOM_ADDRESS_DESCRIPTION: `Este endereço será adicionado automaticamente à reunião.`,
  LOCATION: `Local da empresa`,
  LOCATION_DESCRIPTION: `O convidado será solicitado a escolher um destes locais.`,
  ASSIGN_ACTIVE_LOCATION: `Selecione pelo menos uma localização ativa`,
  INVITEE_LOCATION: `Localização do convidado`,
  INVITEE_LOCATION_DESCRIPTION: `O convidado será solicitado a adicionar um endereço de reunião.`,
  DEFAULT_LOCATION: `Opção de reunião padrão`,
  VIDEO_CONFERENCE: `Videoconferência`,
  VIDEO_CONFERENCE_NOTIFICATION: `Um ou mais anfitriões não possuem videoconferência`,
  ORGANIZATION_LOCATIONS_TITLE: `Localizações da organização`,
  ORGANIZATION_LOCATIONS_DESCRIPTION: `O convidado será solicitado a escolher um desses locais`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Nome completo`,
  EMAIL_LABEL: `E-mail`,
  ADD_QUESTION_BUTTON: `Adicionar Nova Pergunta`,
  QUESTION_TITLE_NEW: `Nova Pergunta`,
  QUESTION_TITLE_EDIT: `Editar Pergunta`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  TITLE_NAME: `Nome do convidado`,
  TITLE_EMAIL: `Email do convidado`,
  TITLE_PHONE: `Número de telefone do convidado`,
  TITLE_LOCATION: `Localização do convidado`,
  DESCRIPTION_NAME: `Este campo obrigatório coleta o nome do convidado. Você pode alterar os rótulos, mas mantenha-os claros para evitar confusões.`,
  DESCRIPTION_EMAIL: `Este campo obrigatório coleta o email do convidado. Você pode alterar os rótulos, mas mantenha-os claros para evitar confusões.`,
  DESCRIPTION_PHONE: `Este campo obrigatório coleta o número de telefone do convidado. Você pode alterar os rótulos, mas mantenha-os claros para evitar confusões.`,
  DESCRIPTION_LOCATION: `Este campo obrigatório coleta a localização do convidado. Você pode alterar os rótulos, mas mantenha-os claros para evitar confusões.`,
  QUESTION_LABEL: `Pergunta`,
  QUESTION_NAME_LABEL: `Rótulo do campo de nome`,
  QUESTION_EMAIL_LABEL: `Rótulo do campo de email`,
  QUESTION_PHONE_LABEL: `Rótulo do campo de número de telefone`,
  QUESTION_LOCATION_LABEL: `Rótulo do campo de localização`,
  FIELD_LABEL: `rótulo do campo`,
  REQUIRED_LABEL: `Obrigatório`,
  TYPE_LABEL: `Tipo de resposta`,
  TYPE_CHECKBOXES: `Caixas de seleção`,
  TYPE_RADIO_BUTTONS: `Botões de opção`,
  TYPE_SELECT: `Menu suspenso`,
  TYPE_TEL: `Número de telefone`,
  TYPE_TEXT: `Linha única`,
  TYPE_TEXTAREA: `Várias linhas`,
  TYPE_CHECKBOXES_DESCRIPTION: `Opções de escolha múltipla`,
  TYPE_RADIO_BUTTONSS_DESCRIPTION: `Opção de escolha única`,
  TYPE_SELECTS_DESCRIPTION: `O convidado pode selecionar uma opção de uma lista`,
  TYPE_TELS_DESCRIPTION: `Entrada de número de telefone com código`,
  TYPE_TEXTS_DESCRIPTION: `Campo de resposta curta simples`,
  TYPE_TEXTAREAS_DESCRIPTION: `Campo de resposta longa`,
  ANSWERS_LABEL: `Respostas`,
  ANSWER_PLACEHOLDER: `Resposta`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `Confirmações por Email`,
  CONFIRMATION_DESCRIPTION: `Um compromisso sempre será criado no calendário do anfitrião SUMO1 e seu convidado será adicionado como participante.
Ao ativar este recurso, o SUMO1 também enviará uma confirmação por email.`,
  RESCHEDULE_TITLE: `Reagendamento por Email`,
  RESCHEDULE_DESCRIPTION: `O convidado receberá uma notificação por email sobre qualquer alteração em sua reunião.`,
  REMINDER_TITLE: `Lembretes por Email`,
  REMINDER_DESCRIPTION: `O convidado receberá um email de lembrete antes da reunião agendada.`,
  CANCELATION_TITLE: `Cancelamentos por Email`,
  CANCELATION_DESCRIPTION: `Uma notificação por email será enviada ao seu convidado se você cancelar a reunião.`,
  FOLLOW_UP_TITLE: `Follow-Up por Email`,
  FOLLOW_UP_DESCRIPTION: `Automatize o acompanhamento dos próximos passos com um email enviado após a reunião.`,
  SMS_TITLE: `Lembrete de Texto por SMS`,
  SMS_DESCRIPTION: `O convidado terá a opção de receber lembretes por texto antes de uma reunião agendada.`,
  SMS_BODY: `Corpo do SMS`,
  SMS_LABEL_PART1: `Somente EUA & Canadá`,
  SMS_LABEL_PART2: `Atualmente, os lembretes por texto são gratuitos e estão disponíveis apenas para números de telefone dos EUA e Canadá.`,
  PERSONALIZE: `Personalizar`,
  EMAIL_SUBJECT: `Assunto do Email`,
  EMAIL_BODY: `Corpo do Email`,
  MERGE_VARIABLES: `Mesclar Variáveis`,
  TIMING: `Tempo`,
  RESET: `Redefinir`,
  ADD_ANOTHER_REMINDER: `Adicionar Outro Lembrete`,
  CANCELATION_POLICY: `Política de Cancelamento`,
  CANCELATION_POLICY_TOOLTIP: `Qualquer texto que você inserir aqui aparecerá em todas as notificações por email enviadas ao convidado.`,
  BEFORE_EVENT: `antes do evento`,
  AFTER_EVENT: `depois do evento`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Após Agendar`,
  TYPE_DISPLAY_SUMO: `Exibir Página de Confirmação SUMO`,
  TYPE_DISPLAY_EXTERNAL: `Redirecionar para uma Página Externa`,
  EXTERNAL_LINK_LABEL: `URL de Redirecionamento Externo`,
  EXTERNAL_LINK_PLACEHOLDER: `https://redirecionamento-aqui.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Exibir botão "Agendar Outra Reunião"`,
  ALLOW_RESCHEDULE_LABEL: `Permitir Reagendamento`,
  ALLOW_CANCEL_LABEL: `Permitir Cancelamento`,
  ADD_CUSTOM_LINK_LABEL: `Adicionar Link Personalizado`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Adicione um link personalizado à Página de Confirmação, como um link para uma pesquisa.`,
  ADD_CUSTOM_LINK_TITLE: `Adicionar Link Personalizado`,
  EDIT_CUSTOM_LINK_TITLE: `Editar Link Personalizado`,
  CUSTOM_LINK_LABEL: `Rótulo do Link Personalizado`,
  CUSTOM_LINK_PLACEHOLDER: `Exemplo de Link`,
  LINK_URL_LABEL: `URL do Link`,
  LINK_URL_PLACEHOLDER: `https://exemplo-link.com`,
});
